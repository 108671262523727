import Filters from 'components/reporting/components/Filters/Filters'
import Export from 'components/reporting/components/Export/Export'
import style from './Actions.module.scss'

const Actions = () => {

  return (
    <aside className={style.aside}>
      <Filters />
      <Export />
    </aside>
  )
}

export default Actions
