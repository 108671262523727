// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Code_container__Nkjoj {
  width: 100%;
  padding: 0 8px;
  margin-top: 20px;
  position: relative;
}
.Code_container__Nkjoj label {
  padding: 0;
}

.Code_input__gdf70 {
  height: 40px;
  font-size: 1px;
  padding: 0;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Code_dashed__0YndI {
  height: 40px;
  line-height: 40px;
}
.Code_dashed__0YndI span {
  display: inline-block;
  height: inherit;
  text-align: center;
  border-bottom: 1px solid #f5f5ff;
  color: #f5f5ff;
  vertical-align: bottom;
}
.Code_dashed__0YndI span:last-child {
  margin-right: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Code/Code.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;AAFF;AAIE;EACE,UAAA;AAFJ;;AAMA;EACE,YAAA;EACA,cAAA;EACA,UAAA;EACA,UAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;AAHF;;AAMA;EACE,YAAA;EACA,iBAAA;AAHF;AAKE;EACE,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,gCAAA;EACA,cCjCO;EDkCP,sBAAA;AAHJ;AAKI;EACE,0BAAA;AAHN","sourcesContent":["@use 'styles/colors';\n@use 'styles/sizes';\n\n.container{\n  width: 100%;\n  padding: 0 8px;\n  margin-top: 20px;\n  position: relative;\n\n  label{\n    padding: 0;\n  }\n}\n\n.input{\n  height: 40px;\n  font-size: 1px;\n  padding: 0;\n  opacity: 0;\n  position: absolute;\n  bottom: 0;\n  left: 0;\n}\n\n.dashed{\n  height: 40px;\n  line-height: 40px;\n\n  span{\n    display: inline-block;\n    height: inherit;\n    text-align: center;\n    border-bottom: 1px solid colors.$primary1;\n    color: colors.$primary1;\n    vertical-align: bottom;\n\n    &:last-child{\n      margin-right: 0!important;\n    }\n  }\n}","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Code_container__Nkjoj`,
	"input": `Code_input__gdf70`,
	"dashed": `Code_dashed__0YndI`
};
export default ___CSS_LOADER_EXPORT___;
