import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { parsePhoneNumber, getCountryCallingCode } from 'libphonenumber-js'

import PhoneInput from 'components/common/PhoneInput/PhoneInput'
import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import { isInputError } from 'helpers/form'
import style from './ContactForm.module.scss'
import Title from 'components/common/PageTitle/PageTitle'

const ContactForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()

  const getDefaultValue = field => {
    return state.client[field] || ''
  }

  const {
    register,
    formState: { dirtyFields, errors, isValid },
    handleSubmit,
    control,
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    mode: 'onTouched',
    values: state?.client && {
      email: getDefaultValue('email'),
      phone: getDefaultValue('phone'),
    },
  })

  const errorFields = Object.keys(errors)

  const onSubmit = handleSubmit(async data => {
    navigate('../address', {
      state: {
        client: { ...state.client, ...data },
        contract: state.contract,
        step: 3,
        token: state.token,
        idCampaign: state.idCampaign
      },
    })
  })

  const handleChangeNumber = (value, data, onChange, onBlur) => {
    onChange({
      number: value,
      countryCode: data.countryCode,
    })
    onBlur(value)
  }

  useEffect(() => {
    if (!state?.client || !state?.contract || !state?.step) navigate('/sign-in')
  }, [navigate, state])

  if (!state) return null

  return (
    <>
      <Title text={<>{t('subscription:contacts.title')} 💌</>} />
      <form className={style.form} onSubmit={onSubmit}>
        <div className={style.inputs}>
          <Input
            className={style.input}
            error={isInputError(errorFields, 'email')}
            register={register('email', {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
              },
            })}
            type={'email'}
            label={t('common:form.email.label')}
            placeholder={`${t('common:your')} ${t('common:form.email.placeholder')}`}
          />
          {errorFields.includes('email') && dirtyFields.hasOwnProperty('email') && (
            <p className={style['input-error']}>{t('subscription:contacts.mailError')}</p>
          )}
          <Controller
            control={control}
            name="phone"
            rules={{
              required: true,
              validate: ({ number, countryCode }) => {
                try {
                  const countryCallingCode = getCountryCallingCode(countryCode.toUpperCase())
                  const codePays = new RegExp(`^${countryCallingCode}`)
                  number = number.replace(codePays, '');

                  const phoneNumber = parsePhoneNumber(number, countryCode.toUpperCase())

                  const country = phoneNumber.country === countryCode.toUpperCase()
                  const possible = phoneNumber.isPossible()
                  const valid = phoneNumber.isValid()

                  return country && possible && valid
                } catch {
                  return false
                }
              },
            }}
            render={({ field: { onBlur, onChange } }) => (
              <PhoneInput
                className={style.input}
                localization={'fr'}
                country={'fr'}
                onBlur={onBlur}
                error={isInputError(errorFields, 'phone')}
                label={t('common:form.phone.label')}
                onChange={(value, data) => {
                  handleChangeNumber(value, data, onChange, onBlur)
                }}
                onlyCountries={['fr', 'es']}
                placeholder={`${t('common:your')} ${t('common:form.phone.placeholder')}`}
                value={`${state?.client?.phone?.number || ''}`}
              />
            )}
          />
          {errorFields.includes('phone') && (
            <p className={style['input-error']}>{t('subscription:contacts.phoneError')}</p>
          )}
        </div>
        <Button
          className={style.button}
          label={t('common:next')}
          onClick={handleSubmit}
          type="primary"
          disabled={!isValid}
        />
      </form>
    </>
  )
}

export default ContactForm
