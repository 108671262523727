import classNames from 'classnames'

import style from './Card.module.scss'

const Card = ({
  className,
  content,
  disabled,
  inactive,
  titleIcon,
  title,
  cornerContent,
  onClick,
  isCentered,
  warning,
  error,
  hiddenContent
}) => {
  return (
    <div
      className={classNames(
        style.card,
        className,
        disabled && style.disabled,
        inactive && style.inactive,
        isCentered && style.centered,
        onClick && style.clickable,
        warning && style.warning,
        error && style.error,
      )}
      onClick={onClick}
    >
      {(title || titleIcon || cornerContent) && (
        <div className={style.top}>
          <div className={style.title}>
            {titleIcon}
            <div className={style['title-text']}>{title}</div>
          </div>
          {cornerContent && <div className={classNames(style['corner-content'])}>{cornerContent}</div>}
        </div>
      )}
      {content && <div className={classNames(style['content'], hiddenContent && style.hidden)}>{content}</div>}
    </div>
  )
}

export default Card
