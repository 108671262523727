// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomLegend_legend__b9Bxv {
  padding: 0;
  margin: 3px 0;
}
.CustomLegend_legend__b9Bxv .CustomLegend_item__7s6vN {
  align-items: center;
  display: flex;
  padding: 5px 0;
}
.CustomLegend_legend__b9Bxv .CustomLegend_item__7s6vN .CustomLegend_color__gPni\\+ {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 2px;
  display: inline-block;
  flex-shrink: 0;
}
.CustomLegend_legend__b9Bxv .CustomLegend_item__7s6vN .CustomLegend_text__OzG1- {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CustomLegend_legend__b9Bxv .CustomLegend_item__7s6vN.CustomLegend_hidden__KY3B3 .CustomLegend_label__7Qe6x {
  text-decoration: line-through;
}
.CustomLegend_legend__b9Bxv.CustomLegend_inline__SzFqD {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
}
.CustomLegend_legend__b9Bxv.CustomLegend_inline__SzFqD .CustomLegend_item__7s6vN {
  margin-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/reporting/components/graphs/CustomLegend/CustomLegend.module.scss"],"names":[],"mappings":"AAGA;EACE,UAAA;EACA,aAAA;AAkBF;AAhBE;EACE,mBAAA;EACA,aAAA;EACA,cAAA;AAkBJ;AAhBI;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,qBAAA;EACA,cAAA;AAkBN;AAfI;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAiBN;AAdI;EACE,6BAAA;AAgBN;AAZE;EACE,aAAA;EACA,eAAA;EACA,kBAAA;AAcJ;AAZI;EACE,iBAAA;AAcN","sourcesContent":["@use 'components/reporting/styles/colors.scss';\n\n\n.legend{\n  padding: 0;\n  margin: 3px 0;\n\n  .item{\n    align-items: center;\n    display: flex;\n    padding: 5px 0;\n  \n    .color{\n      width: 12px;\n      height: 12px;\n      margin-right: 8px;\n      border-radius: 2px;\n      display: inline-block;\n      flex-shrink: 0;\n    }\n    \n    .text{\n      width: 100%;\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n    }\n\n    &.hidden .label{\n      text-decoration: line-through;\n    }\n  }\n\n  &.inline{\n    display: flex;\n    flex-wrap: wrap;\n    margin-left: -15px;\n\n    .item{\n      margin-left: 15px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"light": `#FFFFFF`,
	"dark": `#121217`,
	"blue1": `#00007A`,
	"blue2": `#0000C9`,
	"blue3": `#4343FF`,
	"blue4": `#8686FF`,
	"blue5": `#D8D8FF`,
	"orange1": `#DA8200`,
	"orange2": `#FFA92C`,
	"orange3": `#FFC26B`,
	"orange4": `#FFE1B7`,
	"orange5": `#FFF3E3`,
	"grey1": `#3E3E50`,
	"grey2": `#838390`,
	"grey3": `#C3C3C9`,
	"grey4": `#E0E0E3`,
	"grey5": `#F9F9FA`,
	"legend": `CustomLegend_legend__b9Bxv`,
	"item": `CustomLegend_item__7s6vN`,
	"color": `CustomLegend_color__gPni+`,
	"text": `CustomLegend_text__OzG1-`,
	"hidden": `CustomLegend_hidden__KY3B3`,
	"label": `CustomLegend_label__7Qe6x`,
	"inline": `CustomLegend_inline__SzFqD`
};
export default ___CSS_LOADER_EXPORT___;
