import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import classNames from 'classnames'

import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'
import api from 'helpers/api'
import InfoMessage from 'components/common/InfoMessage/InfoMessage'
import style from 'styles/auth.module.scss'
import Header from 'components/Header/Header'

const ResetPassword = () => {
  const { t } = useTranslation()

  const [errorMessage, setErrorMessage] = useState(null)
  const [isSuccessMessageDisplayed, setIsSuccessMessageDisplayed] = useState(false)

  const accessToken = localStorage.getItem('accessToken')

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    defaultValues: {
      email: '',
    },
    mode: 'onTouched',
  })

  const onSubmit = handleSubmit(async data => {
    try {
      const lang = (navigator.language || navigator.userLanguage).split('-').shift();
      const res = await api.post({
        url: '/auth/clients/forgot_password',
        data: { email: data.email, lang },
      })

      if (res.success) {
        setIsSuccessMessageDisplayed(true)
        errorMessage && setErrorMessage(null)
      } else {
        setErrorMessage(t('main:errorMessages.general'))
        isSuccessMessageDisplayed && setIsSuccessMessageDisplayed(false)
      }
    } catch (error) {
      setErrorMessage(t('main:errorMessages.general'))
    }
  })

  return accessToken
  ? <Navigate to='/contracts' replace />
  : (
    <div className={style['auth']}>
      <Header darkBg styles={{ position: 'absolute', top: 0 }} />
      <h2 className={style.title}>{t('main:auth.resetPassword.reset')}</h2>

      <p className={classNames(style.description, style['reset-password'])}>
        {t('main:auth.resetPassword.resetDescription')}
      </p>
      {errorMessage && <ResponseMessage className={style['error-message']} label={errorMessage} />}
      {isSuccessMessageDisplayed > 0 && (
        <InfoMessage
          className={style['success-message']}
          label={t('main:auth.resetPassword.emailSent')}
          type="success"
        />
      )}

      <form onSubmit={onSubmit}>
        <div className={style.inputs}>
          <div className={style.input}>
            <Input
              register={register('email', { required: true })}
              darkBg
              label="Email"
              placeholder={`${t('common:your')} ${t('common:form.email.placeholder')}`}
              type="email"
            />
          </div>
        </div>
        <Button label={t('main:auth.resetPassword.send')} onClick={handleSubmit} type="primary" disabled={!isValid} />
      </form>
    </div>
  )
}

export default ResetPassword
