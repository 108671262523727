import colors from 'components/reporting/styles/colors.scss'
import CustomTooltip from 'components/reporting/components/graphs/CustomTooltip/CustomTooltip'
import { Tooltip } from 'chart.js'
import api from 'helpers/api'

export const numberReduce = (label) => {
    if (isNaN(label)) {
        return label
    }

    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ]
    const item = lookup.findLast(item => label >= item.value)
    return item
    ? (label / item.value)
        .toFixed(1)
        .replace(/\.0+$|(?<=\.[0-9]*[1-9])0+$/, "")
        .concat(item.symbol)
    : 0
}



Tooltip.positioners.customerPosition = function(items, coordinates) {
    const pos = Tooltip.positioners.average(items)
    if (pos === false) return false

    return {
        x: coordinates.x,
        y: coordinates.y + 10,
        xAlign: 'center',
        yAlign: 'bottom'
    }
}

const color = colors.grey4

export const commonOptions = (legendID) => ({
    maintainAspectRatio: false,
    responsive: true,
    animation:{
        duration: 500
    },
    plugins:{
        htmlLegend:{ legendID },
        legend:{ display: false },
        tooltip: {
            enabled: false,
            position: 'customerPosition', //'nearest',
            external: CustomTooltip
        }
    },
    scales: {
        x: {
            grid:{ color },
            border:{ color },
            ticks: {}
        },
        y: {
            min: 0,
            position: 'left',
            grace: '5%',
            grid:{ color },
            border:{ color },
            ticks:{
                stepSize: 1,
                autoSkip: false,
                maxTicksLimit: 7,
                callback: (label) => label !== 0 ? numberReduce(label) : ''
            },
            title:{
                padding: 0
            }
        },
        y1: {
            min: 0,
            position: 'right',
            display: false,
            grace: '5%',
            grid:{ color },
            border:{ color },
            ticks:{ 
                stepSize: 1,
                autoSkip: false,
                maxTicksLimit: 7,
                callback: (label) => label !== 0 ? numberReduce(label) : ''
            },
            title:{
                padding: 0
            }
        },
    }
})


export const months = (t) => [
    t('reporting:months.january'),
    t('reporting:months.february'),
    t('reporting:months.march'),
    t('reporting:months.april'),
    t('reporting:months.may'),
    t('reporting:months.june'),
    t('reporting:months.july'),
    t('reporting:months.august'),
    t('reporting:months.september'),
    t('reporting:months.october'),
    t('reporting:months.november'),
    t('reporting:months.december')
]


export const hexToRgbA = ({hex, opacity}) => {
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+opacity+')';
    }
    throw new Error('Bad Hex');
}


export const getDatasFrom = async ({contract, endpoint}) => {
    try {
      return await api.get({
        url: `/reporting/datas/${contract._id}/${endpoint}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('reportToken')}` }
      })
    } catch (error) {
      console.log(`Get datas from ${endpoint} error`)
    }
  }