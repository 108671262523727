import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Button from 'components/common/Button/Button';
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle';
import PageTitle from 'components/common/PageTitle/PageTitle';
import Textarea from 'components/common/Textarea/Textarea';
import Select from 'components/common/Select/Select';
import Input from 'components/common/Input/Input';
import Modal from 'components/common/Modal/Modal';
import style from './ContactUs.module.scss';
import { isInputError } from 'helpers/form';
import api from 'helpers/api';
import InfoMessage from 'components/common/InfoMessage/InfoMessage';
import Message from 'components/assets/icons/Message';
import Close from 'components/assets/icons/Close';
import { isMobile } from 'helpers/styles';

const ContactUs = () => {
  const { t } = useTranslation();

  const [contracts, setContracts] = useState([]);
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [closestTerminationDate, setClosestTerminationDate] = useState('');
  const [formData, setFormData] = useState(null);

  const timerRef = useRef(null);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    defaultValues: {
      adhesionId: '',
      subject: '',
      text: '',
      terminationDate: '',
    },
    mode: 'onTouched',
  });

  useEffect(() => {
    const getContracts = async () => {
      try {
        const res = await api.get({ url: '/adhesions/client' });
        setContracts(res.adhesions);
      } catch (error) {
        console.log('Fetch contracts error');
      }
    };
    getContracts();

    return () => clearTimeout(timerRef.current);
  }, []);

  const contractOptions = contracts.map(item => ({
    label: item.contractType?.dashboardName,
    value: item._id, // Utilisez uniquement l'ID ici
    name: item.contractType?.dashboardName, // Stockez le nom du contrat
    date: item.closestTerminationDate, // Stockez la date séparément
  }));
  contractOptions.push({ label: t('common:other'), value: 'other', name: 'other', date: null });

  const isInsuranceExpiryDateValid = closestTerminationDate && !isNaN(new Date(closestTerminationDate).getTime());

  const subjectOptions = [
    { label: t('main:contactUs.subjectSelector.question'), value: t('main:contactUs.subjectSelector.question') },
    { label: t('main:contactUs.subjectSelector.claim'), value: t('main:contactUs.subjectSelector.claim') },
    { label: t('main:contactUs.subjectSelector.ticket'), value: t('main:contactUs.subjectSelector.ticket') },
    { label: t('main:contactUs.subjectSelector.protection'), value: t('main:contactUs.subjectSelector.protection') },
    ...(isInsuranceExpiryDateValid ? [{ label: t('main:contactUs.subjectSelector.termination'), value: t('main:contactUs.subjectSelector.termination') }] : []),
    { label: t('main:contactUs.subjectSelector.other'), value: t('main:contactUs.subjectSelector.other') },
  ];

  const errorFields = Object.keys(errors);

  const onSubmit = handleSubmit(async data => {
    if (data.subject === t('main:contactUs.subjectSelector.termination')) {
      setIsModalOpen(true);
      setFormData({ ...data, closestTerminationDate }); // Stockez les données temporairement dans un état pour les utiliser lors de la confirmation du modal
    } else {
      await handleModalConfirm({ ...data, closestTerminationDate }); // Passez les données du formulaire à handleModalConfirm
    }
  });

  const handleModalConfirm = async (data) => {
    try {
      await api.post({
        url: '/clients/contact_us',
        data: {
          ...data,
          adhesionId: data.adhesionId,
        },
      });

      setIsSuccessMessageShown(true);
      timerRef.current = setTimeout(() => {
        setIsSuccessMessageShown(false);
      }, 5000);
    } catch (error) {
      console.log('Submit message error', error);
    }
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={style['contact-us']}>
      <PageTitle text={t('main:contactUs.title')} />
      <PageSubtitle text={t('main:contactUs.subtitle')} />

      <form className={style.form} onSubmit={onSubmit}>
        <div className={style.inputs}>
          <Controller
            control={control}
            name="adhesionId"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                className={classNames(style.select, style.input)}
                error={isInputError(errorFields, 'adhesionId')}
                onChange={val => {
                  const selectedContract = contractOptions.find(option => option.value === val.value);
                  onChange(val.value); // Passez uniquement l'ID ici
                  setClosestTerminationDate(selectedContract ? selectedContract.date : ''); // Met à jour la date de résiliation
                }}
                options={contractOptions}
                placeholder={t('main:contactUs.contractSelector.placeholder')}
                value={contractOptions.find(item => item.value === value)?.label || ''}
                getOptionLabel={option => option.label} // Affichez le label (dashboardName)
                getOptionValue={option => option.value} // Utilisez l'ID comme valeur
                styles={{ indicatorContainer: { paddingRight: 8 } }}
              />
            )}
          />
          <Controller
            control={control}
            name="subject"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                className={classNames(style.select, style.input)}
                error={isInputError(errorFields, 'subject')}
                onChange={val => {
                  onChange(val.value);
                  setSelectedSubject(val.value);
                }}
                options={subjectOptions}
                placeholder={t('main:contactUs.subjectSelector.placeholder')}
                value={subjectOptions.find(item => item.value === value)?.label || ''}
                styles={{ indicatorContainer: { paddingRight: 8 } }}
              />
            )}
          />
        </div>

        <div className={style.inputs}>
          {selectedSubject === t('main:contactUs.subjectSelector.termination') && (
            <Input
              className={classNames(style.select, style.input)}
              type="text"
              value={closestTerminationDate ? new Date(closestTerminationDate).toLocaleDateString() : t('main:contactUs.cantBeTerminated')}
              readOnly
              onChange={() => {}} // Ajoutez un gestionnaire onChange vide pour éviter l'avertissement
            />
          )}
        </div>
        {selectedSubject === t('main:contactUs.subjectSelector.termination') && (
          <div
            className={style.inputs}
            style={{ fontSize: '12px', color: '#00007A', textDecoration: 'underline' }}
          >
            &nbsp;{t('main:contactUs.firstDateTerminationTxt')}
          </div>
        )}
        <Textarea
          className={style.textarea}
          error={isInputError(errorFields, 'text')}
          label={t('main:contactUs.textInput.label')}
          placeholder={t('main:contactUs.textInput.placeholder')}
          register={register('text', { required: true })}
        />

        {isSuccessMessageShown ? (
          <InfoMessage
            className={style['success-message']}
            label={t('main:contactUs.successMessage')}
            prefix={<Message />}
            suffix={
              <div className={style.close} onClick={() => setIsSuccessMessageShown(false)}>
                <Close styles={{ fill: '#126F0E' }} />
              </div>
            }
            type="success"
          />
        ) : (
          <Button className={style.button} label={t('main:contactUs.button')} onClick={handleSubmit} type="primary" disabled={!isValid} />
        )}
      </form>

      <Modal
        content={
          <div>
            <p style={{ fontSize: '20px', fontWeight: 'normal', color: '#00007A', textAlign: 'center' }}>
              {t('main:contactUs.subjectSelector.termination')}
            </p>
            <br />
            <p style={{ fontSize: '14px', fontWeight: 'normal', color: '#00007A' }}>
              {`${t('main:contactUs.modal.terminationConfirmTxt')} `}
              <span style={{ fontWeight: 'bold', color: '#00007A' }}>
                {closestTerminationDate ? new Date(closestTerminationDate).toLocaleDateString() : '456'}
              </span>
              . <br /> <br />
              {t('main:contactUs.modal.areYouSure')}
            </p>
            <br />
            <div className={style['modal-buttonss']}>
              <Button
                className={style['modal-button']}
                label={t('main:contactUs.modal.confirm')}
                onClick={() => handleModalConfirm(formData)} // Passez les données du formulaire stockées
                type="primary"
                style={{ marginTop: '50px' }}
              />
              <div style={{ marginTop: '10px' }}></div>
              <Button
                className={style['modal-button']}
                label={t('main:contactUs.modal.cancel')}
                onClick={handleCloseModal}
                type="secondary"
              />
            </div>
          </div>
        }
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        styles={{
          content: {
            width: isMobile() ? '90%' : 430,
            maxWidth: 430,
            height: isMobile() ? 290 : 310,
          },
        }}
      />
    </div>
  );
};

export default ContactUs;
