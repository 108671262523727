import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import IconWrapper from 'components/common/IconWrapper/IconWrapper'
import MainLogo from 'components/assets/icons/MainLogo'
import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'
import InfoMessage from 'components/common/InfoMessage/InfoMessage'
import Input from 'components/common/Input/Input'
import Button from 'components/common/Button/Button'
import { emailValidation } from 'constants/inputValidation'
import api from 'helpers/api'
import style from '../Auth.module.scss'
import commonStyle from '../../../Wrapper/wrapper.module.scss'

const PasswordLost = () => {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState(null)
  const [isSuccessMessageDisplayed, setIsSuccessMessageDisplayed] = useState(false)

  const {
    register,
    formState: { errors, isValid },
    handleSubmit
  } = useForm({
    criteriaMode: 'all',
    mode: 'onTouched',
    delayError: 0,
    values: {
      email: '',
    },
  })

  const onSubmit = handleSubmit(async data => {
    setErrorMessage(false)
    try {
      const lang = (navigator.language || navigator.userLanguage).split('-').shift();
      const res = await api.post({
        url: '/auth/reporting/forgot_password',
        data: { ...data, lang }
      })

      if (res.success) {
        setIsSuccessMessageDisplayed(true)
        errorMessage && setErrorMessage(null)
      } else {
        setErrorMessage(t('main:errorMessages.general'))
        isSuccessMessageDisplayed && setIsSuccessMessageDisplayed(false)
      }
    } catch (error) {
      setErrorMessage(t('main:errorMessages.general'))
    }
  })

  return (
    <section className={style.content}>
      <IconWrapper icon={MainLogo} className={style.logo} styles={{ fill: '#000093' }} text='Logo' />
      <h1 className={style.title}>{t('reporting:passwordLost.title')}</h1>
      <p className={style.text}>{t('reporting:passwordLost.guideline')}</p>

      {errorMessage &&
        <ResponseMessage
          className={style.message}
          label={errorMessage}
        />
      }

      {isSuccessMessageDisplayed > 0 && (
        <InfoMessage
          className={style['success-message']}
          label={t('main:auth.resetPassword.emailSent')}
          type="success"
        />
      )}

      <form onSubmit={onSubmit}>
        <Input
          register={register('email', emailValidation(t))}
          label={t('reporting:auth.email.label')+'*'}
          autocomplete="email"
          error={errors.firstname}
          className={commonStyle.input}
        />

        <Button
          className={style.button}
          disabled={!isValid}
          label={t('reporting:passwordLost.button')}
          onClick={handleSubmit}
        />
      </form>
    </section>
  )
};

export default PasswordLost
