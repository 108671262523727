// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Back_back__V4OO8 {
  position: absolute;
  top: 20px;
  left: 320px;
}

.Back_link__wjCS0 {
  display: flex;
  align-items: center;
}
.Back_link__wjCS0:hover path {
  fill: #000093;
}
.Back_link__wjCS0:active path {
  fill: #000054;
}

.Back_arrow__q5F\\+d {
  transform: rotate(-180deg);
  margin-right: 4px;
}

.Back_label__YJ8i7 {
  color: #0000c9;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
}
.Back_label__YJ8i7:hover {
  color: #000093;
}
.Back_label__YJ8i7:active {
  color: #000054;
}

@media screen and (max-width: 900px) {
  .Back_back__V4OO8 {
    left: 270px;
  }
}
@media screen and (max-width: 600px) {
  .Back_back__V4OO8 {
    position: initial;
    padding: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/Back/Back.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;AAFF;AAKI;EACE,aCTK;ADMX;AAQI;EACE,aCbK;ADOX;;AAWA;EACE,0BAAA;EACA,iBAAA;AARF;;AAWA;EACE,cC3BS;ED4BT,gBAAA;EACA,eAAA;EACA,iBAAA;AARF;AAUE;EACE,cChCO;ADwBX;AAWE;EACE,cClCO;ADyBX;;AAaA;EACE;IACE,WAAA;EAVF;AACF;AAaA;EACE;IACE,iBAAA;IACA,aAAA;EAXF;AACF","sourcesContent":["@use 'styles/sizes.scss';\n@use 'styles/colors.scss';\n\n.back {\n  position: absolute;\n  top: 20px;\n  left: calc(sizes.$nav-menu-width + 20px);\n}\n\n.link {\n  display: flex;\n  align-items: center;\n\n  &:hover {\n    path {\n      fill: colors.$primary7;\n    }\n  }\n\n  &:active {\n    path {\n      fill: colors.$primary9;\n    }\n  }\n}\n\n.arrow {\n  transform: rotate(-180deg);\n  margin-right: 4px;\n}\n\n.label {\n  color: colors.$primary6;\n  font-weight: 300;\n  font-size: 16px;\n  line-height: 28px;\n\n  &:hover {\n    color: colors.$primary7;\n  }\n\n  &:active {\n    color: colors.$primary9;\n  }\n}\n\n@media screen and (max-width: sizes.$breakpoint-md) {\n  .back {\n    left: calc(sizes.$nav-menu-width-md + 20px);\n  }\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .back {\n    position: initial;\n    padding: 12px;\n  }\n}\n","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"back": `Back_back__V4OO8`,
	"link": `Back_link__wjCS0`,
	"arrow": `Back_arrow__q5F+d`,
	"label": `Back_label__YJ8i7`
};
export default ___CSS_LOADER_EXPORT___;
