// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContentWrapper_wrapper__ysjzV {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
}

.ContentWrapper_signature-wrapper__PJ4NK {
  width: 600px;
}

@media screen and (min-width: 600px) {
  .ContentWrapper_signature-wrapper__PJ4NK {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .ContentWrapper_wrapper__ysjzV {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/ContentWrapper/ContentWrapper.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE;IACE,WAAA;EADF;AACF;AAIA;EACE;IACE,WAAA;EAFF;AACF","sourcesContent":["@use 'styles/sizes.scss';\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 500px;\n}\n\n.signature-wrapper {\n  width: 600px;\n}\n\n@media screen and (min-width: sizes.$breakpoint-sm) {\n  .signature-wrapper {\n    width: 100%;\n  }\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .wrapper {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ContentWrapper_wrapper__ysjzV`,
	"signature-wrapper": `ContentWrapper_signature-wrapper__PJ4NK`
};
export default ___CSS_LOADER_EXPORT___;
