// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CircumstancesForm_form__MopWT {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CircumstancesForm_inputs__5U-oa {
  display: flex;
  width: 100%;
  gap: 20px;
}

.CircumstancesForm_input__HuBh2 {
  width: 33.3333333333%;
}

.CircumstancesForm_textarea__5ifYr {
  height: 212px;
  margin: 28px 0;
}

.CircumstancesForm_contract__ZgruG {
  width: 100%;
  margin-bottom: 32px;
}

.CircumstancesForm_button__SS9MR {
  width: 200px;
}

@media screen and (max-width: 600px) {
  .CircumstancesForm_inputs__5U-oa {
    flex-direction: column;
    gap: 16px;
  }
  .CircumstancesForm_input__HuBh2 {
    width: 100%;
  }
  .CircumstancesForm_textarea__5ifYr {
    height: 140px;
    margin: 16px 0 20px;
  }
  .CircumstancesForm_button__SS9MR {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Claims/ClaimApplication/CircumstancesForm/CircumstancesForm.module.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,aAAA;EACA,WAAA;EACA,SAAA;AAFF;;AAKA;EACE,qBAAA;AAFF;;AAKA;EACE,aAAA;EACA,cAAA;AAFF;;AAKA;EACE,WAAA;EACA,mBAAA;AAFF;;AAKA;EACE,YAAA;AAFF;;AAKA;EACE;IACE,sBAAA;IACA,SAAA;EAFF;EAKA;IACE,WAAA;EAHF;EAMA;IACE,aAAA;IACA,mBAAA;EAJF;EAOA;IACE,WAAA;EALF;AACF","sourcesContent":["@use 'styles/colors.scss';\n@use 'styles/sizes.scss';\n\n.form {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.inputs {\n  display: flex;\n  width: 100%;\n  gap: 20px;\n}\n\n.input {\n  width: calc(100% / 3);\n}\n\n.textarea {\n  height: 212px;\n  margin: 28px 0;\n}\n\n.contract {\n  width: 100%;\n  margin-bottom: 32px;\n}\n\n.button {\n  width: 200px;\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .inputs {\n    flex-direction: column;\n    gap: 16px;\n  }\n\n  .input {\n    width: 100%;\n  }\n\n  .textarea {\n    height: 140px;\n    margin: 16px 0 20px;\n  }\n\n  .button {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `CircumstancesForm_form__MopWT`,
	"inputs": `CircumstancesForm_inputs__5U-oa`,
	"input": `CircumstancesForm_input__HuBh2`,
	"textarea": `CircumstancesForm_textarea__5ifYr`,
	"contract": `CircumstancesForm_contract__ZgruG`,
	"button": `CircumstancesForm_button__SS9MR`
};
export default ___CSS_LOADER_EXPORT___;
