import classNames from 'classnames'
import style from './Bloc.module.scss'

const Bloc = ({ size, title, subtitle, content }) => {

  return (
    <section className={classNames(style.bloc, style[size])}>
      <header>
        <h2>{title}</h2>
        {subtitle && <p>{subtitle}</p>}
      </header>
      {content}
    </section>
  )
}

export default Bloc
