// Modules
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useOutletContext } from "react-router-dom"

// Components
import Actions from 'components/reporting/components/Actions/Actions'
import StateData from 'components/reporting/components/StateData/StateData'
import Bloc from 'components/reporting/components/Bloc/Bloc'
import DoughnutChart from 'components/reporting/components/graphs/Doughnut/Doughnut'
import HistogramChart from 'components/reporting/components/graphs/Histogram/Histogram'

// Helpers
import { getDatasFrom } from 'helpers/chartjs'

//Styles
import colors from 'components/reporting/styles/colors.scss'

const ClaimsReport = () => {
  const { t } = useTranslation()
  const [contract] = useOutletContext()
  const [currentContract, setCurrentContract] = useState(null)
  const [totalFiled, setTotalFiled] = useState(null)
  const [totalApproved, setTotalApproved] = useState(null)
  const [filedThisMonth, setFiledThisMonth] = useState(null)
  const [ratio, setRatio] = useState(null)
  const [processedClaims, setProcessedClaims] = useState({})
  const [claimsPerPackage, setClaimsPerPackage] = useState({})
  const [topClaims, setTopClaims] = useState({})
  const [claimsStatus, setClaimsStatus] = useState({})


  useEffect(() => {

    const getDatas = async () => {
      if (currentContract === contract._id) return
      setCurrentContract(contract._id)

      try {
        Promise.all([
          getDatasFrom({ contract, endpoint: 'totalFiled' }),
          getDatasFrom({ contract, endpoint: 'totalApproved' }),
          getDatasFrom({ contract, endpoint: 'filedThisMonth' }),
          getDatasFrom({ contract, endpoint: 'ratio' }),
          getDatasFrom({ contract, endpoint: 'processedClaims' }),
          getDatasFrom({ contract, endpoint: 'claimsPerPackage' }),
          getDatasFrom({ contract, endpoint: 'topClaims' }),
          getDatasFrom({ contract, endpoint: 'claimsStatus' })
        ]).then( values => {
          if (values[0]) setTotalFiled(values[0])
          if (values[1]) setTotalApproved(values[1])
          if (values[2]) setFiledThisMonth(values[2])
          if (values[3]) setRatio(values[3])
          if (values[4]) setProcessedClaims(values[4])
          if (values[5]) setClaimsPerPackage(values[5])
          if (values[6]) setTopClaims(values[6])
          if (values[7]) setClaimsStatus(values[7])
        })

      } catch (error) {
        console.log('Fetch datas error')
      }
    }
    if (contract) getDatas()
  }, [contract])

  return (
    <>
      {/* <Actions /> */}

      <StateData
        title={t('reporting:graphs.totalFiled')}
        data={totalFiled}
        size='quart'
      />

      <StateData
        title={t('reporting:graphs.totalApproved')}
        data={totalApproved}
        size='quart'
      />

      <StateData
        title={t('reporting:graphs.filedThisMonth')}
        data={filedThisMonth}
        size='quart'
      />

      <StateData
        title={t('reporting:graphs.ratio')}
        data={ratio}
        size='quart'
      />

      <Bloc
        title={t('reporting:graphs.processedClaims.title')}
        subtitle={t('reporting:graphs.range', {type: null})}
        content={<HistogramChart
          backgroundColor={[
            colors.blue1,
            colors.blue4,
            colors.orange2,
            colors.orange4
          ]}
          legendID='processedClaims'
          datas={processedClaims}
          monthsAxisX={true}
        />}
      />

      <Bloc
        title={t('reporting:graphs.claimsPerPackage.title')}
        subtitle={t('reporting:graphs.claimsPerPackage.subtitle', {number: 0})}
        size='tier'
        content={<DoughnutChart
          backgroundColor={[
            colors.blue1,
            colors.blue2,
            colors.blue3,
            colors.blue4,
            colors.blue5
          ]}
          legendID='claimsPerPackage'
          datas={claimsPerPackage}
        />}
      />

      <Bloc
        title={t('reporting:graphs.topClaims.title')}
        subtitle={t('reporting:graphs.topClaims.subtitle')}
        size='tier'
        content={<DoughnutChart
          backgroundColor={[
            colors.grey1,
            colors.grey2,
            colors.grey3,
            colors.grey4,
            colors.grey5
          ]}
          legendID='legendTopClaims'
          datas={topClaims}
        />}
      />
      
      <Bloc
        title={t('reporting:graphs.claimsStatus.title')}
        subtitle={t('reporting:graphs.claimsStatus.subtitle', {number: 0})}
        size='tier'
        content={<DoughnutChart
          backgroundColor={[
            colors.grey1,
            colors.grey2,
            colors.grey3,
            colors.grey4,
            colors.grey5
          ]}
          legendID='claimsStatus'
          datas={claimsStatus}
        />}
      />
    </>
  )
};

export default ClaimsReport
