import style from './CustomLegend.module.scss'

const CustomLegend = ({id}) => ( <div id={id}></div> )

const getOrCreateLegendList = (inline, id) => {
    const legendContainer = document.getElementById(id)
    let listContainer = legendContainer.querySelector('ul')
    
    if (!listContainer) {
        listContainer = document.createElement('ul')
        listContainer.classList.add(style.legend, inline && style.inline)
        legendContainer.appendChild(listContainer)
    }
    
    return listContainer
}

    
export const htmlLegendPlugin = ({data, inline, percent, reverse, backgroundColor}) => ({
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(inline, options.legendID)
    
        while (ul.firstChild) {
            ul.firstChild.remove()
        }

        const baseLabels = chart.options.plugins.legend.labels.generateLabels(chart)
        const labels = reverse ? baseLabels.reverse() : baseLabels
        const total = percent && data
            ? data.reduce( (acc, cur) => acc + cur , 0)
            : null

        labels.forEach((item, idx) => {
            if (!item.text) return

            const li = document.createElement('li');
            li.classList.add(style.item, item.hidden && style.hidden)

            // Box style
            const boxSpan = document.createElement('span')
            boxSpan.style.backgroundColor = backgroundColor[idx]
            boxSpan.classList.add(style.color)

            // Texts container
            const textContainer = document.createElement('p')
            textContainer.classList.add(style.text)

            // Label (text 1)
            const labelContainer = document.createElement('span')
            labelContainer.classList.add(style.label)
            const labelText = document.createTextNode(item.text)
            labelContainer.appendChild(labelText)
            textContainer.appendChild(labelContainer)

            // Percent (text 2, optionnal)
            if (percent){
                const valContainer = document.createElement('span')
                const value = document.createTextNode((Math.round(data[idx] * 100 / total * 10) / 10)+"%")
                valContainer.appendChild(value)
                textContainer.appendChild(valContainer)
            }

            // Final build
            li.appendChild(boxSpan)
            li.appendChild(textContainer)
            ul.appendChild(li)
        })
    }
})

export default CustomLegend





// const ul = getOrCreateLegendList(inline, options.legendID)
    
// while (ul.firstChild) {
//     ul.firstChild.remove()
// }

// const baseLabels = chart.options.plugins.legend.labels.generateLabels(chart)
// const labels = reverse
//     ? baseLabels.reverse()
//     : baseLabels
// const total = percent && data
//     ? data.reduce( (acc, cur) => acc + cur , 0)
//     : null

// <ul className={classNames(style.legend, inline && style.inline)}>
//     {labels.map((label, index) => {
//         return (
//             <li key={`${options.legendID}-${index}`} className={classNames(style.item, label.hidden && style.hidden)}>
//                 <p className={style.text}>
//                     <span className={style.color} style={{backgroundColor: label.fillStyle}}></span>
//                     <span className={style.label}>{label.text}</span>
//                     {percent &&
//                         <span>
//                             {(Math.round(data[index] * 100 / total * 10) / 10)+"%"}
//                         </span>
//                     }
//                 </p>
//             </li>
//         )
//     })}
// </ul>