// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.masked-input {
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  border: 1px solid #00007a;
  border-radius: 8px;
  outline: none;
  color: #00007a;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
}
.masked-input.error {
  border: 1px solid #bd454a;
}
.masked-input:focus {
  border: 1px solid #1414ff;
}
.masked-input::placeholder {
  color: #d8d8ff;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
}`, "",{"version":3,"sources":["webpack://./src/components/Subscription/MainForm/MainForm.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,cCFS;EDGT,gBAAA;EACA,eAAA;EACA,iBAAA;AADF;AAGE;EACE,yBAAA;AADJ;AAIE;EACE,yBAAA;AAFJ;AAKE;EACE,cCtBO;EDuBP,gBAAA;EACA,eAAA;EACA,iBAAA;AAHJ","sourcesContent":["@use 'styles/colors.scss';\n\n.masked-input {\n  width: 100%;\n  height: 40px;\n  padding: 6px 12px;\n  border: 1px solid colors.$primary8;\n  border-radius: 8px;\n  outline: none;\n  color: colors.$primary8;\n  font-weight: 300;\n  font-size: 16px;\n  line-height: 28px;\n\n  &.error {\n    border: 1px solid colors.$danger5;\n  }\n\n  &:focus {\n    border: 1px solid colors.$primary5;\n  }\n\n  &::placeholder {\n    color: colors.$primary2;\n    font-weight: 300;\n    font-size: 16px;\n    line-height: 28px;\n  }\n}\n","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
