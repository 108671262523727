import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

import style from './SuccessPage.module.scss'
import Title from 'components/common/PageTitle/PageTitle'

const SuccessPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.accessToken) navigate('/sign-in');
  }, [navigate, state]);

  if (!state) return null;

  return (
    <div className={style.success}>
      <Title
        className={style.title}
        text={
          <>
            {t('subscription:success.title', {
              name: state.client.firstname,
            })}{' '}
            🎉
          </>
        }
      />
      <p className={style.subtitle}>{t('subscription:success.subtitle')}</p>
    </div>
  );
};

export default SuccessPage;
