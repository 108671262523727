// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/checkbox.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkbox_checkbox__JYSU0 {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.Checkbox_checkbox__JYSU0 .Checkbox_input__74E1c {
  padding-top: 2px;
}
.Checkbox_checkbox__JYSU0 input[type=checkbox] {
  appearance: none;
  min-width: 18px;
  height: 18px;
  top: 15px;
  border: 1.5px solid #1414ff;
  border-radius: 3px;
}
.Checkbox_checkbox__JYSU0 input[type=checkbox]:checked {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
}
.Checkbox_checkbox__JYSU0 input[type=checkbox]:disabled {
  border-color: #d8d8ff;
}
.Checkbox_checkbox__JYSU0 input[type=checkbox]:disabled + label {
  color: #d8d8ff;
}
.Checkbox_checkbox__JYSU0 label {
  color: #00007a;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}
.Checkbox_checkbox__JYSU0:disabled label {
  color: #d8d8ff;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Checkbox/Checkbox.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,SAAA;AADF;AAGE;EACE,gBAAA;AADJ;AAIE;EAEE,gBAAA;EACA,eAAA;EACA,YAAA;EACA,SAAA;EACA,2BAAA;EACA,kBAAA;AAFJ;AAII;EACE,mDAAA;EACA,2BAAA;AAFN;AAKI;EACE,qBCzBK;ADsBX;AAKM;EACE,cC5BG;ADyBX;AAQE;EACE,cC5BO;ED6BP,gBAAA;EACA,eAAA;EACA,iBAAA;AANJ;AAUI;EACE,cC1CK;ADkCX","sourcesContent":["@use 'styles/colors.scss';\n\n.checkbox {\n  display: flex;\n  align-items: flex-start;\n  gap: 10px;\n\n  .input {\n    padding-top: 2px;\n  }\n\n  input[type='checkbox'] {\n    -webkit-appearance: none;\n    appearance: none;\n    min-width: 18px;\n    height: 18px;\n    top: 15px;\n    border: 1.5px solid colors.$primary5;\n    border-radius: 3px;\n\n    &:checked {\n      background: url(../../../assets/checkbox.svg);\n      background-position: center;\n    }\n\n    &:disabled {\n      border-color: colors.$primary2;\n\n      & + label {\n        color: colors.$primary2;\n      }\n    }\n  }\n\n  label {\n    color: colors.$primary8;\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 28px;\n  }\n\n  &:disabled {\n    label {\n      color: colors.$primary2;\n    }\n  }\n}\n","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": `Checkbox_checkbox__JYSU0`,
	"input": `Checkbox_input__74E1c`
};
export default ___CSS_LOADER_EXPORT___;
