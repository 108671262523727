// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Actions_aside__9bWZb {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/reporting/components/Actions/Actions.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,aAAA;EACA,8BAAA;AACF","sourcesContent":[".aside{\n  width: 100%;\n  margin-bottom: 5px;\n  display: flex;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aside": `Actions_aside__9bWZb`
};
export default ___CSS_LOADER_EXPORT___;
