// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputError_input-error__KXtz9 {
  margin-top: 4px;
  padding: 0 8px;
  color: #b94402;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.InputError_input-error__KXtz9 .InputError_input-error-prefix__LU6gz {
  margin: 0;
  padding: 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/InputError/InputError.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;EACA,cAAA;EACA,cCkBW;EDjBX,gBAAA;EACA,eAAA;EACA,iBAAA;AAFF;AAIE;EACE,SAAA;EACA,eAAA;AAFJ","sourcesContent":["@use 'styles/colors.scss';\n\n\n.input-error {\n  margin-top: 4px;\n  padding: 0 8px;\n  color: colors.$attention7;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n\n  .input-error-prefix {\n    margin: 0;\n    padding: 0 20px;\n  }\n}","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-error": `InputError_input-error__KXtz9`,
	"input-error-prefix": `InputError_input-error-prefix__LU6gz`
};
export default ___CSS_LOADER_EXPORT___;
