import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import api from 'helpers/api'

// Components
import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'
import Envelope from 'components/assets/icons/Envelope'
import Phone from 'components/assets/icons/Phone'
import Button from 'components/common/Button/Button'
import Title from 'components/common/PageTitle/PageTitle'
import Spinner from 'components/common/Spinner/Spinner'
import { next } from 'components/Freemium/Nav/Nav'

// Styles
import style from './SignatureChoice.module.scss'
import mainStyle from '../FreemiumComponents.module.scss'

const SignatureChoice = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state, pathname } = useLocation()
  const [otpType, setOtpType] = useState(false)
  const [isSpinnerLoading, setIsSpinnerLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  const otpSmsType = 'otp_sms'
  const otpMailType = 'otp_email'

  // Get signature link
    const getAdhesion = async () => {
      try {
        const contract = {
          ...state.contract,
          contractType: state.contract.contractType.slice(-1)[0]
        }
        const { price, paymentPlan } = contract
        const res = await api.post({
          url: '/adhesions/client',
          data: {
            ...state.listingData,
            ...state.client,
            ...state.contract,
            //contractType: state.contract.contractType.slice(-1)[0],
            contractType: state.contract.contractType.slice(-1)[0]._id,
            paymentPlan,
            price: Number(price.replace(',','.')),
            idCampaign: state.idCampaign,
            phone: state.client.phone.number,
            countryCode: state.client.phone.countryCode,
            birthdate: state.client.birthdate,
            transactionHorodate: new Date()
          },
          headers: { Authorization: `Bearer ${state.token}` },
        })
        return (res?.response && res?.response.compliance)
          ? res.response.id
          : null
      } catch (error) {
        return null
      }
    }
    // Get signature link
    const getSignatureLink = async (event) => {
      event.preventDefault()
      try {
        setIsSpinnerLoading(true)
        const adhesionId = !state.client.paidOption
          ? await getAdhesion()
          : state.saveID
        const res = await api.post({
          url: '/subscriptions/sign',
          data: { adhesionId, email: state.client.email, otpType: otpType },
          headers: { Authorization: `Bearer ${state.token}` },
        })
        const signatureLink = res?.response?.signatureLink
        if (signatureLink){
          next({
            pathname, navigate, state,
            data: { client: { signatureLink } }
          })
        } else {
          setErrorMessage(true)
        }
      } catch (error) {
        setErrorMessage(true)
      } finally {
        setIsSpinnerLoading(false);
      }
    }


  return (
    <>
      {isSpinnerLoading && <Spinner />}
      {errorMessage && <ResponseMessage label={t('common:form.genericError')} />}

      <Title className={mainStyle['page-title']} text={t('freemium:signature.title')} />

      <form className={mainStyle.form} onSubmit={getSignatureLink}>

        <div className={style.check}>
          <div className={style['check-methods']}>

            <div
              className={classNames(style['check-method'], otpType === otpSmsType && style.selected)}
              onClick={() => setOtpType(otpSmsType)}
            >
              <Phone styles={{ fill: otpType === otpSmsType ? '#fff' : '#8686FF' }} />
              <p>{t('freemium:signature.bySms')}</p>
            </div>

            <div
              className={classNames(style['check-method'], otpType === otpMailType && style.selected)}
              onClick={() => setOtpType(otpMailType)}
            >
              <Envelope styles={{ fill: otpType === otpMailType ? '#fff' : '#8686FF' }}/>
              <p>{t('freemium:signature.byMail')}</p>
            </div>

          </div>
        </div>


        <Button
          className={mainStyle.button}
          disabled={!(otpType)}
          label={t('common:next')}
          onClick={getSignatureLink}
          type="primary"
        />
      </form>
    </>
  )
}

export default SignatureChoice
