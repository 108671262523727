import { useState } from 'react'
import { useSearchParams, Navigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import Eye from 'components/assets/icons/Eye'
import { passwordValidation } from 'constants/inputValidation'
import PrefixedText from 'components/common/PrefixedText/PrefixedText'
import Valid from 'components/assets/icons/Valid'
import ExclamationTriangle from 'components/assets/icons/ExclamationTriangle'
import api from 'helpers/api'
import style from 'styles/auth.module.scss'
import Header from 'components/Header/Header'

const ResetPasswordRequested = () => {
  const [isPasswordShown, setIsPasswordShown] = useState(false)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const accessToken = localStorage.getItem('accessToken')
  const token = searchParams.get('token')

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isValid },
    getValues,
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
    mode: 'onTouched',
  })

  const togglePasswordVisibility = () => {
    setIsPasswordShown(!isPasswordShown)
  }

  const [password, passwordConfirmation] = getValues(['password', 'passwordConfirmation'])

  const onSubmit = handleSubmit(async () => {
    const res = await api.put({
      url: '/auth/clients/reset_password',
      data: { password, passwordConfirmation },
      headers: { Authorization: `Bearer ${token}` },
    })

    if (res.success) {
      navigate('/sign-in')
    }
  })

  return accessToken
  ? <Navigate to='/contracts' replace />
  : (
    <div className={style['auth']}>
      <Header darkBg styles={{ position: 'absolute', top: 0 }} />
      <h2 className={style.title}>{t('main:auth.resetPassword.newPassword')}</h2>

      <form onSubmit={onSubmit}>
        <div className={style.inputs}>
          <div className={style.input}>
            <Input
              register={register('password', passwordValidation(t))}
              darkBg
              label={t('main:auth.password.password')}
              placeholder={t('main:auth.password.placeholder')}
              type={isPasswordShown ? 'text' : 'password'}
              suffix={<Eye className="clickable" />}
              onSuffixClick={togglePasswordVisibility}
            />
            {errors.password && (
              <div className={style['input-error']}>
                <p>{t('main:auth.password.required')}</p>
                {Object.values(errors.password.types).map( (error, id) => {
                  return <div key={'err'+id}>{error}</div>
                })}
              </div>
            )}
          </div>

          <div className={style.input}>
            <Input
              register={register('passwordConfirmation', passwordValidation(t))}
              darkBg
              label={t('main:auth.resetPassword.confirm')}
              placeholder={t('main:auth.resetPassword.confirmPlaceholder')}
              type={isPasswordShown ? 'text' : 'password'}
            />
            {touchedFields.passwordConfirmation && (
              <div className={style['input-error']}>
                <PrefixedText
                  className={style['input-error-prefix']}
                  prefix={password === passwordConfirmation ? <Valid /> : <ExclamationTriangle />}
                  text={t('main:auth.password.notMatching')}
                />
              </div>
            )}
          </div>
        </div>

        <Button label={t('common:submit')} onClick={handleSubmit} type="primary" disabled={!isValid} />
      </form>
    </div>
  )
}

export default ResetPasswordRequested
