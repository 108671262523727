// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Link_link__wk9ef {
  display: inline-block;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
}
.Link_link__wk9ef a {
  text-decoration: none;
}
.Link_link__wk9ef a:link, .Link_link__wk9ef a:visited, .Link_link__wk9ef a:hover {
  color: #000078;
}
.Link_link__wk9ef a:hover {
  text-decoration: underline;
}
.Link_link__wk9ef a:active {
  font-weight: 400;
  color: #00007a;
}
.Link_link__wk9ef.Link_dark-bg__czaKY a {
  color: #fff;
}
.Link_link__wk9ef.Link_error__6\\+tQf a:link, .Link_link__wk9ef.Link_error__6\\+tQf a:visited, .Link_link__wk9ef.Link_error__6\\+tQf a:hover, .Link_link__wk9ef.Link_error__6\\+tQf a:active {
  color: #911727;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Link/Link.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AADF;AAGE;EACE,qBAAA;AADJ;AAGI;EAGE,cCJM;ADCZ;AAMI;EACE,0BAAA;AAJN;AAOI;EACE,gBAAA;EACA,cChBK;ADWX;AAUI;EACE,WAAA;AARN;AAcM;EAIE,cCTE;ADNV","sourcesContent":["@use 'styles/colors.scss';\n\n.link {\n  display: inline-block;\n  font-weight: 300;\n  font-size: 16px;\n  line-height: 28px;\n\n  a {\n    text-decoration: none;\n\n    &:link,\n    &:visited,\n    &:hover {\n      color: colors.$primary11;\n    }\n\n    &:hover {\n      text-decoration: underline;\n    }\n\n    &:active {\n      font-weight: 400;\n      color: colors.$primary8;\n    }\n  }\n\n  &.dark-bg {\n    a {\n      color: #fff;\n    }\n  }\n\n  &.error {\n    a {\n      &:link,\n      &:visited,\n      &:hover,\n      &:active {\n        color: colors.$danger6;\n      }\n    }\n  }\n}\n","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `Link_link__wk9ef`,
	"dark-bg": `Link_dark-bg__czaKY`,
	"error": `Link_error__6+tQf`
};
export default ___CSS_LOADER_EXPORT___;
