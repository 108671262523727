// Modules
import { useForm, Controller } from 'react-hook-form'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import MaskedInput from 'react-text-mask'
import api from 'helpers/api'

// Components
import Spinner from 'components/common/Spinner/Spinner'
import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'
import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import InputError from 'components/common/InputError/InputError'
import Title from 'components/common/PageTitle/PageTitle'
import { expirationDateValidationFx, numberValidationCardNumberFx, numberValidationCVCFx, formParams } from 'constants/inputValidation'
import { next } from 'components/Freemium/Nav/Nav'
import mainStyle from '../../FreemiumComponents.module.scss'

const CardForm = ({ state }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [errorMessage, setErrorMessage] = useState(false);
  const [isSpinnerLoading, setIsSpinnerLoading] = useState(false)
  const contract = {
    ...state.contract,
    contractType: state.contract.contractType.slice(-1)[0]
  }
  const { price, paymentPlan } = contract


  // Initialize form
  const required = t('common:form.incorrect')
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control
  } = useForm({
    ...formParams,
    values:{
      owner: '',
      number: '',
      expirationDate: '',
      ccv: ''
    }
  })


  // Submit form
  const onSubmit = handleSubmit(async data => {
    try {
      setIsSpinnerLoading(true);
      const [expMonth, expYear] = data.expirationDate.split('/')
      console.log(state)
      const res = await api.post({
        url: '/adhesions/client',
        data: {
          ...state.listingData,
          ...contract,
          ...state.client,
          price: Number(price.replace(',','.')),
          idCampaign: state.idCampaign,
          phone: state.client.phone.number,
          countryCode: state.client.phone.countryCode,
          birthdate: state.client.birthdate,
          transactionHorodate: new Date(),
          paymentType: 'credit card',
          paymentPlan,
          contractType: contract.contractType._id,
          cardOwnerFullName: data.owner,
          cardNumber: data.number.replace(/\D/g, ''),
          cvc: data.ccv.replace(/\D/g, ''),
          cardExpMonth: expMonth,
          cardExpYear: expYear,
          frequency: paymentPlan,
        },
        headers: { Authorization: `Bearer ${state.token}` },
      })

      if (res?.response && res?.response.compliance) {
        next({
          pathname, navigate, state,
          data: { saveID: res.response.id }
        })
      } else {
        setErrorMessage(true)
      }
    } catch (error) {
      setErrorMessage(true)
    } finally {
      setIsSpinnerLoading(false);
    }
  })


  return price ? (
    <>
      {isSpinnerLoading && <Spinner />}
      {errorMessage && <ResponseMessage className='response-message' label={t('common:form.genericError')} />}

      <Title className={mainStyle['page-title']} text={<>{t('freemium:payment.creditCardTitle')} 💳</>} />
      <p className={mainStyle.subtitle}>{t('freemium:payment.subtitle')}</p>

      <form className={mainStyle.form} onSubmit={onSubmit}>

        <Input
          error={errors.owner}
          register={register('owner', { required })}
          label={t('common:paymentMethod.creditCard.owner.label')+'*'}
          placeholder={t('common:paymentMethod.creditCard.owner.placeholder')}
          disallowNumbers={true}
        />
        <InputError error={errors.owner} />


        <div className="input-label">{t('common:paymentMethod.creditCard.number.label')+'*'}</div>
        <Controller
          control={control}
          name="number"
          rules={numberValidationCardNumberFx(t)}
          render={({ field: { onBlur, onChange, value } }) => (
            <MaskedInput
              className={classNames('masked-input', errors.number && 'error')}
              mask={[/\d/, /\d/, /\d/, /\d/,
                ' ', /\d/, /\d/, /\d/, /\d/,
                ' ', /\d/, /\d/, /\d/, /\d/,
                ' ', /\d/, /\d/, /\d/, /\d/,
              ]}
              guide={false}
              keepCharPositions={false}
              onBlur={onBlur}
              onChange={value => onChange(value)}
              placeholder={t('common:paymentMethod.creditCard.number.placeholder')}
              value={value}
              disallowletters={value}
            />
          )}
        />
        <InputError error={errors.number} />


        <div className="input-label">{t('common:form.expirationDate.label')+'*'}</div>
        <Controller
          control={control}
          name="expirationDate"
          rules={expirationDateValidationFx(t)}
          render={({ field: { onBlur, onChange, value } }) => (
            <MaskedInput
              className={classNames('masked-input', errors.expirationDate && 'error')}
              mask={[/\d/, /\d/, '/', /\d/, /\d/]}
              guide={false}
              keepCharPositions={false}
              onBlur={onBlur}
              onChange={value => onChange(value)}
              placeholder={t('common:form.expirationDate.label')}
              value={value}
              disallowletters={value}
            />
          )}
        />
        <InputError error={errors.expirationDate} />


        <Input
          maxLength="4"
          error={errors.ccv}
          register={register('ccv', numberValidationCVCFx(t))}
          label={t('common:paymentMethod.creditCard.ccv.placeholderMobile')+'*'}
          placeholder={t('common:paymentMethod.creditCard.ccv.placeholderMobile')}
          disallowLetters={true}
        />
        <InputError error={errors.ccv} />


        <p className={mainStyle.required}>{t('common:form.mandatoryFields')}</p>


        <Button
          className={mainStyle.button}
          label={t('freemium:payment.button', { price })}
          onClick={handleSubmit}
          type="primary"
          disabled={!isValid}
        />
      </form>
    </>
  ) : <p style={{ color: 'red' }}>Une erreur est survenue. </p>
}

export default CardForm
