import { useTranslation } from 'react-i18next'

import Bloc from '../Bloc/Bloc'
import { formatNb } from 'functions'
import style from './StateData.module.scss'

const StateData = ({ title, data, size }) => {
  const { t } = useTranslation()

  return (
    <Bloc
      title={title}
      size={size ?? 'tier'}
      content={
        data && <>
          <span className={style.mainNb}>{formatNb(data.main)}</span>
          <footer className={style.progress}>
            {t('reporting:graphs.pastMonth')} {formatNb(data.past)}
            <span className={data.progress > 0 ? style.better : style.worst}>
              {data.progress > 0 && '+'}{Math.round(data.progress * 10) / 10}%
            </span>
          </footer>
        </>
      }
    />
  )
}

export default StateData
