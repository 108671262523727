// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Unsuccess_success__TpzY0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 630px;
  text-align: center;
}

.Unsuccess_title__2X0ND {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 32px;
  line-height: 52px;
  color: #00007a;
}

.Unsuccess_subtitle__jR\\+9I {
  margin: 40px 0 52px;
  font-weight: 300;
  font-size: 20px;
  line-height: 36px;
  color: #00007a;
}

@media screen and (max-width: 600px) {
  .Unsuccess_success__TpzY0 {
    width: 100%;
  }
  .Unsuccess_title__2X0ND {
    font-size: 28px;
    font-weight: 400;
    line-height: 48px;
  }
  .Unsuccess_subtitle__jR\\+9I {
    margin: 32px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Freemium/Unsuccess/Unsuccess.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;AAFF;;AAKA;EACE,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cCVS;ADQX;;AAKA;EACE,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cClBS;ADgBX;;AAKA;EACE;IACE,WAAA;EAFF;EAKA;IACE,eAAA;IACA,gBAAA;IACA,iBAAA;EAHF;EAMA;IACE,cAAA;IACA,eAAA;IACA,kBAAA;IACA,gBAAA;IACA,iBAAA;EAJF;AACF","sourcesContent":["@use 'styles/colors.scss';\n@use 'styles/sizes.scss';\n\n.success {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 630px;\n  text-align: center;\n}\n\n.title {\n  margin-bottom: 0;\n  font-weight: 400;\n  font-size: 32px;\n  line-height: 52px;\n  color: colors.$primary8;\n}\n\n.subtitle {\n  margin: 40px 0 52px;\n  font-weight: 300;\n  font-size: 20px;\n  line-height: 36px;\n  color: colors.$primary8;\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .success {\n    width: 100%;\n  }\n\n  .title {\n    font-size: 28px;\n    font-weight: 400;\n    line-height: 48px;\n  }\n\n  .subtitle {\n    margin: 32px 0;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 28px;\n  }\n}\n","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": `Unsuccess_success__TpzY0`,
	"title": `Unsuccess_title__2X0ND`,
	"subtitle": `Unsuccess_subtitle__jR+9I`
};
export default ___CSS_LOADER_EXPORT___;
