// Modules
import { Outlet, useLocation, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useState } from 'react'
import { isMobile } from 'helpers/styles'

// Components
import ProgressBar from 'components/common/ProgressBar/ProgressBar'
import Back from 'components/common/Back/Back'
import Header from 'components/Header/Header'
import ContentWrapper from '../common/ContentWrapper/ContentWrapper'
import Collapsible from 'components/common/Collapsible/Collapsible'
import { LEGAL_NOTICES } from 'constants/contract'
import { pages, currentStep } from 'components/Freemium/Nav/Nav'

// Styles
import style from './Freemium.module.scss'
import './FreemiumComponents.module.scss'

const Subscription = () => {
  const { pathname, state } = useLocation()
  const { t } = useTranslation()


  // Notice

  const [areNoticesOpened, setAreNoticesOpened] = useState(false)

  const toggleNotices = () => {
    setAreNoticesOpened(!areNoticesOpened)
    isMobile() && window.scrollTo(0, 0)
  }

  const renderContent = () => {
    return <div dangerouslySetInnerHTML={{ __html: LEGAL_NOTICES }} />
  }

  const LegalNotices = () => {
    return !pathname.includes('signature') && (
      <div className={classNames(style.notices, areNoticesOpened && style['notices-opened'])}>
        <Collapsible
          className={style.collapsible}
          content={renderContent()}
          isArrowReversed
          isOpenedProp={areNoticesOpened}
          onToggle={toggleNotices}
          title={<span className={style['notices-title']}>{t('common:seeLegalNotices')}</span>}
        />
      </div>
    )
  }


  // Navigation
  const step = currentStep(pathname)
  const mainPath = pathname.split('/').slice(0, 3)
  const isSuccessStep = pathname.includes('success')
  const fromLink = () => {
    const skip = ((pathname.includes('signature-choice') && !state.client.paidOption) || (pathname.includes('more') && !state.client.partnerOption)) ? 2 : 1
    return mainPath.concat(pages[step - skip]).join('/')
  }
  window.scrollTo(0, 0)


  if ((step >= 1 && !state?.contract) || (step >= 2 && !state?.client)){
    return <Navigate to="/sign-in" replace={true} />
  }


  return (
    <div className={classNames(style.subscription, isSuccessStep && style['justify-center'])}>
      <Header className={style.header} lightBg />

      {!isSuccessStep && step !== 0 &&
        <Back
          className={style.back}
          from={fromLink()}
        />
      }

      {!isSuccessStep &&
        <ProgressBar
          className={style.progress}
          stepNumber={pages.length}
          step={step}
        />
      }

      <ContentWrapper>
        <Outlet />
      </ContentWrapper>

      <LegalNotices />
    </div>
  )
}

export default Subscription
