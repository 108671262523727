import classNames from 'classnames'
import style from './Code.module.scss'

const Code = ({id, label, value, length, register, darkBg}) => {

  const steps = length * 2 - 1
  const chars = value.split('')

  return (
    <div className={style.container}>
      <label className={classNames('input-label', darkBg && 'dark-bg')} htmlFor={id}>
        {label}
      </label>

      <input
        id={id}
        className={style.input}
        type='text'
        maxLength={length}
        {...register}
      />

      <div className={style.dashed} style={{}}>
        {new Array(length).fill(0).map( (_, id) => {
          return(
            <span
              key={'border-'+id}
              style={{
                width: `calc(100% / ${steps})`,
                marginRight: `calc(100% / ${steps})`
              }}
            >
              {chars[id]}
            </span>
          )
        })}
      </div>
    </div>
  )
}

export default Code;