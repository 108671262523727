import { useEffect } from 'react'
import { Controller, useForm} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'

import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import { isInputError } from 'helpers/form'
import style from './MainForm.module.scss'
import Title from 'components/common/PageTitle/PageTitle'
import { birthdayValidation } from 'constants/inputValidation'
import MaskedInput from 'react-text-mask'
import './MainForm.scss'
import dayjs from 'dayjs'


const MainForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()

  useEffect(() => {
    if (!state?.contract || !state?.step) navigate('/sign-in')
  }, [navigate, state])

  const getDefaultValue = field => {
    return state.client[field] || ''
  }

  const maskedDate = date => {
    if (dayjs(date).isValid()) {
      return `${('0' + dayjs(date).date()).slice(-2)}/${('0' + (dayjs(date).month() + 1)).slice(-2)}/${dayjs(
        date,
      ).year()}`
    }

    return false
  }

  const birthdateOutput = maskedDate(state?.client?.birthdate)

  const {
    control,
    register,
    formState: { dirtyFields, errors, isValid },
    handleSubmit,
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    mode: 'onTouched',
    values: state?.client && {
      firstname: getDefaultValue('firstname'),
      lastname: getDefaultValue('lastname'),
      birthdate: birthdateOutput ? birthdateOutput : maskedDate(new Date()),
      placeOfBirth: getDefaultValue('placeOfBirth'),
      countryOfBirth: getDefaultValue('countryOfBirth'),
    },
  })

  const errorFields = Object.keys(errors)

  const onSubmit = handleSubmit(async data => {
    navigate('../contacts', {
      state: {
        client: state.client ? { ...state.client, ...data } : data,
        contract: state.contract,
        step: 2,
        token: state.token,
        idCampaign: state.idCampaign
      },
    })
  })

  const handleDateChange = (event, onChange, onBlur) => {
    const value = event.target.value;
    if (value.length === 10) {
      onChange(value);
      onBlur(value);
    } 
  }

  if (!state) return null

  return (
    <>
      <Title text={<>{t('subscription:main.title')} 👀</>} />

      <form className={style.form} onSubmit={onSubmit}>
        <div className={style.inputs}>
          <Input
            className={style.input}
            error={isInputError(errorFields, 'firstname')}
            register={register('firstname', { required: true })}
            label={t('common:form.firstname.label')}
            placeholder={`${t('common:your')} ${t('common:form.firstname.label').toLowerCase()}`}
            disallowNumbers={true}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'lastname')}
            register={register('lastname', { required: true })}
            label={t('common:form.lastname.label')}
            placeholder={`${t('common:your')} ${t('common:form.lastname.label').toLowerCase()}`}
            disallowNumbers={true}
          />
          <div className={classNames(style.datepicker, style.input)}>
            <div className="input-label">{t('common:form.birthdate.label')}</div>
            <Controller
              control={control}
              name="birthdate"
              rules={birthdayValidation}
              render={({ field: { onBlur, onChange, value } }) => (
                <MaskedInput
                  className={classNames('masked-input', isInputError(errorFields, 'birthdate') && 'error')}
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  guide={false}
                  keepCharPositions={false}
                  onBlur={onBlur}
                  onChange={value => handleDateChange(value, onChange, onBlur)}
                  placeholder={t('common:form.birthdate.placeholder')}
                  value={state?.client?.birthdate || value}
                />
              )}
            />
            {errorFields.includes('birthdate') && dirtyFields.hasOwnProperty('birthdate') && (
              <p className={style['input-error']}>{t('common:form.birthdate.error')}</p>
            )}
          </div>
          <Input
            className={style.input}
            error={isInputError(errorFields, 'placeOfBirth')}
            register={register('placeOfBirth', { required: true })}
            label={t('common:form.placeOfBirth.label')}
            placeholder={`${t('common:your')} ${t('common:form.placeOfBirth.label').toLowerCase()}`}
            disallowNumbers={true}
          />
          <Input
            className={style.input}
            error={isInputError(errorFields, 'countryOfBirth')}
            register={register('countryOfBirth', { required: true })}
            label={t('common:form.countryOfBirth.label')}
            placeholder={`${t('common:your')} ${t('common:form.countryOfBirth.label').toLowerCase()}`}
            disallowNumbers={true}
          />
        </div>

        <Button
          className={style.button}
          label={t('common:next')}
          onClick={handleSubmit}
          disabled={!isValid}
          type="primary"
        />
      </form>
    </>
  )
}

export default MainForm
