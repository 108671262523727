// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"light": `#FFFFFF`,
	"dark": `#121217`,
	"blue1": `#00007A`,
	"blue2": `#0000C9`,
	"blue3": `#4343FF`,
	"blue4": `#8686FF`,
	"blue5": `#D8D8FF`,
	"orange1": `#DA8200`,
	"orange2": `#FFA92C`,
	"orange3": `#FFC26B`,
	"orange4": `#FFE1B7`,
	"orange5": `#FFF3E3`,
	"grey1": `#3E3E50`,
	"grey2": `#838390`,
	"grey3": `#C3C3C9`,
	"grey4": `#E0E0E3`,
	"grey5": `#F9F9FA`
};
export default ___CSS_LOADER_EXPORT___;
