const MagicStick = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.20377 3.20373C2.26541 4.1421 2.26541 5.66348 3.20377 6.60185L4.52689 7.92496C4.53682 7.91347 4.54724 7.90226 4.55815 7.89135L7.89149 4.55802C7.90236 4.54714 7.91354 4.53675 7.92499 4.52684L6.60189 3.20373C5.66352 2.26537 4.14214 2.26537 3.20377 3.20373Z"/>
      <path d="M8.80655 5.40839C8.79664 5.41985 8.78625 5.43102 8.77537 5.4419L5.44204 8.77523C5.43113 8.78614 5.41991 8.79657 5.40843 8.8065L13.3981 16.7962C14.3365 17.7346 15.8579 17.7346 16.7962 16.7962C17.7346 15.8578 17.7346 14.3364 16.7962 13.3981L8.80655 5.40839Z"/>
      <path d="M13.4167 1.92262C13.5508 1.5813 14.0321 1.5813 14.1662 1.92262L14.5249 2.83535C14.5658 2.93956 14.648 3.02205 14.7518 3.06315L15.6612 3.42313C16.0013 3.55774 16.0013 4.0408 15.6612 4.17541L14.7518 4.53539C14.648 4.57649 14.5658 4.65898 14.5249 4.76318L14.1662 5.67592C14.0321 6.01724 13.5508 6.01724 13.4167 5.67592L13.058 4.76318C13.0171 4.65898 12.9349 4.57649 12.8311 4.53539L11.9217 4.17541C11.5817 4.0408 11.5817 3.55774 11.9217 3.42313L12.8311 3.06315C12.9349 3.02205 13.0171 2.93956 13.058 2.83535L13.4167 1.92262Z"/>
      <path d="M16.6393 7.60784C16.7734 7.26652 17.2547 7.26652 17.3888 7.60784L17.5196 7.94063C17.5605 8.04483 17.6427 8.12732 17.7466 8.16842L18.0781 8.29967C18.4182 8.43429 18.4182 8.91734 18.0781 9.05195L17.7466 9.18321C17.6427 9.2243 17.5605 9.30679 17.5196 9.411L17.3888 9.74379C17.2547 10.0851 16.7734 10.0851 16.6393 9.74379L16.5086 9.411C16.4676 9.30679 16.3854 9.2243 16.2816 9.18321L15.95 9.05195C15.61 8.91734 15.61 8.43429 15.95 8.29967L16.2816 8.16842C16.3854 8.12732 16.4676 8.04483 16.5086 7.94063L16.6393 7.60784Z"/>
      <path d="M4.27766 12.756C4.41178 12.4146 4.89306 12.4146 5.02718 12.756L5.15794 13.0887C5.19889 13.1929 5.28107 13.2754 5.3849 13.3165L5.71646 13.4478C6.05652 13.5824 6.05652 14.0655 5.71646 14.2001L5.3849 14.3313C5.28107 14.3724 5.19889 14.4549 5.15794 14.5591L5.02718 14.8919C4.89306 15.2332 4.41178 15.2332 4.27766 14.8919L4.1469 14.5591C4.10595 14.4549 4.02377 14.3724 3.91994 14.3313L3.58838 14.2001C3.24832 14.0655 3.24832 13.5824 3.58838 13.4478L3.91994 13.3165C4.02377 13.2754 4.10595 13.1929 4.1469 13.0887L4.27766 12.756Z"/>
    </svg>
  )
}

export default MagicStick
