// Modules
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useOutletContext } from "react-router-dom"

// Components
import StateData from 'components/reporting/components/StateData/StateData'
import Bloc from 'components/reporting/components/Bloc/Bloc'
import DoughnutChart from 'components/reporting/components/graphs/Doughnut/Doughnut'
import HistogramChart from 'components/reporting/components/graphs/Histogram/Histogram'

// Helpers
import { formatNb } from 'functions'
import { getDatasFrom } from 'helpers/chartjs'

//Styles
import colors from 'components/reporting/styles/colors.scss'

const Overview = () => {
  const { t } = useTranslation()
  const [contract] = useOutletContext()
  const [currentContract, setCurrentContract] = useState(null)
  const [insuredProducts, setInsuredProducts] = useState(null)
  const [claimsOpened, setClaimsOpened] = useState(null)
  const [totalRevenue, setTotalRevenue] = useState(null)
  const [revenuePerProduct, setRevenuePerProduct] = useState({})
  const [insurancesAndClaims, setInsurancesAndClaims] = useState({})
  const [adhesionPerPackage, setAdhesionPerPackage] = useState({})
  const [topDistributors, setTopDistributors] = useState({})
  const [topClaims, setTopClaims] = useState({})
  const totalAdhesionPerPackage = formatNb(adhesionPerPackage?.data?.reduce( (acc, cur) => acc + cur ,0))
  const totalDistributors = formatNb(topDistributors?.data?.reduce( (acc, cur) => acc + cur ,0))
  

  useEffect(() => {

    const getDatas = async () => {
      if (currentContract === contract._id) return
      setCurrentContract(contract._id)

      try {
        Promise.all([
          getDatasFrom({ contract, endpoint: 'insuredProducts' }),
          getDatasFrom({ contract, endpoint: 'claimsOpened' }),
          getDatasFrom({ contract, endpoint: 'totalRevenue' }),
          getDatasFrom({ contract, endpoint: 'revenuePerProduct' }),
          getDatasFrom({ contract, endpoint: 'insurancesAndClaims' }),
          getDatasFrom({ contract, endpoint: 'adhesionPerPackage' }),
          getDatasFrom({ contract, endpoint: 'topDistributors' }),
          getDatasFrom({ contract, endpoint: 'topClaims' }),
        ]).then( values => {
          if (values[0]) setInsuredProducts(values[0])
          if (values[1]) setClaimsOpened(values[1])
          if (values[2]) setTotalRevenue(values[2])
          if (values[3]) setRevenuePerProduct(values[3])
          if (values[4]) setInsurancesAndClaims(values[4])
          if (values[5]) setAdhesionPerPackage(values[5])
          if (values[6]) setTopDistributors(values[6])
          if (values[7]) setTopClaims(values[7])
        })

      } catch (error) {
        console.log('Fetch datas error')
      }
    }
    if (contract?._id) getDatas()
  }, [contract])

  return (
    <>
      <StateData
        title={t('reporting:graphs.insuredProducts')}
        data={insuredProducts}
      />

      <StateData
        title={t('reporting:graphs.totalRevenue')}
        data={totalRevenue}
      />

      <StateData
        title={t('reporting:graphs.claimsOpened')}
        data={claimsOpened}
      />

      {contract?.partner && <Bloc
        title={t('reporting:graphs.revenuePerProduct.title')}
        subtitle={t('reporting:graphs.range', {type: '(Euros)'})}
        size='mid'
        content={<HistogramChart
          backgroundColor={[
            colors.blue1,
            colors.blue4,
            colors.orange2,
            colors.orange4
          ]}
          legendID='legendRevenuePerProduct'
          datas={revenuePerProduct}
          stacked={true}
          monthsAxisX={true}
        />}
      />}

      <Bloc
        title={t('reporting:graphs.insurancesAndClaims.title')}
        subtitle={t('reporting:graphs.range', {type: null})}
        size={contract?.partner && 'mid'}
        content={<HistogramChart
          backgroundColor={[
            colors.blue4,
            colors.blue1
          ]}
          legendID='legendInsurancesAndClaims'
          datas={insurancesAndClaims}
          monthsAxisX={true}
        />}
      />

      <Bloc
        title={t('reporting:graphs.adhesionPerPackage.title')}
        subtitle={t('reporting:graphs.adhesionPerPackage.subtitle', {number: totalAdhesionPerPackage})}
        size='tier'
        content={<DoughnutChart
          backgroundColor={[
            colors.blue1,
            colors.blue2,
            colors.blue3,
            colors.blue4,
            colors.blue5
          ]}
          legendID='legendRateType'
          datas={adhesionPerPackage}
        />}
      />

      <Bloc
        title={t('reporting:graphs.topDistributor.title')}
        subtitle={t('reporting:graphs.topDistributor.subtitle', {number: totalDistributors})}
        size='tier'
        content={<DoughnutChart
          backgroundColor={[
            colors.orange1,
            colors.orange2,
            colors.orange3,
            colors.orange4,
            colors.orange5
          ]}
          legendID='legendTopDistributor'
          datas={topDistributors}
        />}
      />

      <Bloc
        title={t('reporting:graphs.topClaims.title')}
        subtitle={t('reporting:graphs.topClaims.subtitle')}
        size='tier'
        content={<DoughnutChart
          backgroundColor={[
            colors.grey1,
            colors.grey2,
            colors.grey3,
            colors.grey4,
            colors.grey5
          ]}
          legendID='legendTopClaims'
          datas={topClaims}
        />}
      />
    </>
  )
};

export default Overview
