// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StateData_mainNb__A7o0E {
  font-size: 32px;
  font-weight: 300;
  display: block;
  line-height: 52px;
  margin-top: -5px;
}

.StateData_progress__tdAjm {
  display: flex;
  justify-content: space-between;
}
.StateData_progress__tdAjm .StateData_better__PzB\\+y {
  color: #218214;
}
.StateData_progress__tdAjm .StateData_worst__SPoUQ {
  color: #7C1028;
}`, "",{"version":3,"sources":["webpack://./src/components/reporting/components/StateData/StateData.module.scss","webpack://./src/components/reporting/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;AAmBF;;AAhBA;EACE,aAAA;EACA,8BAAA;AAmBF;AAjBE;EACE,cCQK;ADWT;AAjBE;EACE,cCiBG;ADEP","sourcesContent":["@use 'components/reporting/styles/colors.scss';\n\n.mainNb{\n  font-size: 32px;\n  font-weight: 300;\n  display: block;\n  line-height: 52px;\n  margin-top: -5px;\n}\n\n.progress{\n  display: flex;\n  justify-content: space-between;\n\n  .better{\n    color: colors.$green2;\n  }\n  .worst{\n    color: colors.$red2;\n  }\n}","$dark: #121217;\n$light: #FFFFFF;\n\n$grey1: #3E3E50;\n$grey2: #838390;\n$grey3: #C3C3C9;\n$grey4: #E0E0E3;\n$grey5: #F9F9FA;\n\n$blue1: #00007A;\n$blue2: #0000C9;\n$blue3: #4343FF;\n$blue4: #8686FF;\n$blue5: #D8D8FF;\n$blueLogo: #000093;\n\n$orange1: #DA8200;\n$orange2: #FFA92C;\n$orange3: #FFC26B;\n$orange4: #FFE1B7;\n$orange5: #FFF3E3;\n\n$green1: #033E0F;\n$green2: #218214;\n$green3: #58B441;\n$green4: #89D96B;\n$green5: #E1F8CE;\n\n$brick1: #7D1F01;\n$brick2: #B94402;\n$brick3: #E78D3D;\n$brick4: #FBD298;\n$brick5: #FDEBCB;\n\n$red1: #540726;\n$red2: #7C1028;\n$red3: #BD454A;\n$red4: #F4ADA1;\n$red5: #F9D9CF;\n\n\n:export {\n    light: $light;\n    dark: $dark;\n    blue1: $blue1;\n    blue2: $blue2;\n    blue3: $blue3;\n    blue4: $blue4;\n    blue5: $blue5;\n    orange1: $orange1;\n    orange2: $orange2;\n    orange3: $orange3;\n    orange4: $orange4;\n    orange5: $orange5;\n    grey1: $grey1;\n    grey2: $grey2;\n    grey3: $grey3;\n    grey4: $grey4;\n    grey5: $grey5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"light": `#FFFFFF`,
	"dark": `#121217`,
	"blue1": `#00007A`,
	"blue2": `#0000C9`,
	"blue3": `#4343FF`,
	"blue4": `#8686FF`,
	"blue5": `#D8D8FF`,
	"orange1": `#DA8200`,
	"orange2": `#FFA92C`,
	"orange3": `#FFC26B`,
	"orange4": `#FFE1B7`,
	"orange5": `#FFF3E3`,
	"grey1": `#3E3E50`,
	"grey2": `#838390`,
	"grey3": `#C3C3C9`,
	"grey4": `#E0E0E3`,
	"grey5": `#F9F9FA`,
	"mainNb": `StateData_mainNb__A7o0E`,
	"progress": `StateData_progress__tdAjm`,
	"better": `StateData_better__PzB+y`,
	"worst": `StateData_worst__SPoUQ`
};
export default ___CSS_LOADER_EXPORT___;
