import api from 'helpers/api'
import i18n from 'i18n'

const { t } = i18n

export const getFileUrl = async ({ contractId, claimId, documentId, type }) => {
  const url = claimId
    ? `/claims/${claimId}/documents/${documentId}?adhesionId=${contractId}`
    : `/adhesions/${contractId}/document?type=${type}`

  const res = await api.get({
    isFile: true,
    url,
    responseType: 'arraybuffer',
  })

  const file = new Blob([res.file], { type: res.contentType })
  const fileUrl = URL.createObjectURL(file)

  return fileUrl
}

export const downloadDocument = async ({ contract, claim, documentItem, type }) => {
  const fileUrl = await getFileUrl({
    contractId: contract?._id,
    claimId: claim?._id,
    documentId: documentItem?._id,
    type,
  })
  let a = document.createElement('a')
  a.href = fileUrl
  a.download = type
    ? `${contract.contractType.dashboardName} - ${t(`contract:document.${type}`)}.pdf`
    : documentItem.name
  a.click()
}

export const downloadDocumentAPI = ({ documentId }) => {
  // Utiliser l'URL de l'API pour télécharger le fichier
  const fileUrl = `${process.env.REACT_APP_API_URL}/download/documents/${documentId}`; // Assurez-vous que votre serveur API accepte cette URL
  console.log(fileUrl)
  let a = document.createElement('a');
  a.href = fileUrl;
  a.download = documentId; // Nom du fichier ou ID du document
  a.click();
};

