const Loader = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 50 50">
      <circle fill="#FFFFFF" style={{opacity:1}} cx="25" cy="5" r="5"/>
      <circle fill="#FFFFFF" style={{opacity:0.4}} cx="25" cy="45" r="5"/>
      <circle fill="#FFFFFF" style={{opacity:0.85}} cx="10.9" cy="10.9" r="5"/>
      <circle fill="#FFFFFF" style={{opacity:0.25}} cx="39.1" cy="39.1" r="5"/>
      <circle fill="#FFFFFF" style={{opacity:0.7}} cx="5" cy="25" r="5"/>
      <circle fill="#FFFFFF" style={{opacity:0.1}} cx="45" cy="25" r="5"/>
      <circle fill="#FFFFFF" style={{opacity:0.55}} cx="10.9" cy="39.1" r="5"/>
      <circle fill="#FFFFFF" style={{opacity:0}} cx="39.1" cy="10.9" r="5"/>
    </svg>
  )
}

export default Loader
