// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__OKWO7 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 68px;
  padding: 22px;
}

.Header_right-corner__eV1Yo {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 0 0 auto;
}

.Header_icon-button__Tnr-d {
  height: 20px;
  cursor: pointer;
}
.Header_icon-button__Tnr-d path {
  fill: #0000c9;
}
.Header_icon-button__Tnr-d.Header_dark-bg__vczwZ path {
  fill: #fff;
}
.Header_icon-button__Tnr-d:hover path {
  fill: #000093;
}
.Header_icon-button__Tnr-d:active path {
  fill: #000054;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;AAFF;;AAKA;EACE,YAAA;EACA,eAAA;AAFF;AAIE;EACE,aCnBO;ADiBX;AAMI;EACE,UAAA;AAJN;AASI;EACE,aC7BK;ADsBX;AAYI;EACE,aCjCK;ADuBX","sourcesContent":["@use 'styles/sizes.scss';\n@use 'styles/colors.scss';\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  height: sizes.$header-height;\n  padding: 22px;\n}\n\n.right-corner {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  margin: 0 0 0 auto;\n}\n\n.icon-button {\n  height: 20px;\n  cursor: pointer;\n\n  path {\n    fill: colors.$primary6;\n  }\n\n  &.dark-bg {\n    path {\n      fill: #fff;\n    }\n  }\n\n  &:hover {\n    path {\n      fill: colors.$primary7;\n    }\n  }\n\n  &:active {\n    path {\n      fill: colors.$primary9;\n    }\n  }\n}\n","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__OKWO7`,
	"right-corner": `Header_right-corner__eV1Yo`,
	"icon-button": `Header_icon-button__Tnr-d`,
	"dark-bg": `Header_dark-bg__vczwZ`
};
export default ___CSS_LOADER_EXPORT___;
