// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lexend:wght@100;300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  font-family: "Lexend", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

body {
  height: 100%;
  margin: 0;
  font-family: "Lexend", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.clickable {
  cursor: pointer;
}

.capitalized {
  text-transform: capitalize;
}

.input-label {
  display: block;
  margin-bottom: 4px;
  padding: 0px 8px;
  color: #00007a;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.input-label.dark-bg {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/index.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACE,sBAAA;EACA,oIAAA;AADF;;AAKA;EACE,YAAA;EACA,SAAA;EACA,oIAAA;AAFF;;AAMA;;;;;;;EAOE,SAAA;AAHF;;AAMA;EACE,eAAA;AAHF;;AAMA;EACE,0BAAA;AAHF;;AAMA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,cC/BS;EDgCT,eAAA;EACA,iBAAA;EACA,gBAAA;AAHF;AAKE;EACE,WAAA;AAHJ","sourcesContent":["@use 'styles/colors.scss';\n@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;300;400;500;600;700&display=swap');\n\n* {\n  box-sizing: border-box;\n  font-family: 'Lexend', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif !important;\n}\n\nbody {\n  height: 100%;\n  margin: 0;\n  font-family: 'Lexend', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif !important;\n}\n\np,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  margin: 0;\n}\n\n.clickable {\n  cursor: pointer;\n}\n\n.capitalized {\n  text-transform: capitalize;\n}\n\n.input-label {\n  display: block;\n  margin-bottom: 4px;\n  padding: 0px 8px;\n  color: colors.$primary8;\n  font-size: 14px;\n  line-height: 24px;\n  font-weight: 400;\n\n  &.dark-bg {\n    color: #fff;\n  }\n}\n","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
