export const pages = [
    null,
    'profil',
    'address',
    'contacts',
    'partner',
    'more',
    'summary',
    'payment',
    'signature-choice',
    'signature',
    'success'
]

export const currentStep = (pathname) => {
    const currentPage = pathname.split("/").slice(-1)[0].split("?")[0]
    const index = pages.findIndex( page => page === currentPage)
    return index > 0 ? index : 0
}

export const next = ({pathname, navigate, state, data, skip}) => {
    const { client, contract, ...rest } = data
    const nextStep = currentStep(pathname) + 1 + (skip ?? 0)
    navigate('../'+pages[nextStep], {
        state: {
            ...state,
            client: { ...state.client, ...client },
            contract: { ...state.contract, ...contract },
            ...rest
        }
    })
}