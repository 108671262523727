import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import style from './SubscriptionMain.module.scss'
import Collapsible from 'components/common/Collapsible/Collapsible'
import Button from 'components/common/Button/Button'
import Link from 'components/common/Link/Link'
import LogoShort from 'components/assets/icons/LogoShort'
import Star from 'components/assets/icons/Star'
import Title from 'components/common/PageTitle/PageTitle'
import api from 'helpers/api'
import { useTranslation } from 'react-i18next'
import { getYearPrice } from 'helpers/contract'

const SubscriptionMain = () => {
  const { state } = useLocation()
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  

  const [contractType, setContractType] = useState()
  const token = state?.token || searchParams.get('token')
  const idCampaign = state?.idCampaign || searchParams.get('idCampaign')

  useEffect(() => {
    const getContract = async () => {
      try {
        const res = await api.get({ url: `/owen-products/contract_type/${params.id}/client` })
        setContractType(res)
      } catch (error) {
        console.log('Fetch contracts error')
      }
    }
    getContract()
  }, [params.id, setContractType])

  const onSubmit = () => {
    navigate('main', {
      state: {
        client: state?.client,
        contract: state?.contract || {
          contractType,
          contractPeriodType: searchParams.get('contractPeriodType'),
          partner: searchParams.get('partner'),
          priceType: searchParams.get('priceType'),
          rateType: searchParams.get('rateType'),
          renewableContract: searchParams.get('renewableContract'),
          contractPeriod: searchParams.get('contractPeriod')
        },
        step: 1,
        token,
        idCampaign
      },
    })
  }

  if (!contractType) return null
  const contractPriceType = state?.contract?.priceType || searchParams.get('priceType');
  const priceType = contractType.priceType.find(elem => elem._id === contractPriceType);
  let rate = null;
  let rateType = null;
  if (priceType) {
    const contractRateType = state?.contract?.rateType || searchParams.get('rateType');
     rateType = priceType.rateType.find(elem => elem._id === contractRateType);
     if (rateType) rate = rateType.rate
  }  
  const subscriptionPortalDescriptions = contractType.subscriptionPortalDescriptions[0]
  const replaceDataIndemnities = (itemName) => {
    return itemName
      .replace(/{{package}}/g, rateType.dataInsurance.package ?? '')
      .replace(/{{refund_limit}}/g, rateType.refundLimit ?? '');
  };

  return (
    <>
      <Title className={style.title} text={<>{t('subscription:start.title')} 👨‍👩‍👦‍👦</>} />

      <div className={style.cards}>
        <div className={style.price}>
          <span className={style['price-description']}>{t('subscription:start.priceDescription')}</span>
          <div className={style['price-month']}>
            <Star className={style['star-left']} />
            <span>{rate.toFixed(2).toString().replace('.', ',')}€</span>/{t('common:period.month')}
            <Star className={style['star-right']} />
            <Star className={style['star-small']} />
          </div>
          <span className={style['price-year']}>
            {t('subscription:start.yearPrice', { price: getYearPrice(rate.toFixed(2)).toString().replace('.', ',') })}
          </span>
        </div>
        <div className={style.product}>
          <h4 className={style['product-title']}>
            <LogoShort className={style.logo} /> {t('common:yourProtection')}
          </h4>
          <p className={style['product-description']}>{subscriptionPortalDescriptions.name}</p>
        </div>

        <Collapsible
          className={style['collapsible-card']}
          content={
            <div className={style.list}>
              {subscriptionPortalDescriptions.coveredEvents.map((item, index) => (
                <p className={style.item} key={`covered-event-${index}`}>
                  {item.name}
                </p>
              ))}
            </div>
          }
          title={<span className={style['list-title']}>⚠️ {t('subscription:start.eventsCovered')}</span>}
        />
        <Collapsible
          className={style['collapsible-card']}
          content={
            <div className={style.list}>
              {subscriptionPortalDescriptions.indemnities.map((item, index) => (
                <p className={style.item} key={`indemnity-${index}`}>
                  {replaceDataIndemnities(item.name)}
                </p>
              ))}
            </div>
          }
          title={<span className={style['list-title']}>💸 {t('subscription:start.indemnity')}</span>}
        />
      </div>

      <Button
        className={style.button}
        label={t('subscription:start.button', { price: rate.toFixed(2).toString().replace('.', ',') })}
        onClick={onSubmit}
        type="primary"
      />
      <p className={style.link}>
        {`${t('common:consultThe')} `}
        <Link label={t('contract:document.ipid').toLowerCase()} target="_blank" to={contractType.legalDocuments.ni} />
        {` ${t('common:misc.andThe')} `}
        <Link
          label={t('contract:document.niShort').toLowerCase()}
          target="_blank"
          to={contractType.legalDocuments.ipid}
        />
        {` ${t('common:misc.onThe')} ${t('common:insuranceProduct')}`}
      </p>
    </>
  )
}

export default SubscriptionMain
