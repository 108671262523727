import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { isMobile } from 'helpers/styles'
import QuestionCircle from 'components/assets/icons/QuestionCircle'
import Link from 'components/common/Link/Link'
import Logo from 'components/common/Logo/Logo'
import style from './Header.module.scss'
import { isAuthRoute } from 'helpers/app'
import LanguageSelector from 'components/LanguageSelector/LanguageSelector'

const Header = ({ className, darkBg, lightBg, styles }) => {
  const { pathname } = useLocation()

  return (
    <header className={classNames(style.header, className)} style={styles}>
      <Logo
        styles={{
          fill: darkBg ? '#fff' : lightBg ? '#000093' : isMobile() ? '#000093' : '#fff',
        }}
      />
      <div className={style['right-corner']}>
        {!isAuthRoute(pathname) && (
          <>
            <Link
              className={classNames(style['icon-button'], darkBg && style['dark-bg'])}
              label={<QuestionCircle styles={{ fill: darkBg && '#fff' }} />}
              text='blog'
              to="https://www.get-owen.com/blog"
              target="_blank"
              rel="noopener noreferrer"
            />
          </>
        )}
        <LanguageSelector darkBg={isAuthRoute(pathname)} />
      </div>
    </header>
  )
}

export default Header
