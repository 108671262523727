import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams, Navigate } from 'react-router-dom'
import classNames from 'classnames'
import IconWrapper from 'components/common/IconWrapper/IconWrapper'
import MainLogo from 'components/assets/icons/MainLogo'
import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'
import Input from 'components/common/Input/Input'
import InputError from 'components/common/InputError/InputError'
import Button from 'components/common/Button/Button'
import EyeOpen from 'components/assets/icons/EyeOpen'
import EyeClosed from 'components/assets/icons/EyeClosed'
import Loader from 'components/assets/icons/Loader'
import { emailValidation, passwordValidation, confirmPasswordValidation } from 'constants/inputValidation'
import api from 'helpers/api'
import { parseToken } from 'helpers/auth'
import style from '../Auth.module.scss'
import commonStyle from '../../../Wrapper/wrapper.module.scss'

const SignupReporting = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const [isConfirmShown, setIsConfirmShown] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)

  const token = searchParams.get('token')
  const data = token ? parseToken(token) : null

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    getValues
  } = useForm({
    criteriaMode: 'all',
    mode: 'onTouched',
    delayError: 0,
    values: {
      email: data?.email,
      password: '',
      confirm: ''
    },
  })

  const onSubmit = handleSubmit(async data => {

    if (data.password !== data.confirm) {
      setErrorMessage(t('reporting:auth.password.differents'))
      return
    }

    setLoading(true)

    const register = async () => {
      try {
        const res = await api.post({ url: '/auth/reporting/signup', data })

        if (res.success && res.response.confirmToken) {
          navigate('/reporting/signup-confirm?token='+res.response.confirmToken)
        } else {
          setErrorMessage(t('main:errorMessages.general'))
        }
      } catch (error) {
        const errorMsg = error.toString().split(':')[1]?.trim()
        const errorsList = {
          "Email is already registered": t('main:auth.signUp.knownEmail'),
          "Partner does not exist": t('main:auth.signUp.unknownEmail'),
          "Password should contain at least 9 characters, including 1 digit and 1 capital letter": t('main:auth.signUp.wrongPassword'),
          "Too many requests": t('main:auth.confirm.tooManyRequests'),
        }
  
        setErrorMessage(errorsList[errorMsg] ?? t('main:errorMessages.general'))

        if (errorsList[errorMsg] === t('main:auth.signUp.knownEmail')){
          setTimeout( () => {
            navigate('/reporting/signin')
          }, 5000)
        }
      }
      setLoading(false)
    }

    setTimeout( () => register(), 500)
  })


  return !token
  ? <Navigate to="/reporting/signin" replace />
  : (
    <section className={style.content}>
      <IconWrapper icon={MainLogo} className={style.logo} styles={{ fill: '#000093' }} text='Logo' />
      <h1 className={style.title}>{t('reporting:signup.title')}</h1>

      {errorMessage &&
        <ResponseMessage
          className={style.message}
          label={errorMessage}
        />
      }

      <form onSubmit={onSubmit}>
        <Input
          register={register('email', emailValidation(t))}
          label={t('reporting:auth.email.label')+'*'}
          autocomplete="email"
          error={errors.firstname}
          className={classNames(commonStyle.input, commonStyle.disabled)}
          disabled={true}
        />

        <Input
          register={register('password', passwordValidation(t))}
          label={t('reporting:auth.password.label')}
          type={isPasswordShown ? 'text' : 'password'}
          className={commonStyle.input}
          error={errors.password}
          suffix={
            isPasswordShown ? (
              <EyeClosed className={classNames(style.eyeClosed, 'clickable')} />
            ) : (
              <EyeOpen className={classNames(style.eyeOpen, 'clickable')} />
            )
          }
          onSuffixClick={() => setIsPasswordShown(!isPasswordShown)}
        />
        {errors.password &&
          <div className={style.error}>
            <p>{t('main:auth.password.required')}</p>
            {Object.values(errors.password.types).map( (error, id) => (
              <span key={'err'+id}>
                {error}
              </span>
            ))}
          </div>
        }

        <Input
          register={register('confirm', confirmPasswordValidation(t, getValues('password')))}
          label={t('reporting:signup.confirm.label')}
          type={isConfirmShown ? 'text' : 'password'}
          className={commonStyle.input}
          error={errors.confirm}
          suffix={
            isConfirmShown ? (
              <EyeClosed className={classNames(style.eyeClosed, 'clickable')} />
            ) : (
              <EyeOpen className={classNames(style.eyeOpen, 'clickable')} />
            )
          }
          onSuffixClick={() => setIsConfirmShown(!isConfirmShown)}
        />
        {errors.confirm && <InputError error={errors.confirm} />}

        <Button
          type={loading && 'loading'}
          className={style.button}
          label={loading ? <Loader/> : t('reporting:signup.button')}
          onClick={handleSubmit}
          disabled={!isValid || loading} 
        />
      </form>
    </section>
  )
}

export default SignupReporting
