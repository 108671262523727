// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Line_container__2eISV {
  position: relative;
  height: 250px;
}`, "",{"version":3,"sources":["webpack://./src/components/reporting/components/graphs/Line/Line.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;AACF","sourcesContent":[".container {\n  position: relative;\n  height: 250px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Line_container__2eISV`
};
export default ___CSS_LOADER_EXPORT___;
