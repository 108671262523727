// Modules
import { useLocation } from 'react-router-dom'

// Components
import IbanForm from './IbanForm'
import CardForm from './CardForm'

const Payment = () => {
  const { state } = useLocation()

  return state?.isIbanPayment
    ? <IbanForm state={state} />
    : <CardForm state={state} />
}

export default Payment
