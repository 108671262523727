import { useTranslation } from 'react-i18next'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { commonOptions, hexToRgbA, months } from 'helpers/chartjs';
import CustomLegend, { htmlLegendPlugin } from '../CustomLegend/CustomLegend'
import NoData from '../NoData/NoData';
import colors from 'components/reporting/styles/colors.scss'
import style from './Line.module.scss'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler)

const LineChart = ({backgroundColor, datas, legendID, gradient, monthsAxisX}) => {

    const { t } = useTranslation()
    const {labels, datasets} = datas
    const noData = (!datasets?.find( dataset => dataset.data.find( val => val !== 0) ))

    // Datas
    const data = {
        labels: monthsAxisX
            ? labels?.map( label => months(t)[label] )
            : labels,
        datasets: datasets?.map( (data, index) => ({
            ...data,
            yAxisID: data.yAxisID || 'y',
            borderColor: backgroundColor[index],
            borderWidth: 2,
            backgroundColor: backgroundColor[index],
            pointRadius: 0,
            fill: gradient && backgroundColor[index] === gradient ? {
                target: 'origin',
                above: function(context) {
                    const chart = context.chart;
                    const {ctx, chartArea} = chart;
                    if (!chartArea) return

                    const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
                    gradient.addColorStop(0, hexToRgbA({hex: colors.light, opacity: 0}))
                    gradient.addColorStop(1, hexToRgbA({hex: colors.blue5, opacity: 0.6}))
                    return gradient
                },
              } : false
        })) ?? []
    }

    const verticalLineHover = {
        id: 'verticalLineHover',
        beforeDatasetsDraw(chart, args, plugins) {
            const { ctx, chartArea: {bottom} } = chart
            ctx.save()

            chart.getDatasetMeta(0).data.forEach( (dataPoint, index) => {
                if (dataPoint.active){
                    ctx.beginPath()
                    ctx.strokeStyle = colors.blue4
                    ctx.moveTo(dataPoint.x, dataPoint.y)
                    ctx.lineTo(dataPoint.x, bottom)
                    ctx.stroke()
                }
            })
        }
    }

    // Options
    const options = {
        ...commonOptions(legendID),
        interaction:{
            mode: 'index',
            intersect: false
        }
    }

    if (datasets?.find( data => data.yAxisID === 'y1')){
        options.scales.y1.display = true
        options.scales.y.title.display = true
        options.scales.y.title.text = datasets.find( data => data.yAxisID === 'y' || !data.yAxisID).label
        options.scales.y1.title.display = true
        options.scales.y1.title.text = datasets.find( data => data.yAxisID === 'y1').label
    }

    if (noData){
        options.scales.x.ticks.color = colors.grey3
        options.scales.y.ticks.color = colors.grey3
        options.scales.y.title.color = colors.grey3
        options.scales.y1.ticks.color = colors.grey3
        options.scales.y1.title.color = colors.grey3
    }

    // Legend
    const legend = htmlLegendPlugin({
        inline: true,
        backgroundColor
    })

    return (
        <>
            <CustomLegend id={legendID} />
            <div className={style.container}>
                {noData && <NoData />}
                <Line
                    data={data}
                    options={options}
                    plugins={[legend, verticalLineHover]}
                />
            </div>
        </>
    )
}

export default LineChart