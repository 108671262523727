// Modules
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'

import Input from 'components/common/Input/Input'
import Textarea from 'components/common/Textarea/Textarea'
import Button from 'components/common/Button/Button'

import api from 'helpers/api'
import style from './Contact.module.scss'
import commonStyle from '../../Wrapper/wrapper.module.scss'

const ContactReporting = () => {
  const { t } = useTranslation()

  const {
    register,
    formState: { errors, isValid },
    handleSubmit
  } = useForm({
    criteriaMode: 'all',
    mode: 'onTouched',
    delayError: 0,
    values: {
      object: '',
      message: ''
    },
  })

  const onSubmit = handleSubmit(async data => {
    try {
    } catch (error) {}
  })

  return (
    <>
      <div className={style.main}>
        <div className={style.content}>
          <h1>{t('reporting:contact.title')}</h1>
          <p className={style.head}>{t('reporting:contact.head')}</p>
          <form className={style.form} onSubmit={onSubmit}>
            <Input
              register={register('object', {required: true})}
              label={t('reporting:contact.object')}
              error={errors.object}
              className={commonStyle.input}
            />

            <Textarea
              register={register('message', { required: true })}
              label={t('reporting:contact.message')}
              error={errors.message}
              className={classNames(commonStyle.input, style.textarea)}
            />

            <Button
              className={style.button}
              disabled={!isValid}
              label={t('reporting:contact.button')}
              onClick={handleSubmit}
            />
          </form>
        </div>
        <aside className={style.cover}></aside>
      </div>
    </>
  )
};

export default ContactReporting
