// Modules
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import api from 'helpers/api'
import { isValidIBAN } from 'ibantools'

// Components
import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'
import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import InputError from 'components/common/InputError/InputError'
import Title from 'components/common/PageTitle/PageTitle'
import Spinner from 'components/common/Spinner/Spinner'
import { next } from 'components/Freemium/Nav/Nav'
import mainStyle from '../../FreemiumComponents.module.scss'

const IbanForm = ({ state }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [errorMessage, setErrorMessage] = useState(false)
  const [isSpinnerLoading, setIsSpinnerLoading] = useState(false)
  const { t } = useTranslation()
  const contract = {
    ...state.contract,
    contractType: state.contract.contractType.slice(-1)[0]
  }
  const { price, paymentPlan } = contract


  // Initialize form
  const required = t('common:form.incorrect')
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    mode: 'onTouched',
    values: {
      owner: '',
      iban: ''
    },
  })


  // Submit form
  const onSubmit = handleSubmit(async data => {
    try {
      setIsSpinnerLoading(true);
      const res = await api.post({
        url: '/adhesions/client',
        data: {
          ...state.listingData,
          ...contract,
          ...state.client,
          idCampaign: state.idCampaign,
          phone: state.client.phone.number,
          countryCode: state.client.phone.countryCode,
          birthdate: state.client.birthdate,
          transactionHorodate: new Date(),
          paymentType: "direct debit by Owen",
          paymentPlan,
          contractType: contract.contractType._id,
          iban: data.iban.replace(/\s/g,''),
          frequency: paymentPlan
        },
        headers: { Authorization: `Bearer ${state.token}` },
      })

      // Navigate to the next page
      if (res?.response && res?.response.compliance) {
        next({
          pathname, navigate, state,
          data: { saveID: res.response.id }
        })
      } else {
        setErrorMessage(true)
      }
    } catch (error) {
      setErrorMessage(true)
    } finally {
      setIsSpinnerLoading(false);
    }
  })


  return price ? (
    <>
      {isSpinnerLoading && <Spinner />}
      {errorMessage && <ResponseMessage label={t('common:form.genericError')} />}

      <Title className={mainStyle['page-title']} text={<>{t('freemium:payment.ibanTitle')} 💸</>} />
      <p className={mainStyle.subtitle}>{t('freemium:payment.subtitle')}</p>

      <form className={mainStyle.form} onSubmit={onSubmit}>

        <Input
          error={errors.owner}
          register={register('owner', {
            required,
            validate: value => /[a-zA-ZÀ-ÿ-]{2,}/.test(value) || t('common:form.owner.incorrect')
          })}
          label={t('common:paymentMethod.iban.owner.label')+'*'}
          placeholder={t('common:paymentMethod.iban.owner.placeholder')}
          disallowNumbers={true}
        />
        <InputError error={errors.owner} />


        <Input
          error={errors.iban}
          register={register('iban', {
            required,
            validate: value => isValidIBAN(value.replace(/\s/g,'')) || t('common:form.iban.incorrect')
          })}
          label={'IBAN*'}
          placeholder={'IBAN'}
        />
        <InputError error={errors.iban} />


        <p className={mainStyle.required}>{t('common:form.mandatoryFields')}</p>


        <Button
          className={mainStyle.button}
          label={t('freemium:payment.button', { price })}
          onClick={handleSubmit}
          type="primary"
          disabled={!isValid}
        />

      </form>
    </>
  ) : <p style={{ color: 'red' }}>Une erreur est survenue. </p>
}
export default IbanForm
