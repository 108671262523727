import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import IconWrapper from 'components/common/IconWrapper/IconWrapper'
import MainLogo from 'components/assets/icons/MainLogo'
import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'
import Input from 'components/common/Input/Input'
import Link from 'components/common/Link/Link'
import Button from 'components/common/Button/Button'
import EyeOpen from 'components/assets/icons/EyeOpen'
import EyeClosed from 'components/assets/icons/EyeClosed'
import { emailValidation, passwordValidation } from 'constants/inputValidation'
import api from 'helpers/api'
import style from '../Auth.module.scss'
import commonStyle from '../../../Wrapper/wrapper.module.scss'

const AuthReporting = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const {
    register,
    formState: { errors, isValid },
    handleSubmit
  } = useForm({
    criteriaMode: 'all',
    mode: 'onTouched',
    delayError: 0,
    values: {
      email: '',
      password: ''
    },
  })

  const onSubmit = handleSubmit(async data => {
    try {
      const res = await api.post({ url: '/auth/reporting/signin', data })

      if (res?.success && res.response.reportToken) {
        localStorage.setItem('reportToken', res.response.reportToken)
        navigate('/reporting/overview')
      } else {
        setErrorMessage(t('main:errorMessages.general'))
      }
    } catch (error) {
      const errorMsg = error.toString().split(':')[1]?.trim()
      const errorsList = {
        "Wrong password":       t('main:auth.signIn.wrongPwd'),
        "Sign up unconfirmed":  t('main:auth.signIn.signUpUnconfirmed'),
        "User not registered":  t('main:auth.signIn.unknownUser'),
        "Unauthorized":         t('main:auth.signIn.unauthorized'),
        "Too many requests":    t('main:auth.signIn.tooManyRequests')
      }
      const msg = errorsList[errorMsg] ?? t('main:errorMessages.general')
      setErrorMessage(msg)
    }
  })

  return (
    <section className={style.content}>
      <IconWrapper icon={MainLogo} className={style.logo} styles={{ fill: '#000093' }} text='Logo' />
      <h1 className={style.title}>{t('reporting:auth.title')}</h1>

      {errorMessage &&
        <ResponseMessage
          className={style.message}
          label={errorMessage}
        />
      }

      <form onSubmit={onSubmit}>
        <Input
          register={register('email', emailValidation(t))}
          label={t('reporting:auth.email.label')+'*'}
          autocomplete="email"
          error={errors.email}
          className={commonStyle.input}
        />

        <Input
          register={register('password', passwordValidation(t))}
          label={t('main:auth.password.password')}
          placeholder={t('main:auth.password.placeholder')}
          type={isPasswordShown ? 'text' : 'password'}
          error={errors.password}
          className={commonStyle.input}
          suffix={
            isPasswordShown ? (
              <EyeClosed className={classNames(style.eyeClosed, 'clickable')} />
            ) : (
              <EyeOpen className={classNames(style.eyeOpen, 'clickable')} />
            )
          }
          onSuffixClick={() => setIsPasswordShown(!isPasswordShown)}
        />
        {errors.password &&
          <div className={style.error}>
            <p>{t('main:auth.password.required')}</p>
            {Object.values(errors.password.types).map( (error, id) => (
              <div key={'err'+id}>
                {error}
              </div>
            ))}
          </div>
        }

        <Link
          label={t('reporting:auth.password.forgotten')}
          className={style.link}
          to={'/reporting/password-lost'}
        />

        <Button
          className={style.button}
          disabled={!isValid}
          label={t('reporting:auth.button')}
          onClick={handleSubmit}
        />
      </form>
    </section>
  )
};

export default AuthReporting
