// Modules
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import api from 'helpers/api'

// Components
import Collapsible from 'components/common/Collapsible/Collapsible'
import Button from 'components/common/Button/Button'
import LogoShort from 'components/assets/icons/LogoShort'
import Title from 'components/common/PageTitle/PageTitle'
import { next } from 'components/Freemium/Nav/Nav'
import { dateToAge } from 'functions'

// Styles
import classNames from 'classnames'
import style from './More.module.scss'
import mainStyle from '../FreemiumComponents.module.scss'

const More = () => {
  const { state, pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [paidOption, setPaidOption] = useState(false)
  const [contractType, setContractType] = useState(state.contract.contractType ?? [])
  const [price, setPrice] = useState(0)
  const contractPay = state.contract?.contractPay


  // Get datas
  const queryContract = useQuery({
    queryKey: ["query-contract", contractPay],
    queryFn: async () => {
      try{
        return await api.get({ url: `/owen-products/contract_type/${contractPay}/client` })
      } catch (error) {
        throw new Error('Failed to fetch contract')
      }
    }
  })


  const fetchPrice = async (name, age) => {
    try{
      return age >= 18
        ? Number((await api.get({ url: `/freemium/${name}/${age}` })).result)
        : 0
    } catch (error) {
      throw new Error('Failed to fetch price')
    }
  }

  const userAge = dateToAge(state.client.birthdate)
  const queryUserPrice = useQuery({
    queryKey: ['price', userAge],
    queryFn: async () => fetchPrice('age', userAge)
  })

  const partnerAge = state.client.birthdatePartner ? dateToAge(state.client.birthdatePartner) : 0
  const queryPartnerPrice = useQuery({
    queryKey: ['price', partnerAge],
    queryFn: async () => fetchPrice('agePartner', partnerAge)
  })


  useEffect(() => {
    if (!queryContract.isPending && !queryUserPrice.isPending && !queryPartnerPrice.isPending){
      if (contractType.slice(-1)[0]._id !== queryContract.data._id){
        setContractType([...contractType, queryContract.data])
      }
      setPrice(parseFloat(queryUserPrice.data + queryPartnerPrice.data).toFixed(2).replace('.', ','))
    }
  }, [queryContract, queryUserPrice, queryPartnerPrice, contractType])


  // Navigate to the next page
  const submit = () => {
    next({
      pathname, navigate, state,
      data: {
        client: { paidOption },
        contract: {
          contractType: paidOption ? contractType : [contractType[0]],
          price
        }
      }
    })
  }


  if (price === 0) return null
  const {
    titleActivationGuarantees,
    descriptionActivationGuarantees,
    guarantees,
    titleGuarantees,
    subTitlePromotion,
    labelButtonActivation,
    coveredEvents,
    indemnities,
    name
  } = contractType.slice(-1)[0].subscriptionPortalDescriptions[0]


  return (
    <>
      <Title className={mainStyle['page-title']} text={<>{t('freemium:more.title')} 🛡️</>} />

      {contractType.length === 2 && <div className={style.confirm}>
        <span>
          🎉 {titleActivationGuarantees}
        </span>
        <p dangerouslySetInnerHTML={{__html:descriptionActivationGuarantees?.replace(/{{(.+)}}/g, state.client.partnerOption ? "$1" : "")+' ⬇️'}}></p>
      </div>}


      <div className={style.cards}>

        <div className={style.main}>
          <span>
            {subTitlePromotion}
          </span>

          <h3 className={classNames(mainStyle.shining_title, mainStyle.max)}>
            {titleGuarantees.replace(/ - .+/,"")}
          </h3>

          <ul>
            {guarantees.map((item, index) => (
              <li key={`covered-event-${index}`}>
                {item.name}
              </li>
            ))}
          </ul>

          <div>
            {labelButtonActivation}
            <span className={style.price}>{price}€/mois</span>

            <label className={style.toggle}>
              <input
                type='checkbox'
                checked={paidOption}
                onChange={() => setPaidOption(!paidOption)}
                hidden
              />
              <span></span>
            </label>
          </div>
        </div>

        <div className={style.product}>
          <h4 className={style['product-title']}>
            <LogoShort className={style.logo} /> {t('common:yourProtection')}
          </h4>
          <p className={style['product-description']}>
            {name}
          </p>
        </div>

        <Collapsible
          className={style['collapsible-card']}
          title={<span className={style['list-title']}>⚠️ {t('freemium:start.eventsCovered')}</span>}
          isOpenedProp={true}
          content={
            <ul className={style.list}>
              {coveredEvents.map((item, index) => (
                <li className={style.item} key={`covered-event2-${index}`}>
                  {item.name}
                </li>
              ))}
            </ul>
          }
        />

        <Collapsible
          className={style['collapsible-card']}
          title={<span className={style['list-title']}>💸 {t('freemium:start.indemnity')}</span>}
          isOpenedProp={true}
          content={
            <ul className={style.list}>
              {indemnities.map((item, index) => (
                <li className={style.item} key={`indemnity-${index}`}>
                  {item.name}
                </li>
              ))}
            </ul>
          }
        />

        <Button
          className={classNames(mainStyle.button, style.button)}
          label={paidOption
            ? t('freemium:more.confirmProtection')
            : t('freemium:more.rejectProtection')
          }
          onClick={submit}
          type="primary"
        />
      </div>
    </>
  )
}

export default More