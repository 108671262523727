// Modules
import { Controller, useForm} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import MaskedInput from 'react-text-mask'
import dayjs from 'dayjs'

// Components
import Button from 'components/common/Button/Button'
import Radio from 'components/common/Radio/Radio'
import Input from 'components/common/Input/Input'
import InputError from 'components/common/InputError/InputError'
import Title from 'components/common/PageTitle/PageTitle'
import { birthdayValidationFx, maskedDate, formParams, getDefaultValue } from 'constants/inputValidation'
import { next } from 'components/Freemium/Nav/Nav'
import { Capitalize } from 'functions'

// Style
import mainStyle from '../FreemiumComponents.module.scss'


const Partner = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state, pathname } = useLocation()
  const [ isSamePerson, setIsSamePerson ] = useState(false)


  // Initialize form
  const required = t('common:form.incorrect')
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    getValues
  } = useForm({
    ...formParams,
    values: {
      firstnamePartner: getDefaultValue('firstnamePartner', state),
      lastnamePartner: getDefaultValue('lastnamePartner', state),
      birthdatePartner: maskedDate(state.client.birthdatePartner, dayjs),
      placeOfBirthPartner: getDefaultValue('placeOfBirthPartner', state),
      countryOfBirthPartner: getDefaultValue('countryOfBirthPartner', state),
      civilityPartner: getDefaultValue('civilityPartner', state),
    },
  })


  const areProfilsSames = () => {
    const {
      civilityPartner,
      firstnamePartner,
      lastnamePartner,
      birthdatePartner,
      placeOfBirthPartner,
      countryOfBirthPartner
    } = getValues()

    const isSameCivility = civilityPartner === state?.client?.civility
    const isSameFirstName = firstnamePartner.toLowerCase() === state?.client?.firstname.toLowerCase()
    const isSameLastName = lastnamePartner.toLowerCase() === state?.client?.lastname.toLowerCase()
    const isSameBirthdate = birthdatePartner === state?.client?.birthdate
    const isSamePlace = placeOfBirthPartner.toLowerCase() === state?.client?.placeOfBirth.toLowerCase()
    const isSameCountry = countryOfBirthPartner.toLowerCase() === state?.client?.countryOfBirth.toLowerCase()

    return isSameCivility && isSameFirstName && isSameLastName && isSameBirthdate && isSamePlace && isSameCountry
  }



  // Submit form => Navigate to the next page
  const onSubmit = handleSubmit(async data => {

    if (areProfilsSames()){
      setIsSamePerson(true)
      return
    }

    // Text format
    data.firstnamePartner = Capitalize(data.firstnamePartner)
    data.lastnamePartner = Capitalize(data.lastnamePartner)
    data.placeOfBirthPartner = Capitalize(data.placeOfBirthPartner)
    data.countryOfBirthPartner = Capitalize(data.countryOfBirthPartner)

    // Next
    next({
      pathname, navigate, state,
      data: { client: data }
    })
  })


  return (
    <>
      <Title className={mainStyle['page-title']} text={<>{t('freemium:partner.title')} 💕</>} />

      <form className={mainStyle.form} onSubmit={onSubmit}>

        {isSamePerson && <InputError error={{message: "Same person"}} />}

        <Radio
          register={register('civilityPartner', { required })}
          label={'Civilité *'}
          values={["Homme","Femme"]}
        />


        <Input
          error={errors.firstnamePartner}
          register={register('firstnamePartner', { required })}
          label={t('common:form.firstname.label')+'*'}
          placeholder={`${t('common:your')} ${t('common:form.firstname.label').toLowerCase()}`}
          disallowNumbers={true}
          disallowSpecials={true}
          autocomplete="given-name"
        />
        <InputError error={errors.firstnamePartner} />


        <Input
          error={errors.lastnamePartner}
          register={register('lastnamePartner', { required })}
          label={t('common:form.lastname.label')+'*'}
          placeholder={`${t('common:your')} ${t('common:form.lastname.label').toLowerCase()}`}
          disallowNumbers={true}
          disallowSpecials={true}
          autocomplete="family-name"
        />
        <InputError error={errors.lastnamePartner} />


        <div className="input-label">{t('common:form.birthdate.label')+'*'}</div>
        <Controller
          control={control}
          name="birthdatePartner"
          rules={birthdayValidationFx(t)}
          render={({ field: { onBlur, onChange, value } }) => (
            <MaskedInput
              className={classNames('masked-input', errors.birthdatePartner && 'error')}
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              guide={false}
              keepCharPositions={false}
              onBlur={onBlur}
              onChange={event => onChange(event.target.value)}
              placeholder={t('common:form.birthdate.placeholder')}
              value={value}
            />
          )}
        />
        <InputError error={errors.birthdatePartner} />


        <Input
          error={errors.placeOfBirthPartner}
          register={register('placeOfBirthPartner', { required })}
          label={t('common:form.placeOfBirth.label')+'*'}
          placeholder={`${t('common:your')} ${t('common:form.placeOfBirth.label').toLowerCase()}`}
          disallowNumbers={true}
          disallowSpecials={true}
        />
        <InputError error={errors.placeOfBirthPartner} />


        <Input
          error={errors.countryOfBirthPartner}
          register={register('countryOfBirthPartner', { required })}
          label={t('common:form.countryOfBirth.label')+'*'}
          placeholder={`${t('common:your')} ${t('common:form.countryOfBirth.label').toLowerCase()}`}
          disallowNumbers={true}
          disallowSpecials={true}
        />
        <InputError error={errors.countryOfBirthPartner} />


        <p className={mainStyle.required}>{t('common:form.mandatoryFields')}</p>


        <Button
          className={mainStyle.button}
          label={t('common:next')}
          onClick={handleSubmit}
          disabled={!isValid || isSamePerson}
          type="primary"
        />

      </form>
    </>
  )
}

export default Partner
