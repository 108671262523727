import IconWrapper from 'components/common/IconWrapper/IconWrapper'
import MainLogo from 'components/assets/icons/MainLogo'
import style from './Logo.module.scss'
import Link from 'components/common/Link/Link'
import { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import api from 'helpers/api'

const Logo = ({ styles }) => {
  const [svgContent, setSvgContent] = useState('');
  const [searchParams] = useSearchParams()
  const containerStyle = {
    marginTop: '15px',
  };
  const { state } = useLocation()

  const token = state?.token || searchParams.get('token')
  const location = useLocation();  
  const isSubscriptionRoute = location.pathname.includes('subscription');
  useEffect(() => {
    if (isSubscriptionRoute) {
      api.get({
        url: '/partners/logo',
        headers: { 
          Authorization: `Bearer ${token}` 
        },
      }).then(response => {
        setSvgContent(response.svgContent.svgLogo);
      }).catch(err => {
          console.log("Error Fetch SVG")
      });        
    }
  }, [isSubscriptionRoute, token]);
  
  if (!isSubscriptionRoute) {
    return <Link className={style.logo} label={<IconWrapper icon={MainLogo} styles={styles} />} text='Logo' to="#" />
  } else {
    return (
      <div className={styles.logo} style={containerStyle}>
        {svgContent ? (
          <div dangerouslySetInnerHTML={{ __html: svgContent }} />
        ) : (
          <IconWrapper icon={MainLogo} styles={styles} text='Logo' />
        )}
      </div>
    );
  }
}

export default Logo
