import { useEffect, useState } from 'react'
import ReactSelect from 'react-select'
import { changeLanguage } from "i18next";
import { useTranslation } from 'react-i18next'
import api from 'helpers/api'

const LanguageSelector = ({ darkBg }) => {
  const { i18n } = useTranslation()
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(undefined)

  useEffect(() => {
    const getCountries = async () => {
      try {
        const res = await api.get({ url: `/clients/countries` })

        if (res?.countries){
          const formattedRes = res.countries.map( option => {
            return {
              value: option.ISOCodeCountry,
              label: <>
                <span dangerouslySetInnerHTML={{__html: option.flag}}></span>
                <span>{option.ISOCodeCountry}</span>
              </>,
              locizeValue: option.ISOCodeLocize
            }
          })
          const current = formattedRes.find( lng => lng.value === i18n.language)

          setOptions(formattedRes)
          setSelectedOption(current)
        }
      } catch (error) {
        console.log('Fetch countries error')
      }
    }
    getCountries()
  }, [setOptions, setSelectedOption, i18n])

  const onChange = (option) => {
    changeLanguage(option.locizeValue)
    setSelectedOption(option)
  }

  const option = {
    span:{
      fontSize: 16,
      fontWeight: 300,
      fontVariant: 'small-caps',
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: 4,
      svg:{ width: '14px' },
      '&:last-child':{ margin: '0 0 4px 0' }
    }
  }

  const selectStyles = {
    control: () => ({
      height: 28,
      position: 'relative',
      background: 'transparent',
      border: darkBg ? '1px solid #fff' : '1px solid #00007A',
      borderRadius: 8,
      padding: '0 20px 0 5px',
      div:{ height: '100%' }
    }),

      valueContainer: () => ({}),
        singleValue: () => ({
          ...option,
          color: darkBg ? '#FFF' : '#00007A',
          display: 'flex',
          alignItems: 'center'
        }),
        input: () => ({
          display: 'none'
        }),

      indicatorsContainer: () => ({
        position: 'absolute',
        width: 'calc(100% - 10px)',
        top: 0
      }),
        indicatorSeparator: () => ({
          display: 'none'
        }),
        dropdownIndicator: () => ({
          fontSize: 14,
          textAlign: 'right',
          cursor: 'pointer',
          color: darkBg ? '#FFF' : '#00007A',
          svg:{
            width: 15,
            height: '100%',
            verticalAlign: 'middle'
          }
        }),

    menu: baseStyles => ({
      ...baseStyles,
      boxShadow: '8px 12px 36px rgba(0, 0, 147, 0.05)',
      marginTop: 4,
    }),
      menuList: () => ({
        padding: 4
      }),
        option: (_, { isSelected }) => ({
          ...option,
          height: 26,
          marginTop: -2,
          padding: '0 3px',
          borderRadius: 4,
          display: 'flex',
          alignItems: 'center',
          backgroundColor: isSelected && '#F5F5FF',
          '&:hover': {
            backgroundColor: '#F5F5FF',
            cursor: 'pointer',
          },
        }),
  }

  return selectedOption  &&  (
    <ReactSelect
      styles={selectStyles}
      value={selectedOption}
      onChange={onChange}
      options={options}
      placeholder={selectedOption.label}
    />
  )
}

export default LanguageSelector
