// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactForm_form__y15Vs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ContactForm_inputs__AVvcn {
  width: 100%;
  column-count: 2;
  gap: 20px;
  margin: 0 0 28px;
}

.ContactForm_input__fiGW8:not(:last-child) {
  margin-bottom: 20px;
}
.ContactForm_input__fiGW8 input {
  width: 100%;
}

.ContactForm_contract__36rUb {
  width: 100%;
  margin-bottom: 32px;
}

.ContactForm_button__ZaauD {
  width: 200px;
}

@media screen and (max-width: 600px) {
  .ContactForm_inputs__AVvcn {
    column-count: 1;
    margin-bottom: 20px;
  }
  .ContactForm_button__ZaauD {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Claims/ClaimApplication/ContactForm/ContactForm.module.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,WAAA;EACA,eAAA;EACA,SAAA;EACA,gBAAA;AAFF;;AAME;EACE,mBAAA;AAHJ;AAME;EACE,WAAA;AAJJ;;AAQA;EACE,WAAA;EACA,mBAAA;AALF;;AAQA;EACE,YAAA;AALF;;AAQA;EACE;IACE,eAAA;IACA,mBAAA;EALF;EAQA;IACE,WAAA;EANF;AACF","sourcesContent":["@use 'styles/sizes.scss';\n@use 'styles/colors.scss';\n\n.form {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.inputs {\n  width: 100%;\n  column-count: 2;\n  gap: 20px;\n  margin: 0 0 28px;\n}\n\n.input {\n  &:not(:last-child) {\n    margin-bottom: 20px;\n  }\n\n  input {\n    width: 100%;\n  }\n}\n\n.contract {\n  width: 100%;\n  margin-bottom: 32px;\n}\n\n.button {\n  width: 200px;\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .inputs {\n    column-count: 1;\n    margin-bottom: 20px;\n  }\n\n  .button {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `ContactForm_form__y15Vs`,
	"inputs": `ContactForm_inputs__AVvcn`,
	"input": `ContactForm_input__fiGW8`,
	"contract": `ContactForm_contract__36rUb`,
	"button": `ContactForm_button__ZaauD`
};
export default ___CSS_LOADER_EXPORT___;
