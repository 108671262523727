const DocumentText = ({ styles }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.47631 2.6433C2.5 3.61961 2.5 5.19096 2.5 8.33366V11.667C2.5 14.8097 2.5 16.381 3.47631 17.3573C4.45262 18.3337 6.02397 18.3337 9.16667 18.3337H10.8333C13.976 18.3337 15.5474 18.3337 16.5237 17.3573C17.5 16.381 17.5 14.8097 17.5 11.667V8.33366C17.5 5.19096 17.5 3.61961 16.5237 2.6433C15.5474 1.66699 13.976 1.66699 10.8333 1.66699H9.16667C6.02397 1.66699 4.45262 1.66699 3.47631 2.6433ZM6.04167 6.66699C6.04167 6.32181 6.32149 6.04199 6.66667 6.04199H13.3333C13.6785 6.04199 13.9583 6.32181 13.9583 6.66699C13.9583 7.01217 13.6785 7.29199 13.3333 7.29199H6.66667C6.32149 7.29199 6.04167 7.01217 6.04167 6.66699ZM6.04167 10.0003C6.04167 9.65515 6.32149 9.37533 6.66667 9.37533H13.3333C13.6785 9.37533 13.9583 9.65515 13.9583 10.0003C13.9583 10.3455 13.6785 10.6253 13.3333 10.6253H6.66667C6.32149 10.6253 6.04167 10.3455 6.04167 10.0003ZM6.66667 12.7087C6.32149 12.7087 6.04167 12.9885 6.04167 13.3337C6.04167 13.6788 6.32149 13.9587 6.66667 13.9587H10.8333C11.1785 13.9587 11.4583 13.6788 11.4583 13.3337C11.4583 12.9885 11.1785 12.7087 10.8333 12.7087H6.66667Z"
        fill="#1414FF"
        {...styles}
      />
    </svg>
  )
}

export default DocumentText
