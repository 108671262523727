import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import PageTitle from 'components/common/PageTitle/PageTitle'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import style from './ContractCancel.module.scss'
import Button from 'components/common/Button/Button'
import Textarea from 'components/common/Textarea/Textarea'
import { Controller, useForm } from 'react-hook-form'
import api from 'helpers/api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import Datepicker from 'components/common/Datepicker/Datepicker'
import { isInputError } from 'helpers/form'
import classNames from 'classnames'
import { DAY_1_IN_MS } from 'constants/dates'
import InfoMessage from 'components/common/InfoMessage/InfoMessage'
import Message from 'components/assets/icons/Message'
import Close from 'components/assets/icons/Close'


const ContractCancel = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const { state } = useLocation()

  const [contract, setContract] = useState(state?.contract)
  const [isSuccessMessageShown, setIsSuccessMessageShown] = useState(false)
  const timerRef = useRef(null)

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    defaultValues: {
      cancelationDate: Date.now() + DAY_1_IN_MS,
      reason: '',
    },
    mode: 'onTouched',
  })

  const errorFields = Object.keys(errors)

  useEffect(() => {
    const getContract = async () => {
      try {
        const adhesionRes = await api.get({ url: `/adhesions/${params.id}/client` })
        setContract(adhesionRes.adhesion)
      } catch (error) {
        console.log('Fetch contract error')
      }
    }

    if (!state?.contract) getContract()
  }, [params.id, contract?.urlDocument, state?.contract])

  const onSubmit = handleSubmit(async data => {
    try {
      await api.patch({
        url: `/adhesions/${params.id}/cancel/client`,
        data: { ...data, cancelationType: 'résiliation' },
      })
      setIsSuccessMessageShown(true)
      timerRef.current = setTimeout(() => {
        setIsSuccessMessageShown(false)
        navigate('/contracts')
      }, 5000)
      
    } catch (error) {
      console.log('Cancel contract error')
    }
  })

  if (!contract) return null

  const {
    contractType: { noticePeriodDuringFirstYear, noticePeriodAfterFirstYear, withdrawalPeriod },
    createdAt,
    insuranceExpiryDate: insuranceExpiryDateRaw,
    transactionHorodate,
  } = contract

  const isFirstYear = dayjs(createdAt).diff(dayjs(transactionHorodate), 'year') < 1

  const insuranceExpiryDate = dayjs(insuranceExpiryDateRaw)
  const currentDate = new Date(dayjs().add(1, 'day'))

  const maxDate = new Date(insuranceExpiryDate)
  const withdrawalPeriodEnd = dayjs(transactionHorodate).add(withdrawalPeriod.value, withdrawalPeriod.unit)
  const excludedDateIntervals = [
    {
      start: new Date(withdrawalPeriodEnd),
      end: new Date(
        isFirstYear
          ? withdrawalPeriodEnd.add(noticePeriodDuringFirstYear.value, noticePeriodDuringFirstYear.unit)
          : withdrawalPeriodEnd.add(noticePeriodAfterFirstYear.value, noticePeriodAfterFirstYear.unit),
      ),
    },
  ]

  return (
    <>
      <PageTitle text={t('contract:cancel.cancelContract')} />
      <PageSubtitle text={t('contract:cancel.cancelContractDescription')} />
      {/* not for MVP1 */}
      {/* <InfoMessage
        label={t('contract:list.infoMessage')}
        prefix={<ShieldLogo />}
        suffix={<Button label={t('contract:list.cta')} onClick={() => {}} />}
      /> */}

      <form className={style.form} onSubmit={onSubmit}>
        <div className={classNames(style.datepicker, style.input)}>
          <div className="input-label">{t('contract:cancel.cancelationDate')}</div>
          <Controller
            control={control}
            name="cancelationDate"
            render={({ field: { onChange, value } }) => (
              <Datepicker
                className={style.input}
                error={isInputError(errorFields, 'cancelationDate')}
                excludeDateIntervals={excludedDateIntervals}
                minDate={currentDate}
                maxDate={maxDate}
                onChange={onChange}
                placeholder={t('contract:cancel.cancelationDate')}
                register={register('cancelationDate', { required: true })}
                value={value}
              />
            )}
          />
        </div>
        <Textarea
          className={style.textarea}
          id="contract-cancel-reason"
          label={t('contract:cancel.reason')}
          placeholder={t('contract:cancel.reasonPlaceholder')}
          register={register('reason', { required: true })}
        />
        {isSuccessMessageShown > 0 ? (
          <InfoMessage
            className={style['success-message']}
            label={t('contract:cancel.reasonSuccessMessage')}
            prefix={<Message />}
            suffix={
              <div className={style.close} onClick={() => setIsSuccessMessageShown(false)}>
                <Close styles={{ fill: '#126F0E' }} />
              </div>
            }
            type="success"
          />
        ) : (
          <Button
          className={style.button}
          label={t('contract:cancel.cancelMyContract')}
          onClick={handleSubmit}
          type="primary"
          disabled={!isValid}
          />
        )}
        
      </form>
    </>
  )
}

export default ContractCancel
