import { useState, useEffect } from 'react'
import Slider from 'react-slick'
import api from 'helpers/api'
import style from './Submenu.module.scss'
import "./Submenu.scss";

const Submenu = ({setContract}) => {
  const [links, setLinks] = useState([])
  const reportToken = localStorage.getItem('reportToken')

  const settings = {
    dots: false,
    infinite: false,
    focusOnSelect: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    beforeChange: (prev, next) => {
      setContract(links[next])
    },
  }

  useEffect(() => {
    const getDatas = async () => {
      try {

        const contracts = await api.get({
          url: '/reporting/datas/getContracts',
          headers: { Authorization: `Bearer ${reportToken}` }
        })

        if (contracts){
          setLinks(contracts)
          setContract(contracts[0])
        }

      } catch (error) {
        console.log('Fetch contracts error', error)
      }
    }
    getDatas()
  }, [setContract, reportToken])

  return (
    <nav className={style.submenu}>
      <Slider {...settings}>
        {links.map((contract, index) => (
          <div key={`link-${index}`}>
            {contract.name}
          </div>
        ))}
      </Slider>
    </nav>
  )
}

export default Submenu
