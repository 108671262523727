// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Histogram_container__0IM7y {
  height: 250px;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/reporting/components/graphs/Histogram/Histogram.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;AACF","sourcesContent":[".container {\n  height: 250px;\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Histogram_container__0IM7y`
};
export default ___CSS_LOADER_EXPORT___;
