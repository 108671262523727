import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import Link from 'components/common/Link/Link'
import style from './NotFoundPage.module.scss'

const NotFoundPageReporting = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const reportToken = localStorage.getItem('reportToken')

  useEffect(() => {
    if (!reportToken) {
      navigate('/reporting/sign-in')
    }
  }, [reportToken, navigate])

  return (
    <div className={style['not-found']}>
      <div className={style.content}>
        <h1 className={style.title}>
          {t('reporting:notFoundPage.title')}
        </h1>

        <h2 className={style.subtitle}>
          {t('reporting:notFoundPage.subtitle')}
        </h2>

        <p className={style.text}>
          {t('reporting:notFoundPage.content')}
          &nbsp;
          <Link
            className={style.link}
            label={t('reporting:notFoundPage.button')}
            to={'/reporting'}
          />
        </p>
      </div>
    </div>
  )
}

export default NotFoundPageReporting
