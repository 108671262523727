// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 600px) {
  .App_app__GQ97N {
    height: min-content;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/App/App.module.scss"],"names":[],"mappings":"AAGA;EACE;IACE,mBAAA;EAFF;AACF","sourcesContent":["@use 'styles/colors';\n@use 'styles/sizes';\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .app {\n    height: min-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `App_app__GQ97N`
};
export default ___CSS_LOADER_EXPORT___;
