// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageWrapper_page-wrapper__tTTfJ {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.PageWrapper_content__o4sSZ {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 300px;
}
.PageWrapper_content__o4sSZ > *:not(.PageWrapper_back__vF5u1) {
  width: 100%;
}

.PageWrapper_outlet__2y27z {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 68px);
  padding: 10px 112px 80px;
  background-color: #fff;
}

@media screen and (max-width: 900px) {
  .PageWrapper_content__o4sSZ {
    padding-left: 250px;
  }
  .PageWrapper_outlet__2y27z {
    padding: 12px 40px 20px;
  }
}
@media screen and (max-width: 600px) {
  .PageWrapper_content__o4sSZ {
    width: 100%;
    padding-left: initial;
    padding-bottom: 120px;
  }
  .PageWrapper_outlet__2y27z {
    width: 100%;
    padding: 12px 20px 0;
  }
}
@media screen and (min-width: 2048px) {
  .PageWrapper_outlet__2y27z {
    padding: 50px 350px 80px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PageWrapper/PageWrapper.module.scss","webpack://./src/styles/sizes.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,WAAA;EACA,iBAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,mBCTe;ADOjB;AAIE;EACE,WAAA;AAFJ;;AAMA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,yBAAA;EACA,wBAAA;EACA,sBAAA;AAHF;;AAMA;EACE;IACE,mBC1BgB;EDuBlB;EAMA;IACE,uBAAA;EAJF;AACF;AAOA;EACE;IACE,WAAA;IACA,qBAAA;IACA,qBAAA;EALF;EAQA;IACE,WAAA;IACA,oBAAA;EANF;AACF;AASA;EACE;IACE,wBAAA;EAPF;AACF","sourcesContent":["@use 'styles/colors.scss';\n@use 'styles/sizes.scss';\n\n.page-wrapper {\n  display: flex;\n  width: 100%;\n  min-height: 100vh;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  padding-left: sizes.$nav-menu-width;\n\n  & > *:not(.back) {\n    width: 100%;\n  }\n}\n\n.outlet {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: calc(100% - sizes.$header-height);\n  padding: 10px 112px 80px;\n  background-color: #fff;\n}\n\n@media screen and (max-width: sizes.$breakpoint-md) {\n  .content {\n    padding-left: sizes.$nav-menu-width-md;\n  }\n\n  .outlet {\n    padding: 12px 40px 20px;\n  }\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .content {\n    width: 100%;\n    padding-left: initial;\n    padding-bottom: 120px;\n  }\n\n  .outlet {\n    width: 100%;\n    padding: 12px 20px 0;\n  }\n}\n\n@media screen and (min-width: sizes.$breakpoint-extra-lg) {\n  .outlet {\n    padding: 50px 350px 80px;\n  }\n}\n","$breakpoint-sm: 600px;\n$breakpoint-md: 900px;\n$breakpoint-extra-lg: 2048px;\n\n$nav-menu-width: 300px;\n$nav-menu-width-md: 250px;\n\n$nav-menu-mobile-height: 77px;\n$header-height: 68px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page-wrapper": `PageWrapper_page-wrapper__tTTfJ`,
	"content": `PageWrapper_content__o4sSZ`,
	"back": `PageWrapper_back__vF5u1`,
	"outlet": `PageWrapper_outlet__2y27z`
};
export default ___CSS_LOADER_EXPORT___;
