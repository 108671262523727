// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Doughnut_container__gFSYd {
  height: 150px;
  position: relative;
}
.Doughnut_container__gFSYd canvas {
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/reporting/components/graphs/Doughnut/Doughnut.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;AACF;AACE;EACE,YAAA;AACJ","sourcesContent":[".container {\n  height: 150px;\n  position: relative;\n\n  canvas{\n    margin: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Doughnut_container__gFSYd`
};
export default ___CSS_LOADER_EXPORT___;
