import dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import ExclamationTriangle from 'components/assets/icons/ExclamationTriangle'
import PrefixedText from 'components/common/PrefixedText/PrefixedText'


export const emailValidation = (t) => {
  return {
    required: true,
    validate: val => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i.test(val) || (
      <PrefixedText
        text={t('main:auth.email.pattern')}
        prefix={<ExclamationTriangle />}
      />
    )
  }
}

export const passwordValidation = (t) => {
  return {
    required: true,
    validate:{
      minLength: val => /.{9,}/.test(val) || (
        <PrefixedText
          text={t('main:auth.password.length')}
          prefix={<ExclamationTriangle />}
        />
      ),
      oneLetter: val => /[a-z]/.test(val) || (
        <PrefixedText
          text={t('main:auth.password.oneLetter')}
          prefix={<ExclamationTriangle />}
        />
      ),
      oneCap: val => /[A-Z]/.test(val) || (
        <PrefixedText
          text={t('main:auth.password.oneCap')}
          prefix={<ExclamationTriangle />}
        />
      ),
      oneNumber: val => /[0-9]/.test(val) || (
        <PrefixedText
          text={t('main:auth.password.oneNumber')}
          prefix={<ExclamationTriangle />}
        />
      ),
    }
  }
}


export const confirmPasswordValidation = (t, password) => {
  return {
    required: true,
    validate:{
      same: val => val === password || (
        <PrefixedText
          text={t('reporting:auth.password.differents')}
          prefix={<ExclamationTriangle />}
        />
      )
    }
  }
}


export const birthdayValidation = {
  required: true,
  validate: value => {
    dayjs.extend(customParseFormat)

    return dayjs().isAfter(dayjs(value, 'DD/MM/YYYY', true).add(18, 'years')) && dayjs().isBefore(dayjs(value, 'DD/MM/YYYY', true).add(70, 'years'));
  },
}

export const numberValidationCardNumber = {
  required: true,
  validate: (value) => {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.length === 16;
  },
};

export const expirationDateValidation = {
  required: true,
  validate: (value) => {
    const currentDate = dayjs();
    
    if (!dayjs(value, 'MM/YY', true).isValid()) {
      return false;
    }
    const enteredDate = dayjs(value, 'MM/YY', true);
    return enteredDate.isAfter(currentDate);
  },
};

export const numberValidationCVC = {
  required: true,
  validate: (value) => {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.length === 4;
  },
};



export const birthdayValidationFx = (t) => {
  dayjs.extend(customParseFormat)

  return {
    required: t('common:form.incorrect'),
    validate: {
      format: value => /\d{2}\/\d{2}\/\d{4}/.test(value) || t('common:form.birthdate.errorFormat'),
      range: value => (dayjs().isAfter(dayjs(value, 'DD/MM/YYYY', true).add(18, 'years')) && dayjs().isBefore(dayjs(value, 'DD/MM/YYYY', true).add(70, 'years'))) || t('common:form.birthdate.errorRange')
    }
  }
}

export const numberValidationCardNumberFx = (t) =>  {
  return {
    required: t('common:form.incorrect'),
    validate: val => val.replace(/\D/g,"").length === 16 || t('common:form.creditCard.error')
  }
}

export const expirationDateValidationFx = (t) => {
  return {
    required: t('common:form.incorrect'),
    validate: (value) => {

      if (!dayjs(value, 'MM/YY', true).isValid()) {
        return t('common:form.expirationDate.errorFormat')
      }

      const MM = ('0' + (dayjs().month() + 1)).slice(-2)
      const YYYY = dayjs().year()
      const currentDate = dayjs(MM+"/"+YYYY, 'MM/YYYY', true)
      const enteredDate = dayjs(value, 'MM/YY', true)

      if (enteredDate.isSame(currentDate)){
        return t('common:form.expirationDate.errorSame')
      }
      return enteredDate.isAfter(currentDate) || t('common:form.expirationDate.errorPast')
    }
  }
};

export const numberValidationCVCFx = (t) =>  {
  return {
    required: t('common:form.incorrect'),
    validate: val => /^\d{3,4}$/.test(val) || t('common:form.email.error')
  }
}


export const maskedDate = (date, dayjs) => {
  if (date && dayjs(date.replace(/\//g,'')).isValid()) {
    const DD = ('0' + dayjs(date, 'DD/MM/YYYY', true).date()).slice(-2)
    const MM = ('0' + (dayjs(date, 'DD/MM/YYYY', true).month() + 1)).slice(-2)
    const YYYY = dayjs(date, 'DD/MM/YYYY', true).year()
    return `${DD}/${MM}/${YYYY}`
  }
  return ''
}


export const formParams = {
  criteriaMode: 'all',
  delayError: 500,
  mode: 'onTouched',
}


export const getDefaultValue = (field, state) => state.client[field] || ''