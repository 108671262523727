import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Navigate, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import Link from 'components/common/Link/Link'
import Eye from 'components/assets/icons/Eye'
import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'
import api from 'helpers/api'
import Header from 'components/Header/Header'
import style from 'styles/auth.module.scss'
import EyeClosed from 'components/assets/icons/EyeClosed'
import Loader from 'components/assets/icons/Loader'
import { parseToken } from 'helpers/auth'

const SignIn = () => {
  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const accessToken = localStorage.getItem('accessToken')
  const parsedToken = parseToken(accessToken)

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onTouched',
  })

  const togglePasswordVisibility = () => {
    setIsPasswordShown(!isPasswordShown)
  }

  const onSubmit = handleSubmit(async data => {
    setLoading(true)

    const signIn = async () => {
      try {
        const res = await api.post({ url: '/auth/clients/signin', data: { email: data.email, password: data.password } })

        if (res.success && res.response.accessToken) {
          localStorage.setItem('accessToken', res.response.accessToken)
          navigate('/contracts')
        } else {
          setErrorMessage(t('main:errorMessages.general'))
        }
      } catch (error) {
        const errorMsg = error.toString().split(':')[1]?.trim()
        const errorsList = {
          "Wrong password":       t('main:auth.signIn.wrongPwd'),
          "Sign up unconfirmed":  t('main:auth.signIn.signUpUnconfirmed'),
          "User not registered":  t('main:auth.signIn.unknownUser'),
          "Unauthorized":         t('main:auth.signIn.unauthorized'),
          "Too many requests":    t('main:auth.signIn.tooManyRequests')
        }
        setErrorMessage(errorsList[errorMsg] ?? t('main:errorMessages.general'))
      }
      setLoading(false)
    }

    setTimeout( () => signIn(), 500)
  })

  return accessToken && new Date(parsedToken.exp) > Date.now()
  ? <Navigate to='/contracts' replace />
  : (
    <div className={style['auth']}>
      <Header darkBg styles={{ position: 'absolute', top: 0 }} />
      <h2 className={style.title}>{t('main:auth.signIn.title')}</h2>
      {errorMessage && <ResponseMessage className={style['response-message']} label={errorMessage} />}

      <form onSubmit={onSubmit}>
        <div className={style.inputs}>
          <div className={style.input}>
            <Input
              register={register('email', { required: true })}
              darkBg
              label="Email"
              placeholder={`${t('common:your')} ${t('common:form.email.placeholder')}`}
              type="email"
            />
          </div>
          <div className={style.input}>
            <Input
              register={register('password', { required: true })}
              darkBg
              label={t('main:auth.password.password')}
              placeholder={t('main:auth.password.placeholder')}
              type={isPasswordShown ? 'text' : 'password'}
              suffix={
                isPasswordShown ? (
                  <EyeClosed className={classNames(style.eye, 'clickable')} />
                ) : (
                  <Eye className={classNames(style.eye, 'clickable')} />
                )
              }
              onSuffixClick={togglePasswordVisibility}
            />
          </div>
        </div>

        <Button
          type={loading ? 'loading' : 'primary'}
          label={loading ? <Loader/> : t('main:auth.signIn.login')}
          onClick={handleSubmit}
          disabled={!isValid || loading || errorMessage === t('main:auth.signIn.tooManyRequests')} />
      </form>

      <Link className={style.link} label={t('main:auth.signIn.forgotPassword')} to={'/reset-password'} darkBg />
      <Link className={style.link} label={t('main:auth.signIn.createAccount')} to={'/sign-up'} darkBg />
    </div>
  )
}

export default SignIn
