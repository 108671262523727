// import classNames from 'classnames'
import style from './Radio.module.scss'

const Radio = ({
  label,
  values,
  register
}) => {

  return (
    <fieldset className={style.fieldset}>
      <legend className='input-label'>
        {label}
      </legend>

      {values.map( (value) =>
        <label className={style.label} key={`radio-${value}`}>
          <input
            name={label}
            value={value}
            type='radio'
            {...register}
          />
          {value}
        </label>
      )}
    </fieldset>
  );
};

export default Radio;