// Modules
import { Controller, useForm} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import MaskedInput from 'react-text-mask'
import dayjs from 'dayjs'

// Components
import Button from 'components/common/Button/Button'
import Radio from 'components/common/Radio/Radio'
import Input from 'components/common/Input/Input'
import InputError from 'components/common/InputError/InputError'
import Checkbox from 'components/common/Checkbox/Checkbox'
import Title from 'components/common/PageTitle/PageTitle'
import { birthdayValidationFx, maskedDate, formParams, getDefaultValue } from 'constants/inputValidation'
import { next } from 'components/Freemium/Nav/Nav'
import mainStyle from '../FreemiumComponents.module.scss'
import { Capitalize } from 'functions'


const Profil = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state, pathname } = useLocation()


  // Initialize form
  const required = t('common:form.incorrect')
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue
  } = useForm({
    ...formParams,
    values: state?.client && {
      firstname: getDefaultValue('firstname', state),
      lastname: getDefaultValue('lastname', state),
      birthdate: maskedDate(state.client?.birthdate, dayjs),
      placeOfBirth: getDefaultValue('placeOfBirth', state),
      countryOfBirth: getDefaultValue('countryOfBirth', state),
      partnerOption: state.client.partnerOption || false,
      civility: getDefaultValue('civility', state),
    },
  })


  // Submit form => Navigate to next page
  const onSubmit = handleSubmit(async data => {

    // Text format
    data.firstname = Capitalize(data.firstname)
    data.lastname = Capitalize(data.lastname)
    data.placeOfBirth = Capitalize(data.placeOfBirth)
    data.countryOfBirth = Capitalize(data.countryOfBirth)

    // Next
    next({
      pathname, navigate, state,
      data: { client: data }
    })
  })


  return (
    <>
      <Title className={mainStyle['page-title']} text={<>{t('freemium:profil.title')} 👀</>} />

      <form className={mainStyle.form} onSubmit={onSubmit}>

        <Radio
          register={register('civility', { required })}
          label={'Civilité *'}
          values={["Homme","Femme"]}
        />


        <Input
          error={errors.firstname}
          register={register('firstname', { required })}
          label={t('common:form.firstname.label')+'*'}
          placeholder={`${t('common:your')} ${t('common:form.firstname.label').toLowerCase()}`}
          disallowNumbers={true}
          disallowSpecials={true}
          autocomplete="given-name"
        />
        <InputError error={errors.firstname} />


        <Input
          error={errors.lastname}
          register={register('lastname', { required })}
          label={t('common:form.lastname.label')+'*'}
          placeholder={`${t('common:your')} ${t('common:form.lastname.label').toLowerCase()}`}
          disallowNumbers={true}
          disallowSpecials={true}
          autocomplete="family-name"
        />
        <InputError error={errors.lastname} />


        <div className="input-label">{t('common:form.birthdate.label')+'*'}</div>
        <Controller
          control={control}
          name="birthdate"
          rules={birthdayValidationFx(t)}
          render={({ field: { onBlur, onChange, value } }) => (
            <MaskedInput
              className={classNames('masked-input', errors.birthdate && 'error')}
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              guide={false}
              keepCharPositions={false}
              onBlur={onBlur}
              onChange={event => onChange(event.target.value)}
              placeholder={t('common:form.birthdate.placeholder')}
              value={value}
            />
          )}
        />
        <InputError error={errors.birthdate} />


        <Input
          error={errors.placeOfBirth}
          register={register('placeOfBirth', { required })}
          label={t('common:form.placeOfBirth.label')+'*'}
          placeholder={`${t('common:your')} ${t('common:form.placeOfBirth.label').toLowerCase()}`}
          disallowNumbers={true}
          disallowSpecials={true}
        />
        <InputError error={errors.placeOfBirth} />


        <Input
          error={errors.countryOfBirth}
          register={register('countryOfBirth', { required })}
          label={t('common:form.countryOfBirth.label')+'*'}
          placeholder={`${t('common:your')} ${t('common:form.countryOfBirth.label').toLowerCase()}`}
          disallowNumbers={true}
          disallowSpecials={true}
        />
        <InputError error={errors.countryOfBirth} />


        <Checkbox
          id="partnerOption"
          className={mainStyle.checkbox}
          label={<p>{ t('freemium:profil.partnerCheckbox') }</p>}
          onChange={e => setValue('partnerOption', e.target.value, true)}
          register={register('partnerOption')}
        />


        <p className={mainStyle.required}>{t('common:form.mandatoryFields')}</p>


        <Button
          className={mainStyle.button}
          label={t('common:next')}
          onClick={handleSubmit}
          disabled={!isValid}
          type="primary"
        />

      </form>
    </>
  )
}

export default Profil
