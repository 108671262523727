import style from './CustomTooltip.module.scss'

const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('#tooltip');

    if (!tooltipEl) {
        tooltipEl = document.createElement('aside')
        tooltipEl.setAttribute("id", "tooltip")
        tooltipEl.classList.add(style.tooltip)

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl
}

    
const CustomTooltip = (context) => {
    // Tooltip Element
    const {chart, tooltip} = context;

    // No data
    const labels = chart.options.plugins.legend.labels.generateLabels(chart)
    if (labels.length === 1 && !labels[0].text) return

    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.classList.add(style.hidden)
        return
    }

    if (tooltip.body) {
        // Title
        const titleLines = tooltip.title || []
        const title = document.createElement('div')
        title.classList.add(style.title)

        titleLines.forEach(value => {
            const text = document.createTextNode(value)
            title.appendChild(text)
        });

        // Content
        const list = document.createElement('ul')

        if (chart.config._config.type === 'bar'){
            const xIndex = chart.data.labels.findIndex( label => label === tooltip.title[0] )
            const datas = chart.data.datasets.map( (dataset, datasetIndex) => ({
                label: dataset.label,
                data: dataset.stacked && datasetIndex > 0
                    ? dataset.data[xIndex] - chart.data.datasets[datasetIndex-1].data[xIndex]
                    : dataset.data[xIndex],
                color: dataset.savedBackground,
            }))

            datas.forEach((data, i) => {

                const container = document.createElement('li')
                const colorBox = document.createElement('span');
                colorBox.classList.add(style.color)
                colorBox.style.background = data.color
                const text = document.createTextNode(`${data.label} : ${data.data}`)

                container.appendChild(colorBox)
                container.appendChild(text)
                list.appendChild(container)
            })
        }
        else {
            const bodyLines = tooltip.body.map(b => b.lines)
            bodyLines.forEach((label, i) => {

                const container = document.createElement('li')
                const colorBox = document.createElement('span');
                colorBox.classList.add(style.color)
                colorBox.style.background = tooltip.labelColors[i].backgroundColor
                const text = document.createTextNode(label)

                container.appendChild(colorBox)
                container.appendChild(text)
                list.appendChild(container)
            })
        }

        // Remove old children
        while (tooltipEl.firstChild) {
            tooltipEl.firstChild.remove()
        }

        // Add new children
        tooltipEl.appendChild(title);
        tooltipEl.appendChild(list);
    }

    tooltipEl.classList.remove(style.hidden)

    // Set tooltip position
    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas
    tooltipEl.style.left = positionX + tooltip.caretX + 'px'
    tooltipEl.style.top = positionY + tooltip.caretY + 'px'
}

export default CustomTooltip