import { useTranslation } from 'react-i18next'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { commonOptions, months } from 'helpers/chartjs';
import CustomLegend, { htmlLegendPlugin } from '../CustomLegend/CustomLegend';
import NoData from '../NoData/NoData';
import colors from 'components/reporting/styles/colors.scss'
import style from './Histogram.module.scss'
  
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const HistogramChart = ({datas, backgroundColor, legendID, stacked, monthsAxisX}) => {

    const { t } = useTranslation()
    const {labels, datasets} = datas
    const noData = (!datasets?.find( dataset => dataset.data.find( val => val !== 0) ))


    // Datas
    const data = {
        labels: monthsAxisX
            ? labels?.map( label => months(t)[Number(label)] )
            : labels,
        datasets: datasets?.toReversed().map( (data, index) => ({
            ...data,
            data: stacked
                ? data.data.map((nb, id) => {
                    let baseId = index-1
                    while (baseId >= 0){
                        nb += datasets.toReversed()[baseId].data[id]
                        baseId--
                    }
                    return nb
                })
                : data.data,
            backgroundColor: new Array(labels.length).fill(backgroundColor[index* -1 + datasets.length - 1]),
            savedBackground: backgroundColor[index* -1 + datasets.length - 1],
            borderRadius: stacked ? 4 : 2,
            borderColor: colors.grey4,
            stacked
        })) ?? []
    }

    const updateChart = (chart, index) => {
        if (!isNaN(index)) {
            chart.data.datasets.forEach( (dataset, datasetIndex) => {
                dataset.backgroundColor = new Array(6).fill(colors.grey5)
                dataset.backgroundColor[index] = backgroundColor[datasetIndex*-1 + datasets?.length - 1]

                dataset.borderWidth = new Array(6).fill(1)
                dataset.borderWidth[index] = 0
            })
        } else {
            chart.data.datasets.forEach( (dataset, datasetIndex) => {
                let datasetColor = backgroundColor[datasetIndex*-1 + datasets?.length - 1]
                dataset.backgroundColor = new Array(6).fill(datasetColor)
                dataset.borderWidth = new Array(6).fill(0)
            })
        }
    }


    const triggerEvents = {
        id: 'eventPlugin',
        afterEvent(chart, args, opts) {
          let event = args.event.type
          if (event === 'mouseout') {
            updateChart(chart)
            chart.update()
          }
        }
    }


    // Options
    const options = {
        ...commonOptions(legendID),
        barPercentage: stacked ? 0.5 : 0.8,
        onHover: (event, hovered, chart) => {
            if (!hovered.length){
                updateChart(chart)
            } else {
                let index = hovered[0].element.$context.dataIndex
                updateChart(chart, index)
            }
            chart.update()
        },
    }
    options.scales.x.stacked = stacked
    options.scales.x.grid.display = false

    if (datasets?.find( data => data.yAxisID === 'y1')){
        options.scales.y1.display = true
        options.scales.y.title.display = true
        options.scales.y.title.text = datasets.find( data => data.yAxisID === 'y' || !data.yAxisID).label
        options.scales.y1.title.display = true
        options.scales.y1.title.text = datasets.find( data => data.yAxisID === 'y1').label
    }

    if (noData){
        options.scales.x.ticks.color = colors.grey3
        options.scales.y.ticks.color = colors.grey3
        options.scales.y.title.color = colors.grey3
        options.scales.y1.ticks.color = colors.grey3
        options.scales.y1.title.color = colors.grey3
    }

    // Legend
    const legend = htmlLegendPlugin({
        inline: true,
        reverse: true,
        backgroundColor
    })

    return (
        <>
            <CustomLegend id={legendID} />
            <div className={style.container}>
                {noData && <NoData />}
                <Bar
                    data={data}
                    options={options}
                    plugins={[legend, triggerEvents]}
                />
            </div>
        </>
    )
}

export default HistogramChart