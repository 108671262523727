// Modules
import { useTranslation } from 'react-i18next'
import style from './NoData.module.scss'

const NoData = () => {
  const { t } = useTranslation()

  return <span className={style.nodata}>{t('reporting:graphs.nodata')}</span>
}

export default NoData