import { useForm, Controller } from 'react-hook-form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import Spinner from 'components/common/Spinner/Spinner'

import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'
import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import MaskedInput from 'react-text-mask'
import { isInputError } from 'helpers/form'
import style from '../CreditCardForm.module.scss'
import Title from 'components/common/PageTitle/PageTitle'
import api from 'helpers/api'
import { getYearPrice } from 'helpers/contract'
import { isMobile } from 'helpers/styles'
import '../CreditCardForm.scss'
import { numberValidationCardNumber, expirationDateValidation, numberValidationCVC } from 'constants/inputValidation'

const CardForm = ({ state, navigate }) => {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState(false);
  const [isSpinnerLoading, setIsSpinnerLoading] = useState(false)

  const getDefaultValue = field => {
    return state.client[field] || ''
  }

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    mode: 'onTouched',
    values:
      state?.client &&
      (state?.payByIban
        ? {
            owner: getDefaultValue('owner'),
            iban: getDefaultValue('iban'),
            bic: getDefaultValue('bic'),
          }
        : {
            owner: getDefaultValue('owner'),
            number: getDefaultValue('number'),
            placeOfBirth: getDefaultValue('expirationDate'),
            countryOfBirth: getDefaultValue('cvc'),
          }),
  })

  const errorFields = Object.keys(errors)

  const onSubmit = handleSubmit(async data => {
    try {
      setIsSpinnerLoading(true);
      const [expMonth, expYear] = data.expirationDate.split('/')
      const res = await api.post({
        url: '/adhesions/client',
        data: {
          ...state.listingData,
          ...state.contract,
          ...state.client,
          idCampaign: state.idCampaign,
          phone: state.client.phone.number,
          countryCode: state.client.phone.countryCode,
          birthdate: state.client.birthdate,
          transactionHorodate: new Date(),
          paymentType: 'credit card',
          paymentPlan,
          contractType: state.contract.contractType._id,
          cardOwnerFullName: data.owner,
          cardNumber: data.number.replace(/\D/g, ''),
          cvc: data.ccv.replace(/\D/g, ''),
          cardExpMonth: expMonth,
          cardExpYear: expYear,
          frequency: state.contract.paymentPlan,
        },
        headers: { Authorization: `Bearer ${state.token}` },
      })
      if (res?.response) {
        if (res?.response.compliance === false) {
          navigate('../cannot_continue_the_subscription', {
            state: {
              saveID: res.response.id,
              accessToken: state.accessToken,
              client: state.client,
              contract: state.contract,
              step: 7,
              token: state.token,
              idCampaign: state.idCampaign
            },
          })
        } else {
          navigate('../signature', {
            state: {
              saveID: res.response.id,
              accessToken: state.accessToken,
              client: state.client,
              contract: state.contract,
              step: 7,
              token: state.token,
              idCampaign: state.idCampaign
            },
          })
        }
      }
    } catch (error) {
      console.log('Create contact payment method error')
      setErrorMessage(true)
    } finally {
      setIsSpinnerLoading(false);
    }
  })

  if (!state) return null

  const handleDateChange = (value, onChange, onBlur) => {
      onChange(value);
      onBlur(value);
  }

  const {
    contract: { contractType, paymentPlan },
  } = state
  const priceType = contractType.priceType.find(elem => elem._id === state.contract.priceType)
  let rate = null
  if (priceType) {
    const rateType = priceType.rateType.find(elem => elem._id === state.contract.rateType)
    if (rateType) rate = rateType.rate
  }
  return (
    <>
      {isSpinnerLoading && <Spinner />}
      {errorMessage && <ResponseMessage className={style['response-message']} label={t('common:form.genericError')} />}

      <Title text={<>{t('subscription:creditCard.title')} 💳</>} />
      <p className={style.subtitle}>{t('subscription:creditCard.subtitle')}</p>

      <form className={style.form} onSubmit={onSubmit}>
        <div className={style.inputs}>
          <Input
            className={style.input}
            error={isInputError(errorFields, 'owner')}
            register={register('owner', { required: true })}
            label={t('common:paymentMethod.creditCard.owner.label')}
            placeholder={t('common:paymentMethod.creditCard.owner.placeholder')}
            disallowNumbers={true}
          />
          {errorFields.includes('owner') && <p className={style['input-error']}>{t('common:form.incorrect')}</p>}
          <div>
            <div className="input-label">{t('common:paymentMethod.creditCard.number.label')}</div>
            <Controller
              control={control}
              name="number"
              rules={numberValidationCardNumber}
              render={({ field: { onBlur, onChange, value } }) => (
                <MaskedInput
                  className={classNames('masked-input', isInputError(errorFields, 'number') && 'error')}
                  mask={[
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  guide={false}
                  keepCharPositions={false}
                  onBlur={onBlur}
                  onChange={value => handleDateChange(value, onChange, onBlur)}
                  placeholder={t('common:paymentMethod.creditCard.number.placeholder')}
                  value={value}
                  disallowletters={value}
                />
              )}
            />
            {errorFields.includes('number') && <p className={style['input-error']}>{t('common:form.incorrect')}</p>}
          </div>
          <div className={style['half-width-inputs']}>
            <div>
              <div className="input-label">{t('common:form.expirationDate.label')}</div>
              <Controller
                control={control}
                name="expirationDate"
                rules={expirationDateValidation}
                render={({ field: { onBlur, onChange, value } }) => (
                  <MaskedInput
                    className={classNames('masked-input', isInputError(errorFields, 'expirationDate') && 'error')}
                    mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                    guide={false}
                    keepCharPositions={false}
                    onBlur={onBlur}
                    onChange={value => handleDateChange(value, onChange, onBlur)}
                    placeholder={t(
                      isMobile()
                        ? 'common:form.expirationDate.placeholderMobile'
                        : 'common:form.expirationDate.placeholder',
                    )}
                    value={value}
                    disallowletters={value}
                  />
                )}
              />
              {errorFields.includes('expirationDate') && <p className={style['input-error']}>{t('common:form.incorrect')}</p>}
            </div>
            <Input
              maxLength="4"
              className={style.input}
              error={isInputError(errorFields, 'ccv')}
              register={register('ccv', { required: true, minLength: 3, maxLength: 4 })}
              rules={numberValidationCVC}
              label={t('common:paymentMethod.creditCard.ccv.label')}
              placeholder={t(
                isMobile()
                  ? 'common:paymentMethod.creditCard.ccv.placeholderMobile'
                  : 'common:paymentMethod.creditCard.ccv.placeholder',
              )}
              disallowLetters={true}
            />
            {errorFields.includes('ccv') && <p className={style['input-error']}>{t('common:form.incorrect')}</p>}
          </div>
        </div>

        {rate ? (
          <Button
          className={style.button}
          label={t('subscription:creditCard.button', {
            price: paymentPlan === 'month' ? parseFloat(rate).toFixed(2).replace('.', ',') : getYearPrice(rate).replace('.', ','),
          })}
          onClick={handleSubmit}
          type="primary"
          disabled={!isValid}
         />
        ) : (
          <p style={{ color: 'red' }}>Une erreur est survenue. </p>
        )}
      </form>
    </>
  )
}

export default CardForm
