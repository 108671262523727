// Modules
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'

// Components
import Title from 'components/common/PageTitle/PageTitle'

// Styles
import style from './Success.module.scss'

const SuccessPage = () => {
  const { t } = useTranslation()
  const { state } = useLocation()


  return (
    <div className={style.success}>
      <Title text={t('freemium:success.title', { name: state.client.firstname })+' 🎉'}/>
      <p className={style.subtitle}>{t('freemium:success.subtitle')}</p>
    </div>
  )
};

export default SuccessPage;
