// Modules
import { useState } from 'react'
import api from 'helpers/api'

// Styles
import classNames from 'classnames'
import style from './AddressInput.module.scss'
import InputStyle from '../Input/Input.module.scss'

const AddressInput = ({ onChange, error, label, placeholder, register, countries }) => {

  const [adressOptions, setAdressOptions] = useState([])


  const onAddressChange = async value => {
    setAdressOptions([])

    const res = await api.get({
      url: `/clients/address/${value}`,
    })

    const number = res.address_components.find(item => item.types.includes('street_number'))?.long_name || ''
    const street = res.address_components.find(item => item.types.includes('route'))?.long_name || ''
    const city = res.address_components.find(item => item.types.includes('locality') || item.types.includes('postal_town'))?.long_name || ''
    const zipcode = res.address_components.find(item => item.types.includes('postal_code'))?.long_name || ''
    const country = res.address_components.find(item => item.types.includes('country'))?.long_name || ''
    const address = `${number} ${street}`.trim();

    onChange( { address, zipcode, city, country })
  }


  const getAddressOptions = async address => {
    if (address){
      const res = await api.get({
        url: '/clients/address',
        params: {
          address,
          countries: countries.map( c => 'country:'+c ).join('|')
        },
      })
  
      const options = res.reduce((acc, cur) => {
        const label = cur.description
        const value = cur.place_id
        return [ ...acc, { label, value } ]
      }, [])
  
      setAdressOptions(options)
    } else {
      setAdressOptions([])
    }
    
  }


  let inputTimeout
  const loadAddressOptions = e => {
    clearTimeout(inputTimeout)
    return new Promise(resolve => {
      inputTimeout = setTimeout(async () => {

        const data = await getAddressOptions(e.target.value)
        resolve(data)
      }, 500)
    })
  }


  return (
    <div className={classNames(InputStyle['input-wrapper'], style.addressContainer, error && InputStyle.error)}>
      <label className={classNames('input-label')} htmlFor='address'>
        {label}
      </label>
      <div className={InputStyle.input}>
        <input
          id='address'
          type='text'
          autoComplete='street-address'
          placeholder={placeholder}
          {...register}
          onChange={loadAddressOptions}
        />
      </div>
      {adressOptions.length > 0 && <ul>
        {adressOptions.map((opt, id) => (
          <li key={`option-${id}`} onClick={() => onAddressChange(opt.value)}>
            {opt.label}
          </li>
        ))}
      </ul>}
    </div>
  )
}

export default AddressInput
