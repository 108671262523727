// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-tel-input .form-control.input-phone {
  width: 100%;
  height: 40px;
  border: 1px solid #00007a;
  border-radius: 8px;
  outline: none;
  color: #00007a;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
}
.react-tel-input .form-control.input-phone:focus {
  border: 1px solid #1414ff;
}
.react-tel-input .flag-dropdown,
.react-tel-input .open.flag-dropdown {
  border-color: transparent;
  background-color: transparent;
}
.react-tel-input .flag-dropdown .selected-flag,
.react-tel-input .flag-dropdown .selected-flag.open,
.react-tel-input .open.flag-dropdown .selected-flag,
.react-tel-input .open.flag-dropdown .selected-flag.open {
  border-color: transparent;
  background-color: transparent;
}
.react-tel-input .flag-dropdown .selected-flag:focus, .react-tel-input .flag-dropdown .selected-flag:hover,
.react-tel-input .flag-dropdown .selected-flag.open:focus,
.react-tel-input .flag-dropdown .selected-flag.open:hover,
.react-tel-input .open.flag-dropdown .selected-flag:focus,
.react-tel-input .open.flag-dropdown .selected-flag:hover,
.react-tel-input .open.flag-dropdown .selected-flag.open:focus,
.react-tel-input .open.flag-dropdown .selected-flag.open:hover {
  border-color: transparent;
  background-color: transparent;
}

.error .react-tel-input .form-control.input-phone {
  border: 1px solid #bd454a;
}`, "",{"version":3,"sources":["webpack://./src/components/common/PhoneInput/PhoneInput.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAIE;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,cCHO;EDIP,gBAAA;EACA,eAAA;EACA,iBAAA;AAHJ;AAKI;EACE,yBAAA;AAHN;AAME;;EAEE,yBAAA;EACA,6BAAA;AAJJ;AAMI;;;;EAEE,yBAAA;EACA,6BAAA;AAFN;AAGM;;;;;;;EAEE,yBAAA;EACA,6BAAA;AAIR;;AAEA;EACE,yBAAA;AACF","sourcesContent":["@use 'styles/colors';\n@use 'styles/sizes';\n\n.react-tel-input {\n  .form-control.input-phone {\n    width: 100%;\n    height: 40px;\n    border: 1px solid colors.$primary8;\n    border-radius: 8px;\n    outline: none;\n    color: colors.$primary8;\n    font-weight: 300;\n    font-size: 16px;\n    line-height: 28px;\n\n    &:focus {\n      border: 1px solid colors.$primary5;\n    }\n  }\n  .flag-dropdown,\n  .open.flag-dropdown {\n    border-color: transparent;\n    background-color: transparent;\n\n    .selected-flag,\n    .selected-flag.open {\n      border-color: transparent;\n      background-color: transparent;\n      &:focus,\n      &:hover {\n        border-color: transparent;\n        background-color: transparent;\n      }\n    }\n  }\n}\n\n.error .react-tel-input .form-control.input-phone {\n  border: 1px solid colors.$danger5;\n}\n","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
