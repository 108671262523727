// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentMethodForm_form__OjXO3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PaymentMethodForm_inputs__2wWsh {
  width: 100%;
  max-width: 448px;
  margin-bottom: 40px;
}

.PaymentMethodForm_secondary-inputs__v0rnh {
  column-count: 2;
  gap: 12px;
}

.PaymentMethodForm_button__mhigY {
  width: 200px;
}

@media screen and (max-width: 600px) {
  .PaymentMethodForm_input__BmPwX:not(:last-child) {
    margin-bottom: 20px;
  }
  .PaymentMethodForm_button__mhigY {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Contacts/PaymentMethodForm/PaymentMethodForm.module.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,WAAA;EACA,gBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,eAAA;EACA,SAAA;AAFF;;AAKA;EACE,YAAA;AAFF;;AAKA;EAEI;IACE,mBAAA;EAHJ;EAOA;IACE,WAAA;EALF;AACF","sourcesContent":["@use 'styles/colors';\n@use 'styles/sizes';\n\n.form {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.inputs {\n  width: 100%;\n  max-width: 448px;\n  margin-bottom: 40px;\n}\n\n.secondary-inputs {\n  column-count: 2;\n  gap: 12px;\n}\n\n.button {\n  width: 200px;\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .input {\n    &:not(:last-child) {\n      margin-bottom: 20px;\n    }\n  }\n\n  .button {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `PaymentMethodForm_form__OjXO3`,
	"inputs": `PaymentMethodForm_inputs__2wWsh`,
	"secondary-inputs": `PaymentMethodForm_secondary-inputs__v0rnh`,
	"button": `PaymentMethodForm_button__mhigY`,
	"input": `PaymentMethodForm_input__BmPwX`
};
export default ___CSS_LOADER_EXPORT___;
