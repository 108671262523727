import classNames from 'classnames'
import ReactPhoneInput from 'react-phone-input-2'

// Styles
import 'react-phone-input-2/lib/style.css'
import './PhoneInput.scss'
import style from './PhoneInput.module.scss'

const PhoneInput = ({ className, darkBg, disabled, error, id, label, placeholder, value, ...props }) => {
  return (
    <div
      className={classNames(
        style['input-wrapper'],
        className,
        darkBg && style['dark-bg'],
        error && style.error && 'error',
      )}
    >
      <label className={classNames('input-label', darkBg && 'dark-bg')} htmlFor={id}>
        {label}
      </label>
      <ReactPhoneInput
        disabled={disabled}
        placeholder={placeholder}
        id={id}
        value={value}
        inputClass={classNames('input-phone', style['input-phone'])}
        {...props}
        autocomplete="tel"
      />
    </div>
  )
}

export default PhoneInput
