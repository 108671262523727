// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Submenu_submenu__bB5OO {
  width: 100%;
  margin-top: -10px;
  margin-bottom: 5px;
  position: relative;
  white-space: nowrap;
  overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/reporting/components/Submenu/Submenu.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;AAmBF","sourcesContent":["@use 'components/reporting/styles/colors.scss';\n\n.submenu {\n  width: 100%;\n  margin-top: -10px;\n  margin-bottom: 5px;\n  position: relative;\n  white-space: nowrap;\n  overflow-x: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"light": `#FFFFFF`,
	"dark": `#121217`,
	"blue1": `#00007A`,
	"blue2": `#0000C9`,
	"blue3": `#4343FF`,
	"blue4": `#8686FF`,
	"blue5": `#D8D8FF`,
	"orange1": `#DA8200`,
	"orange2": `#FFA92C`,
	"orange3": `#FFC26B`,
	"orange4": `#FFE1B7`,
	"orange5": `#FFF3E3`,
	"grey1": `#3E3E50`,
	"grey2": `#838390`,
	"grey3": `#C3C3C9`,
	"grey4": `#E0E0E3`,
	"grey5": `#F9F9FA`,
	"submenu": `Submenu_submenu__bB5OO`
};
export default ___CSS_LOADER_EXPORT___;
