// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bloc_bloc__PC6wy {
  width: 100%;
  padding: 16px;
  border: 1px solid #E0E0E3;
  border-radius: 8px;
}
.Bloc_bloc__PC6wy.Bloc_mid__TDkPS {
  width: calc(50% - 6px);
}
.Bloc_bloc__PC6wy.Bloc_tierx2__XKUX2 {
  width: calc(66.6666666667% - 4px);
}
.Bloc_bloc__PC6wy.Bloc_tier__uHjjW {
  width: calc(33.3333333333% - 8px);
}
.Bloc_bloc__PC6wy.Bloc_quart__Xetd\\+ {
  width: calc(25% - 9px);
}`, "",{"version":3,"sources":["webpack://./src/components/reporting/components/Bloc/Bloc.module.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;AAkBF;AAhBE;EACI,sBAAA;AAkBN;AAfE;EACI,iCAAA;AAiBN;AAdE;EACI,iCAAA;AAgBN;AAbE;EACI,sBAAA;AAeN","sourcesContent":["@use 'components/reporting/styles/colors.scss';\n@use 'components/reporting/styles/sizes.scss';\n\n.bloc{\n  width: 100%;\n  padding: 16px;\n  border: 1px solid colors.$grey4;\n  border-radius: 8px;\n\n  &.mid{\n      width: calc(100% / 2 - (sizes.$graphs-gap / 2));\n  }\n\n  &.tierx2{\n      width: calc(100% / 3 * 2 - (sizes.$graphs-gap / 3));\n  }\n\n  &.tier{\n      width: calc(100% / 3 - (sizes.$graphs-gap / 3 * 2));\n  }\n\n  &.quart{\n      width: calc(100% / 4 - (sizes.$graphs-gap / 4 * 3));\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"light": `#FFFFFF`,
	"dark": `#121217`,
	"blue1": `#00007A`,
	"blue2": `#0000C9`,
	"blue3": `#4343FF`,
	"blue4": `#8686FF`,
	"blue5": `#D8D8FF`,
	"orange1": `#DA8200`,
	"orange2": `#FFA92C`,
	"orange3": `#FFC26B`,
	"orange4": `#FFE1B7`,
	"orange5": `#FFF3E3`,
	"grey1": `#3E3E50`,
	"grey2": `#838390`,
	"grey3": `#C3C3C9`,
	"grey4": `#E0E0E3`,
	"grey5": `#F9F9FA`,
	"bloc": `Bloc_bloc__PC6wy`,
	"mid": `Bloc_mid__TDkPS`,
	"tierx2": `Bloc_tierx2__XKUX2`,
	"tier": `Bloc_tier__uHjjW`,
	"quart": `Bloc_quart__Xetd+`
};
export default ___CSS_LOADER_EXPORT___;
