// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContractSelection_items__qNiqb {
  flex: 2 1;
  margin-top: 20px;
}
.ContractSelection_items__qNiqb.ContractSelection_empty__pD\\+1q {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContractSelection_empty-list-message__pc3oB {
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  color: #00007a;
}

.ContractSelection_item__d7Gu4 {
  position: relative;
  cursor: pointer;
}
.ContractSelection_item__d7Gu4:not(:last-child) {
  margin-bottom: 12px;
}

@media screen and (max-width: 600px) {
  .ContractSelection_items__qNiqb {
    margin-top: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Claims/ClaimApplication/ContractSelection/ContractSelection.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACE,SAAA;EACA,gBAAA;AAFF;AAIE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAFJ;;AAMA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cCZS;ADSX;;AAMA;EACE,kBAAA;EACA,eAAA;AAHF;AAKE;EACE,mBAAA;AAHJ;;AAOA;EACE;IACE,aAAA;EAJF;AACF","sourcesContent":["@use 'styles/sizes.scss';\n@use 'styles/colors.scss';\n\n.items {\n  flex: 2;\n  margin-top: 20px;\n\n  &.empty {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n}\n\n.empty-list-message {\n  font-weight: 400;\n  font-size: 20px;\n  line-height: 36px;\n  text-align: center;\n  color: colors.$primary8;\n}\n\n.item {\n  position: relative;\n  cursor: pointer;\n\n  &:not(:last-child) {\n    margin-bottom: 12px;\n  }\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .items {\n    margin-top: 0;\n  }\n}\n","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"items": `ContractSelection_items__qNiqb`,
	"empty": `ContractSelection_empty__pD+1q`,
	"empty-list-message": `ContractSelection_empty-list-message__pc3oB`,
	"item": `ContractSelection_item__d7Gu4`
};
export default ___CSS_LOADER_EXPORT___;
