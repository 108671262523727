const EyeOpen = ({ className }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
      <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#838390" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.7544 11.5404C16.9131 2.8198 7.08837 2.8199 2.247 11.5405C2.08954 11.8242 2.08954 12.1759 2.247 12.4596C7.08837 21.1802 16.9131 21.1801 21.7544 12.4595C21.9119 12.1758 21.9119 11.8241 21.7544 11.5404Z" stroke="#838390" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default EyeOpen
