// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrefixedText_prefixed-text__WGXCP {
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding: 5px 5px 0 0;
}

.PrefixedText_prefix__KoOd3 {
  height: 16px;
  margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/PrefixedText/PrefixedText.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,oBAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;AACF","sourcesContent":[".prefixed-text {\n  display: flex;\n  align-items: center;\n  margin-right: 20px;\n  padding: 5px 5px 0 0;\n}\n\n.prefix {\n  height: 16px;\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"prefixed-text": `PrefixedText_prefixed-text__WGXCP`,
	"prefix": `PrefixedText_prefix__KoOd3`
};
export default ___CSS_LOADER_EXPORT___;
