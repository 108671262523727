// Modules
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useOutletContext } from "react-router-dom"

// Components
import Actions from 'components/reporting/components/Actions/Actions'
import StateData from 'components/reporting/components/StateData/StateData'
import Bloc from 'components/reporting/components/Bloc/Bloc'
import DoughnutChart from 'components/reporting/components/graphs/Doughnut/Doughnut'
import LineChart from 'components/reporting/components/graphs/Line/Line'

// Helpers
import { formatNb } from 'functions'
import { getDatasFrom } from 'helpers/chartjs'

//Styles
import colors from 'components/reporting/styles/colors.scss'

const Production = () => {
  const { t } = useTranslation()
  const [contract] = useOutletContext()
  const [currentContract, setCurrentContract] = useState(null)
  const [totalAdhesions, setTotalAdhesions] = useState(null)
  const [thisMonthAdhesions, setThisMonthAdhesions] = useState(null)
  const [totalCancellations, setTotalCancellations] = useState(null)
  const [productsSold, setProductsSold] = useState({})
  const [soldPerPackage, setSoldPerPackage] = useState({})
  const [adhesionPerPackage, setAdhesionPerPackage] = useState({})
  const [topDistributors, setTopDistributors] = useState({})
  const [primesPerPackage, setPrimesPerPackage] = useState({})
  const totalAdhesionPerPackage = formatNb(adhesionPerPackage?.data?.reduce( (acc, cur) => acc + cur ,0))
  const totalDistributors = formatNb(topDistributors?.data?.reduce( (acc, cur) => acc + cur ,0))


  useEffect(() => {

    const getDatas = async () => {
      if (currentContract === contract._id) return
      setCurrentContract(contract._id)

      try {
        Promise.all([
          getDatasFrom({ contract, endpoint: 'insuredProducts' }),
          getDatasFrom({ contract, endpoint: 'thisMonthAdhesions' }),
          getDatasFrom({ contract, endpoint: 'totalCancellations' }),
          getDatasFrom({ contract, endpoint: 'productsSold' }),
          getDatasFrom({ contract, endpoint: 'soldPerPackage' }),
          getDatasFrom({ contract, endpoint: 'adhesionPerPackage' }),
          getDatasFrom({ contract, endpoint: 'topDistributors' }),
          getDatasFrom({ contract, endpoint: 'primesPerPackage' }),
        ]).then( values => {
          if (values[0]) setTotalAdhesions(values[0])
          if (values[1]) setThisMonthAdhesions(values[1])
          if (values[2]) setTotalCancellations(values[2])
          if (values[3]) setProductsSold(values[3])
          if (values[4]) setSoldPerPackage(values[4])
          if (values[5]) setAdhesionPerPackage(values[5])
          if (values[6]) setTopDistributors(values[6])
          if (values[7]) setPrimesPerPackage(values[7])
        })

      } catch (error) {
        console.log('Fetch datas error')
      }
    }
    if (contract?._id) getDatas()
  }, [contract])

  return (
    <>
      {/* <Actions /> */}

      <StateData
        title={t('reporting:graphs.totalAdhesions')}
        data={totalAdhesions}
      />

      <StateData
        title={t('reporting:graphs.adhesionsThisMonth')}
        data={thisMonthAdhesions}
      />

      <StateData
        title={t('reporting:graphs.totalCancellations')}
        data={totalCancellations}
      />

      <Bloc
        title={t('reporting:graphs.totalSold.title')}
        subtitle={t('reporting:graphs.range', {type: null})}
        size={contract?.partner && 'mid'}
        content={<LineChart
          backgroundColor={[
            colors.blue4
          ]}
          gradient={colors.blue4}
          legendID='productsSold'
          datas={productsSold}
          monthsAxisX={true}
        />}
      />

      <Bloc
        title={t('reporting:graphs.soldPerPackage.title')}
        subtitle={t('reporting:graphs.range', {type: null})}
        size={contract?.partner ? 'mid' : 'tierx2'}
        content={<LineChart
          backgroundColor={[
            colors.blue1,
            colors.blue4,
            colors.orange2,
            colors.orange4
          ]}
          legendID='soldPerPackage'
          datas={soldPerPackage}
          monthsAxisX={true}
        />}
      />

      <Bloc
        title={t('reporting:graphs.adhesionPerPackage.title')}
        subtitle={t('reporting:graphs.adhesionPerPackage.subtitle', {number: totalAdhesionPerPackage})}
        size='tier'
        content={<DoughnutChart
          backgroundColor={[
            colors.blue1,
            colors.blue2,
            colors.blue3,
            colors.blue4,
            colors.blue5
          ]}
          legendID='legendRateType'
          datas={adhesionPerPackage}
        />}
      />

      {contract?.partner && <Bloc
        title={t('reporting:graphs.topDistributor.title')}
        subtitle={t('reporting:graphs.topDistributor.subtitle', {number: totalDistributors})}
        size='tier'
        content={<DoughnutChart
          backgroundColor={[
            colors.orange1,
            colors.orange2,
            colors.orange3,
            colors.orange4,
            colors.orange5
          ]}
          legendID='legendTopDistributor'
          datas={topDistributors}
        />}
      />}

      {contract?.partner && <Bloc
        title={t('reporting:graphs.primesPerPackage.title')}
        subtitle={t('reporting:graphs.primesPerPackage.subtitle')}
        size='tier'
        content={<DoughnutChart
          backgroundColor={[
            colors.grey1,
            colors.grey2,
            colors.grey3,
            colors.grey4,
            colors.grey5
          ]}
          legendID='legendPrimesPerPackage'
          datas={primesPerPackage}
        />}
      />}
    </>
  )
};

export default Production
