// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RangeInput_input-wrapper__4gnA8 {
  width: 100%;
  break-inside: avoid-column;
}
.RangeInput_input-wrapper__4gnA8 .RangeInput_input__Di-TY {
  position: relative;
}
.RangeInput_input-wrapper__4gnA8 input {
  width: 100%;
}
.RangeInput_input-wrapper__4gnA8 .RangeInput_suffix__utK6f {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  height: 18px;
}
.RangeInput_input-wrapper__4gnA8.RangeInput_error__Xgwcv input {
  border: 1px solid #bd454a;
}
.RangeInput_input-wrapper__4gnA8.RangeInput_dark-bg__64-Nl .RangeInput_label__lgWYf {
  color: #fff;
}
.RangeInput_input-wrapper__4gnA8.RangeInput_dark-bg__64-Nl input {
  border: none;
}

.RangeInput_points_container__pD1Ar {
  display: flex;
  justify-content: space-between;
}
.RangeInput_points_container__pD1Ar .RangeInput_point__jZgI7 {
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.75rem;
  color: #00007a;
}

@media screen and (max-width: 600px) {
  .RangeInput_input__Di-TY {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/RangeInput/RangeInput.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,0BAAA;AAFF;AAIE;EACE,kBAAA;AAFJ;AAKE;EACE,WAAA;AAHJ;AAME;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,2BAAA;EACA,YAAA;AAJJ;AAQI;EACE,yBAAA;AANN;AAWI;EACE,WAAA;AATN;AAYI;EACE,YAAA;AAVN;;AAeA;EACE,aAAA;EACA,8BAAA;AAZF;AAcE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBAAA;EACA,cC1CO;AD8BX;;AAgBA;EACE;IACE,WAAA;EAbF;AACF","sourcesContent":["@use 'styles/colors';\n@use 'styles/sizes';\n\n.input-wrapper {\n  width: 100%;\n  break-inside: avoid-column;\n\n  .input {\n    position: relative;\n  }\n\n  input {\n    width: 100%;\n  }\n\n  .suffix {\n    position: absolute;\n    right: 8px;\n    top: 50%;\n    transform: translateY(-50%);\n    height: 18px;\n  }\n\n  &.error {\n    input {\n      border: 1px solid colors.$danger5;\n    }\n  }\n\n  &.dark-bg {\n    .label {\n      color: #fff;\n    }\n\n    input {\n      border: none;\n    }\n  }\n}\n\n.points_container {\n  display: flex;\n  justify-content: space-between;\n\n  .point {\n    font-size: 1rem;\n    font-style: normal;\n    font-weight: 300;\n    line-height: 1.75rem;\n    color: colors.$primary8;\n  }\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .input {\n    width: 100%;\n  }\n}\n","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-wrapper": `RangeInput_input-wrapper__4gnA8`,
	"input": `RangeInput_input__Di-TY`,
	"suffix": `RangeInput_suffix__utK6f`,
	"error": `RangeInput_error__Xgwcv`,
	"dark-bg": `RangeInput_dark-bg__64-Nl`,
	"label": `RangeInput_label__lgWYf`,
	"points_container": `RangeInput_points_container__pD1Ar`,
	"point": `RangeInput_point__jZgI7`
};
export default ___CSS_LOADER_EXPORT___;
