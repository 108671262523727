import ExclamationTriangle from 'components/assets/icons/ExclamationTriangle'
import Valid from 'components/assets/icons/Valid'

const ValidationIcon = ({ errors, dirtyFields, field, validationType }) => {

  // if (!dirtyFields[field]) {
  //   return '•'
  // }
  if (!errors[field]) {
    return <Valid />
  }

  return errors[field].types[validationType]
    ? <ExclamationTriangle />
    : <Valid />
}

export default ValidationIcon
