// Modules
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

// Components
import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import InputError from 'components/common/InputError/InputError'
import Title from 'components/common/PageTitle/PageTitle'
import AddressInput from 'components/common/AddressInput/AddressInput'
import { formParams, getDefaultValue } from 'constants/inputValidation'
import { next } from 'components/Freemium/Nav/Nav'
import mainStyle from '../FreemiumComponents.module.scss'
import { Capitalize } from 'functions'

const AddressForm = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state, pathname } = useLocation()


  // Initialize form
  const required = t('common:form.incorrect')
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    trigger
  } = useForm({
    ...formParams,
    values: {
      address: getDefaultValue('address', state),
      zipcode: getDefaultValue('zipcode', state),
      city: getDefaultValue('city', state),
      country: getDefaultValue('country', state),
      additionalAddress: getDefaultValue('additionalAddress', state),
    },
  })


  // Submit form => Navigate to the next page
  const onSubmit = handleSubmit(async data => {
    next({
      pathname, navigate, state,
      data: { client: data }
    })
  })


  const onAddressChange = datas => {
    const { address, zipcode, city, country } = datas

    setValue('address', address, true)
    setValue('zipcode', zipcode, true)
    setValue('city', city, true)
    setValue('country', country, true)

    trigger()
  }


  return (
    <>
      <Title className={mainStyle['page-title']} text={<>Bonjour {Capitalize(state.client.firstname)} 👋<br/>{t('freemium:address.title')}</>} />

      <form className={mainStyle.form} onSubmit={onSubmit}>

        <AddressInput
          error={errors.address}
          register={register('address', { required })}
          label={t('common:form.streetAddress.label')+'*'}
          placeholder={t('common:form.streetAddress.placeholder')}
          onChange={onAddressChange}
          countries={['fr']}
        />
        <InputError error={errors.address} />


        <Input
          error={errors.zipcode}
          register={register('zipcode', { required })}
          label={t('common:form.zipCode.label')+'*'}
          placeholder={`${t('common:your')} ${t('common:form.zipCode.label').toLowerCase()}`}
        />
        <InputError error={errors.zipcode} />


        <Input
          error={errors.city}
          register={register('city', { required })}
          label={t('common:form.city.label')+'*'}
          placeholder={t('common:form.city.placeholder')}
          disallowSpecials={true}
          disallowNumbers={true}
        />
        <InputError error={errors.city} />


        <Input
          error={errors.country}
          register={register('country', {
            required,
            validate: value => value.toLowerCase() === "france" || t('common:form.country.error')
          })}
          label={t('common:form.country.label')+'*'}
          placeholder={`${t('common:your')} ${t('common:form.country.label').toLowerCase()}`}
        />
        <InputError error={errors.country} />


        <Input
          register={register('additionalAddress')}
          label={t('common:form.additionalAddress.label')}
          placeholder={t('common:form.additionalAddress.placeholder')}
        />


        <p className={mainStyle.required}>{t('common:form.mandatoryFields')}</p>


        <Button
          className={mainStyle.button}
          label={t('common:next')}
          onClick={handleSubmit}
          type="primary"
          disabled={!isValid}
        />

      </form>
    </>
  )
}

export default AddressForm
