// Modules
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Yousign } from 'helpers/yousign'
import dayjs from 'dayjs'
import classNames from 'classnames'
import api from 'helpers/api'

// Components
import { next } from 'components/Freemium/Nav/Nav'

// Styles
import style from './Signature.module.scss'

const Signature = () => {
  const navigate = useNavigate()
  const { state, pathname } = useLocation()
  const [isSigned, setIsSigned] = useState(false)
  const signatureLink = state?.client?.signatureLink


  const onSignatureSuccess = useCallback(
    async data => {
      try {
        const res = await api.put({
          url: `/adhesions/${state.saveID}/signature/subscription/client`,
          data: {
            adhesionId: state.saveID,
            signature: 1,
            signatureHorodate: dayjs(data.timeStamp).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          },
          headers: { Authorization: `Bearer ${state.token}` },
        })

        if (res?.response) {
          next({
            pathname, navigate, state,
            data: {}
          })
        }
      } catch (error) {
        console.log('Update adhesion status error')
      }
    },
    [navigate, state, pathname],
  )


  useEffect(() => {
    if (signatureLink) {
      const yousign = new Yousign({
        signatureLink: signatureLink,
        iframeContainerId: 'signature-iframe',
        isSandbox: true, //To set if you use the sandbox environment
      })

      !isSigned &&
        yousign.onSuccess(() => {
          setIsSigned(true)
          onSignatureSuccess({ timeStamp: new Date() })
        })

      yousign.onError(() => {
        console.log('Error on signing the document')
      })
    }
  }, [navigate, state, isSigned, onSignatureSuccess, signatureLink])


  return (
    <div
      className={classNames(style['signature-iframe'], !signatureLink && style.inactive)}
      id="signature-iframe"
    ></div>
  )
}

export default Signature
