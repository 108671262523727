// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactSummary_cards__Aouav {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.ContactSummary_info-message__rNOmU a {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
}
.ContactSummary_info-message__rNOmU a:hover {
  text-decoration: none;
}

.ContactSummary_edit-button__jaTrO {
  padding: 0 8px;
}

.ContactSummary_card__uyCwp:not(:last-child) {
  margin-bottom: 12px;
}

.ContactSummary_main-card__CnIIO {
  width: 100%;
}

.ContactSummary_name__543OI {
  font-weight: 500;
  font-size: 28px;
  line-height: 48px;
}

.ContactSummary_secondary-cards__pCi06 {
  width: 100%;
  column-count: 2;
  gap: 12px;
}

.ContactSummary_price__p5ZPv {
  align-items: baseline;
  cursor: initial;
}
.ContactSummary_price__p5ZPv span {
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
}

@media screen and (max-width: 600px) {
  .ContactSummary_edit-button__jaTrO {
    height: 36px;
  }
  .ContactSummary_secondary-cards__pCi06 {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
  }
  .ContactSummary_card__uyCwp:not(:last-child) {
    margin-bottom: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Contacts/ContactSummary/ContactSummary.module.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;AAFF;;AAME;EACE,WAAA;EACA,gBAAA;EACA,eAAA;AAHJ;AAKI;EACE,qBAAA;AAHN;;AAQA;EACE,cAAA;AALF;;AASE;EACE,mBAAA;AANJ;;AAUA;EACE,WAAA;AAPF;;AAUA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAPF;;AAUA;EACE,WAAA;EACA,eAAA;EACA,SAAA;AAPF;;AAUA;EACE,qBAAA;EACA,eAAA;AAPF;AASE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAPJ;;AAWA;EACE;IACE,YAAA;EARF;EAWA;IACE,aAAA;IACA,sBAAA;IACA,gBAAA;EATF;EAaE;IACE,gBAAA;EAXJ;AACF","sourcesContent":["@use 'styles/colors.scss';\n@use 'styles/sizes.scss';\n\n.cards {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 20px;\n}\n\n.info-message {\n  a {\n    width: 100%;\n    font-weight: 500;\n    font-size: 16px;\n\n    &:hover {\n      text-decoration: none;\n    }\n  }\n}\n\n.edit-button {\n  padding: 0 8px;\n}\n\n.card {\n  &:not(:last-child) {\n    margin-bottom: 12px;\n  }\n}\n\n.main-card {\n  width: 100%;\n}\n\n.name {\n  font-weight: 500;\n  font-size: 28px;\n  line-height: 48px;\n}\n\n.secondary-cards {\n  width: 100%;\n  column-count: 2;\n  gap: 12px;\n}\n\n.price {\n  align-items: baseline;\n  cursor: initial;\n\n  span {\n    font-weight: 500;\n    font-size: 28px;\n    line-height: 32px;\n  }\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .edit-button {\n    height: 36px;\n  }\n\n  .secondary-cards {\n    display: flex;\n    flex-direction: column;\n    margin-top: 12px;\n  }\n\n  .card {\n    &:not(:last-child) {\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cards": `ContactSummary_cards__Aouav`,
	"info-message": `ContactSummary_info-message__rNOmU`,
	"edit-button": `ContactSummary_edit-button__jaTrO`,
	"card": `ContactSummary_card__uyCwp`,
	"main-card": `ContactSummary_main-card__CnIIO`,
	"name": `ContactSummary_name__543OI`,
	"secondary-cards": `ContactSummary_secondary-cards__pCi06`,
	"price": `ContactSummary_price__p5ZPv`
};
export default ___CSS_LOADER_EXPORT___;
