import { useTranslation } from 'react-i18next'
import Button from 'components/common/Button/Button'
import Download from 'components/assets/icons/Download'
import PrefixedText from 'components/common/PrefixedText/PrefixedText'
import style from './Export.module.scss'

const Export = () => {
  const { t } = useTranslation()

  const GetCSV = () => {
  }

  return (
    <Button
      className={style.button}
      label={
        <PrefixedText
          prefix={<Download />}
          text={t('reporting:export')}
        />
      }
      onClick={GetCSV()}
    />
  )
}

export default Export
