import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import Collapsible from 'components/common/Collapsible/Collapsible'
import style from './Recap.module.scss'
import Button from 'components/common/Button/Button'
import Link from 'components/common/Link/Link'
import LogoShort from 'components/assets/icons/LogoShort'
import Checkbox from 'components/common/Checkbox/Checkbox'
import CreditCard from 'components/assets/icons/CreditCard'
import CashOut from 'components/assets/icons/CashOut'
import Title from 'components/common/PageTitle/PageTitle'
import { getYearPrice } from 'helpers/contract'
import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'

const Recap = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()

  const [paymentPlan, setPaymentPlan] = useState('month')
  const [errorMessage, setErrorMessage] = useState(null)
  const [isIbanPayment, setIbanPayment] = useState(false)

  useEffect(() => {
    if (!state?.client || !state?.contract || !state?.step) navigate('/sign-in')
  }, [navigate, state])

  const {
    formState: { isValid },
    handleSubmit,
    register,
    setValue,
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    mode: 'onTouched',
    values: {
      areTermsAccepted: false,
      areOffersAccepted: false,
    },
  })

  const onSubmit = handleSubmit(async data => {
    try {
      navigate('../credit-card', {
        state: {
          //accessToken: res.response.accessToken,
          listingData: data,
          client: {...state.client,birthdate: state.client.birthdate},
          contract: { ...state.contract, /*_id: res.response.id,*/ paymentPlan},
          transactionHorodate: new Date(),
          step: 6,
          token: state.token,
          idCampaign: state.idCampaign,
          payByIban: isIbanPayment
        },
      })
      /*const res = await api.post({
        url: '/adhesions/client',
        data: {
          ...data,
          ...state.contract,
          ...state.client,
          payByIban: isIbanPayment,
          frequency: paymentPlan,
          contractType: state.contract.contractType._id,
        },
        headers: { Authorization: `Bearer ${state.token}` },
      })

      if (res?.response) {
        navigate('../credit-card', {
          state: {
            accessToken: res.response.accessToken,
            client: state.client,
            contract: { ...state.contract, _id: res.response.id, paymentPlan },
            step: 6,
            token: state.token,
            payByIban: isIbanPayment,
          },
        })
      } else {
        setErrorMessage('Something went wrong')
      }*/
    } catch (error) {
      setErrorMessage(error.message)
    }
  })

  const onCheckboxChange = (e, key) => {
    setValue(key, e.target.value, true)
  }

  if (!state) return null

  const {
    client: { address, city, country, zipcode },
    contract: { contractType },
  } = state
  const priceType = contractType.priceType.find(elem => elem._id === state.contract.priceType);
  let rate = null;
  let rateType = null;
  if (priceType) {
     rateType = priceType.rateType.find(elem => elem._id === state.contract.rateType);
     if (rateType) rate = rateType.rate
  }   
  const yourHome = contractType.subscriptionPortalDescriptions?.[0]?.yourHome
  const replaceDataIndemnities = (itemName) => {
    return itemName
      .replace(/{{package}}/g, rateType.dataInsurance.package ?? '')
      .replace(/{{refund_limit}}/g, rateType.refundLimit ?? '');
  };
  const TermsLabel = () => (
    <p className={style['checkbox-label']}>
      {`${t('common:iBecameAwareOf')} `}
      <Link
        className={style.link}
        label={t('contract:document.ipid').toLowerCase()}
        target="_blank"
        to={contractType.legalDocuments.ni}
      />
      {` ${t('common:misc.andOfThe')} `}
      <Link
        className={style.link}
        label={t('contract:document.niShort').toLowerCase()}
        target="_blank"
        to={contractType.legalDocuments.ipid}
      />
      {` ${t('common:misc.onThe')} ${t('common:insuranceProduct')}, ${t('common:shareInformations')}`}
    </p>
  )

  const subscriptionPortalDescriptions = contractType.subscriptionPortalDescriptions[0]
  const isMonthly = paymentPlan === 'month'
  const isAnnually = paymentPlan === 'year'

  const TitlePrice = () =>
    isMonthly ? (
      <>
        <span>{rate.toFixed(2).toString().replace('.', ',')}€</span> /{t('common:period.month')}
      </>
    ) : (
      <>
        <span>{getYearPrice(rate.toFixed(2)).toString().replace('.', ',')}€</span> / {t('common:period.year')}
      </>
    )

  const SubTitlePrice = () =>
    isMonthly
      ? t('subscription:recap.perYear', { price: getYearPrice(rate.toFixed(2)).toString().replace('.', ',') })
      : t('subscription:recap.perMonth', { price: rate.toFixed(2).toString().replace('.', ',') })

  return (
    <>
      {errorMessage && <ResponseMessage className={style['response-message']} label={errorMessage} />}
      <Title text={<>{t('subscription:recap.title')} 👌</>} />

      <div className={style.card}>
        <div className={style['card-top']}>
          <div className={style['price-month']}>
            <TitlePrice />
          </div>
          <span className={style['price-year']}>
            <SubTitlePrice />
          </span>
        </div>

        <div className={style['card-content']}>
          <div className={style.switch}>
            <div className={style[`switch-labels`]} style={{ height: 48, width: '100%' }}>
              <span
                className={classNames(style[`switch-label`], isMonthly && style.active)}
                onClick={() => setPaymentPlan('month')}
              >
                {t('subscription:recap.paymentByMonth')}
              </span>
              <span
                className={classNames(style[`switch-label`], isAnnually && style.active)}
                onClick={() => setPaymentPlan('year')}
              >
                {t('subscription:recap.paymentByYear')}
              </span>
            </div>
          </div>

          <div className={style.summary}>
            <div className={style['summary-row']}>
              <h4 className={classNames(style['summary-title'], style.address)}>
                {`🏠 ${t('common:your')} ${t('common:form.address.label').toLowerCase()}`}
                <Link className={style['address-link']} label="Modifier" state={state} to="../address" />
              </h4>
              <p className={style['summary-description']}>{`${address}, ${zipcode} ${city} ${country}`}</p>
            </div>
            {!!yourHome?.length && (
              <div className={style['summary-row']}>
                <h4 className={style['summary-title']}>👩‍👧‍👦 {t('common:yourHome')}</h4>
                {yourHome.map((item, index) => (
                  <p className={style['summary-description']} key={`your-home-${index}`}>
                    {item.name}
                  </p>
                ))}
              </div>
            )}
            <div className={style['summary-row']}>
              <h4 className={style['summary-title']}>
                <LogoShort className={style.logo} /> {contractType.name}
              </h4>
              <p className={style['summary-description']}>Maintien de revenus</p>
            </div>
          </div>
        </div>
      </div>
      <Collapsible
        className={style['collapsible-card']}
        content={
          <div className={style.list}>
            {subscriptionPortalDescriptions.coveredEvents.map((item, index) => (
              <p className={style.item} key={`covered-event-${index}`}>
                {item.name}
              </p>
            ))}
          </div>
        }
        title={<span className={style['list-title']}>⚠️ {t('subscription:start.eventsCovered')}</span>}
      />
      <Collapsible
        className={style['collapsible-card']}
        content={
          <div className={style.list}>
            {subscriptionPortalDescriptions.indemnities.map((item, index) => (
              <p className={style.item} key={`indemnity-${index}`}>
               {replaceDataIndemnities(item.name)}
              </p>
            ))}
          </div>
        }
        title={<span className={style['list-title']}>💸 {t('subscription:start.indemnity')}</span>}
      />

      <form className={style.form} onSubmit={onSubmit}>
        <div className={style.inputs}>
          <div className={style.payment}>
            <h3 className={style['payment-title']}>{t('subscription:recap.paymentMethod.title')}</h3>
            <div className={style['payment-methods']}>
              <div
                className={classNames(style['payment-method'], !isIbanPayment && style.selected)}
                onClick={() => setIbanPayment(false)}
              >
                <CreditCard styles={{ fill: !isIbanPayment ? '#fff' : '#8686FF' }} />
                <p>{t('subscription:recap.paymentMethod.creditCard')}</p>
              </div>
              <div
                className={classNames(style['payment-method'], isIbanPayment && style.selected)}
                onClick={() => setIbanPayment(true)}
              >
                <CashOut styles={{ fill: isIbanPayment ? '#fff' : '#8686FF' }} />
                <p>{t('subscription:recap.paymentMethod.iban')}</p>
              </div>
            </div>
          </div>
          <Checkbox
            className={style.checkbox}
            id="terms"
            label={<TermsLabel />}
            onChange={e => onCheckboxChange(e, 'areTermsAccepted')}
            register={register('areTermsAccepted', { required: true })}
          />
          <Checkbox
            className={style.checkbox}
            id="areOffersAccepted"
            label={<p className={style['checkbox-label']}>{t('subscription:recap.subscriptionCheckbox')}</p>}
            onChange={e => onCheckboxChange(e, 'areOffersAccepted')}
            register={register('areOffersAccepted')}
          />
        </div>

        {rate ? (
          <Button
            className={style.button}
            disabled={!isValid}
            label={t('common:submit')}
            onClick={handleSubmit}
            type="primary"
         />
        ) : (
          <p style={{ color: 'red' }}>Une erreur est survenue. </p>
        )} 
        
      </form>
    </>
  )
}

export default Recap
