import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'
import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import { isInputError } from 'helpers/form'
import style from '../CreditCardForm.module.scss'
import Title from 'components/common/PageTitle/PageTitle'
import api from 'helpers/api'
import { getYearPrice } from 'helpers/contract'
import { isValidIBAN } from 'ibantools'
import Spinner from 'components/common/Spinner/Spinner'

const IbanForm = ({ state, navigate }) => {
  const [errorMessage, setErrorMessage] = useState(false)
  const [isSpinnerLoading, setIsSpinnerLoading] = useState(false)
  const { t } = useTranslation()

  const getDefaultValue = field => {
    return state.client[field] || ''
  }

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    criteriaMode: 'all',
    delayError: 500,
    mode: 'onTouched',
    values: state?.client && {
      owner: getDefaultValue('owner'),
      iban: getDefaultValue('iban'),
      bic: getDefaultValue('bic'),
    },
  })

  const errorFields = Object.keys(errors)

  const onSubmit = handleSubmit(async data => {
    try {
      setIsSpinnerLoading(true);
      const res = await api.post({
        url: '/adhesions/client',
        data: {
          ...state.listingData,
          ...state.contract,
          ...state.client,
          idCampaign: state.idCampaign,
          phone: state.client.phone.number,
          countryCode: state.client.phone.countryCode,
          birthdate: state.client.birthdate,
          transactionHorodate: new Date(),
          paymentType: "direct debit by Owen",
          paymentPlan,
          contractType: state.contract.contractType._id,
          iban: data.iban,
          bic: data.bic,
          frequency: state.contract.paymentPlan
        },
        headers: { Authorization: `Bearer ${state.token}` },
      })

      if (res?.response) {
        if(res?.response.compliance === false){
          navigate('../cannot_continue_the_subscription', {
            state: {
              saveID: res.response.id,
              accessToken: state.accessToken,
              client: state.client,
              contract: state.contract,
              step: 7,
              token: state.token,
              idCampaign: state.idCampaign
            },
          })
        } else {
          navigate('../signature', {
            state: {
              saveID: res.response.id,
              accessToken: state.accessToken,
              client: state.client,
              contract: state.contract,
              step: 7,
              token: state.token,
              idCampaign: state.idCampaign
            },
          })
        }
      }
    } catch (error) {
      console.log('Create contact payment method error')
      setErrorMessage(true)
    } finally {
      setIsSpinnerLoading(false);
    }
  })

  if (!state) return null

  const {
    contract: { contractType, paymentPlan },
  } = state
  const priceType = contractType.priceType.find(elem => elem._id === state.contract.priceType);
  let rate = null;
  if (priceType) {
     const rateType = priceType.rateType.find(elem => elem._id === state.contract.rateType);
     if (rateType) rate = rateType.rate
  }  
  return (
    <>
      {isSpinnerLoading && <Spinner />}
      {errorMessage && <ResponseMessage className={style['response-message']} label={t('common:form.genericError')} />}

      <Title text={<>{t('subscription:iban.title')} 💸</>} />
      <p className={style.subtitle}>{t('subscription:creditCard.subtitle')}</p>

      <form className={style.form} onSubmit={onSubmit}>
        <div className={style.inputs}>
          <div>
            <Input
              className={style.input}
              error={isInputError(errorFields, 'owner')}
              register={register('owner', { required: true, minLength: 2, pattern: /[a-zA-ZÀ-ÿ-]/ })}
              label={t('common:paymentMethod.iban.owner.label')}
              placeholder={t('common:paymentMethod.iban.owner.placeholder')}
              disallowNumbers={true}
            />
            {errorFields.includes('owner') && <p className={style['input-error']}>{t('common:form.incorrect')}</p>}
          </div>
          <div>
            <Input
              className={style.input}
              error={isInputError(errorFields, 'iban')}
              register={register('iban', { required: true, validate: value => isValidIBAN(value) })}
              label={'IBAN'}
              placeholder={t('subscription:iban.number') + ' IBAN'}
            />
            {errorFields.includes('iban') && <p className={style['input-error']}>{t('common:form.incorrect')}</p>}
          </div>
          <div>
            <Input
              className={style.input}
              register={register('bic')}
              label={'BIC'}
              placeholder={t('subscription:iban.number') + ' BIC'}
            />
            {errorFields.includes('bic') && <p className={style['input-error']}>{t('common:form.incorrect')}</p>}
          </div>
        </div>

        
        {rate ? (
          <Button
          className={style.button}
          label={t('subscription:creditCard.button', {
            price: paymentPlan === 'month' ? parseFloat(rate).toFixed(2).replace('.', ',') : getYearPrice(rate).replace('.', ','),
          })}
          onClick={handleSubmit}
          type="primary"
          disabled={!isValid}
        />
        ) : (
          <p style={{ color: 'red' }}>Une erreur est survenue. </p>
        )} 
      </form>
    </>
  )
}
export default IbanForm
