import { useTranslation } from 'react-i18next'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { commonOptions } from 'helpers/chartjs';
import CustomLegend, { htmlLegendPlugin } from '../CustomLegend/CustomLegend';
import NoData from '../NoData/NoData';
import colors from 'components/reporting/styles/colors.scss'
import style from './Doughnut.module.scss'

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({datas, backgroundColor, legendID}) => {
    const { t } = useTranslation()
    const {labels, data} = datas
    if (!data) return
    const noData = !data.length

    // Datas
    const dataObj = {
        labels: !noData
            ? labels.length > process.env.REACT_APP_DOUGHTNUT_MAX_VALUES
                ? [...labels.slice(0, 4), t('reporting:others')]
                : labels
            : [''],
        datasets: [
            {
                data: !noData
                    ? data.length > process.env.REACT_APP_DOUGHTNUT_MAX_VALUES
                        ? data.reduce( (acc, cur, index) => {
                            if (index < 5){
                                return [...acc, cur]
                            } else {
                                acc[4] += cur
                                return acc
                            }
                        },[])
                        : data
                    : [1],
                backgroundColor: noData 
                    ? [colors.grey5]
                    : backgroundColor,
                borderWidth: 0,
            },
        ]
    }

    const updateChart = (chart, index) => {
        if (noData) return
        if (!isNaN(index)) {
            chart.data.datasets[0].backgroundColor = chart.data.datasets[0].backgroundColor.map( (bg, bgIndex) => (
                index === bgIndex ? backgroundColor[index] : colors.grey5
            ))
        } else {
            chart.data.datasets[0].backgroundColor = backgroundColor
        }
    }

    const triggerEvents = {
        id: 'eventPlugin',
        afterEvent(chart, args, opts) {
          let event = args.event.type
          if (event === 'mouseout') {
            updateChart(chart)
            chart.update()
          }
        }
    }

    // Options
    const { plugins } = commonOptions(legendID)
    const options = {
        onResize: (chart, size) => {
            chart.resize(300, 150);
        },
        onHover: (event, hovered, chart) => {
            if (!hovered.length){
                updateChart(chart)
            } else {
                let index = hovered[0].element.$context.dataIndex
                updateChart(chart, index)
            }
            chart.update();
        },
        cutout: '60%',
        layout:{
            padding: {
                top: 15,
                bottom: 15
            }
        },
        plugins
    }


    // Legend
    const legend = htmlLegendPlugin({
        data: dataObj.datasets[0].data,
        percent: true,
        backgroundColor
    })

    return (
        <>
            <div className={style.container}>
                {noData && <NoData />}
                <Doughnut
                    data={dataObj}
                    options={options}
                    plugins={[legend, triggerEvents]}
                />
            </div>
            <CustomLegend id={legendID} />
        </>
    )
}

export default DoughnutChart