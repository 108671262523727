// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddressInput_addressContainer__cXoVV {
  position: relative;
  width: 100%;
  height: 68px;
}
.AddressInput_addressContainer__cXoVV ul {
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  width: inherit;
  border-radius: 8px;
  background: #FFF;
  margin: 0;
  padding: 0;
  border: 1px solid #CCC;
  font-size: 14px;
  font-weight: 300;
}
.AddressInput_addressContainer__cXoVV ul li {
  display: block;
  list-style: none;
  padding: 12px;
  border-bottom: 1px solid #CCC;
  cursor: pointer;
}
.AddressInput_addressContainer__cXoVV ul li:last-child {
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/common/AddressInput/AddressInput.module.scss"],"names":[],"mappings":"AAIA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AAHF;AAKE;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,OAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,SAAA;EACA,UAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;AAHJ;AAKI;EACE,cAAA;EACA,gBAAA;EACA,aAAA;EACA,6BAAA;EACA,eAAA;AAHN;AAKM;EACE,YAAA;AAHR","sourcesContent":["@use 'styles/colors.scss';\n@use 'styles/sizes.scss';\n\n\n.addressContainer{\n  position: relative;\n  width: 100%;\n  height: 68px;\n\n  ul {\n    position: absolute;\n    z-index: 1;\n    top: 100%;\n    left: 0;\n    width: inherit;\n    border-radius: 8px;\n    background: #FFF;\n    margin: 0;\n    padding: 0;\n    border: 1px solid #CCC;\n    font-size: 14px;\n    font-weight: 300;\n\n    li{\n      display: block;\n      list-style: none;\n      padding: 12px;\n      border-bottom: 1px solid #CCC;\n      cursor: pointer;\n\n      &:last-child{\n        border: none;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addressContainer": `AddressInput_addressContainer__cXoVV`
};
export default ___CSS_LOADER_EXPORT___;
