// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Radio_fieldset__dzrSO {
  width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
}

.Radio_label__WpAYC {
  font-size: 16px;
  font-weight: 300;
  color: #00007a;
  display: inline-block;
  position: relative;
}
.Radio_label__WpAYC:not(:last-of-type) {
  margin-right: 30px;
}
.Radio_label__WpAYC:before {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #FFF;
  border: 2px solid #1414ff;
  content: "";
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}
.Radio_label__WpAYC:has(input:checked):after {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #1414ff;
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  content: "";
}
.Radio_label__WpAYC input {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Radio/Radio.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,SAAA;EACA,SAAA;EACA,UAAA;AAFF;;AAKA;EACE,eAAA;EACA,gBAAA;EACA,cCNS;EDOT,qBAAA;EACA,kBAAA;AAFF;AAIE;EACE,kBAAA;AAFJ;AAKE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,yBAAA;EACA,WAAA;EACA,qBAAA;EACA,kBAAA;EACA,mBAAA;AAHJ;AAME;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBCjCO;EDkCP,cAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;AAJJ;AAOE;EACE,aAAA;AALJ","sourcesContent":["@use 'styles/colors';\n\n\n.fieldset{\n  width: 100%;\n  border: 0;\n  margin: 0;\n  padding: 0;\n}\n\n.label{\n  font-size: 16px;\n  font-weight: 300;\n  color: colors.$primary8;\n  display: inline-block;\n  position: relative;\n\n  &:not(:last-of-type){\n    margin-right: 30px;\n  }\n\n  &:before{\n    width: 15px;\n    height: 15px;\n    border-radius: 50%;\n    background-color: #FFF;\n    border: 2px solid colors.$primary5;\n    content: \"\";\n    display: inline-block;\n    margin-right: 10px;\n    vertical-align: top;\n  }\n\n  &:has(input:checked):after{\n    width: 11px;\n    height: 11px;\n    border-radius: 50%;\n    background-color: colors.$primary5;\n    display: block;\n    position: absolute;\n    top: 4px;\n    left: 4px;\n    content: \"\";\n  }\n\n  input{\n    display: none;\n  }\n}","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldset": `Radio_fieldset__dzrSO`,
	"label": `Radio_label__WpAYC`
};
export default ___CSS_LOADER_EXPORT___;
