// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainForm_title__ImC\\+4 {
  margin-bottom: 28px;
  font-weight: 400;
  font-size: 28px;
  line-height: 48px;
}

.MainForm_form__w8Vl3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.MainForm_inputs__foW\\+u {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-bottom: 70px;
}

.MainForm_input-error__Tuh3j {
  margin-top: 4px;
  padding: 0 8px;
  color: #b94402;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.MainForm_input-error__Tuh3j .MainForm_input-error-prefix__H\\+qnz {
  margin: 0;
  padding: 0 20px;
}

.MainForm_button__4dCi1 {
  width: 200px;
}

@media screen and (max-width: 600px) {
  .MainForm_inputs__foW\\+u {
    margin-bottom: 32px;
  }
  .MainForm_button__4dCi1 {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Subscription/MainForm/MainForm.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACE,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,WAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,WAAA;EACA,mBAAA;AAFF;;AAKA;EACE,eAAA;EACA,cAAA;EACA,cCJW;EDKX,gBAAA;EACA,eAAA;EACA,iBAAA;AAFF;AAIE;EACE,SAAA;EACA,eAAA;AAFJ;;AAMA;EACE,YAAA;AAHF;;AAMA;EACE;IACE,mBAAA;EAHF;EAMA;IACE,WAAA;EAJF;AACF","sourcesContent":["@use 'styles/colors.scss';\n@use 'styles/sizes.scss';\n\n.title {\n  margin-bottom: 28px;\n  font-weight: 400;\n  font-size: 28px;\n  line-height: 48px;\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n}\n\n.inputs {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  width: 100%;\n  margin-bottom: 70px;\n}\n\n.input-error {\n  margin-top: 4px;\n  padding: 0 8px;\n  color: colors.$attention7;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n\n  .input-error-prefix {\n    margin: 0;\n    padding: 0 20px;\n  }\n}\n\n.button {\n  width: 200px;\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .inputs {\n    margin-bottom: 32px;\n  }\n\n  .button {\n    width: 100%;\n  }\n}\n","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `MainForm_title__ImC+4`,
	"form": `MainForm_form__w8Vl3`,
	"inputs": `MainForm_inputs__foW+u`,
	"input-error": `MainForm_input-error__Tuh3j`,
	"input-error-prefix": `MainForm_input-error-prefix__H+qnz`,
	"button": `MainForm_button__4dCi1`
};
export default ___CSS_LOADER_EXPORT___;
