import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Button from 'components/common/Button/Button'
import Link from 'components/common/Link/Link'
import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'
import Header from 'components/Header/Header'
import api from 'helpers/api'
import style from 'styles/auth.module.scss'
import Code from 'components/common/Code/Code'
import { parseToken } from 'helpers/auth'

import ExclamationTriangle from 'components/assets/icons/ExclamationTriangle'
import PrefixedText from 'components/common/PrefixedText/PrefixedText'

const SignUpConfirm = () => {
  const [message, setMessage] = useState('')
  const [codeError, setCodeError] = useState('')
  const [timeLeft, setTimeleft] = useState('')

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const accessToken = localStorage.getItem('accessToken')
  const parsedToken = parseToken(accessToken)
  const token = searchParams.get('token')
  const tokenObj = parseToken(token)
  const id = tokenObj?.id
  const codeLength = Number(process.env.REACT_APP_CODE_SECURITY)


  // Validity timer
  const secondsLeft = Math.ceil((tokenObj?.exp * 1000 - Date.now()) / 1000)

  useEffect(() => {
    const timer = setInterval(() => {
      const min = Math.trunc(secondsLeft / 60)
      const sec = ("0"+Number(secondsLeft - (60 * min))).slice(-2)
      setTimeleft((min > 0 ? min+"min" : '')+sec+(min === 0 ? 's' : ''))
    }, 1000);
    return () => clearInterval(timer);
  }, [timeLeft, secondsLeft])


  // Errors
  const backError = t('main:errorMessages.general')
  const waitError = t('main:auth.confirm.shouldWait')
  const checkMail = t('main:auth.confirm.checkMail')
  const wrongCode = t('main:auth.confirm.wrongCode')
  const expired = t('main:auth.confirm.expired')
  const tooManyAttempts = t('main:auth.confirm.tooManyAttempts')
  const tooManyRequests = t('main:auth.confirm.tooManyRequests')


  // Define form
  const {
    register,
    handleSubmit,
    formState: { isValid },
    watch
  } = useForm({
    criteriaMode: 'all',
    delayError: 0,
    defaultValues: {
      code: ''
    },
    mode: 'all',
  })
  const code = watch('code')


  const onSubmit = handleSubmit(async data => {
    const checkCode = async () => {
      try {
        const res = await api.post({
          url: '/auth/clients/confirm',
          data: { ...data, id },
          headers: { Authorization: `Bearer ${token}` }
        })
        
        if (res.success && res.response.accessToken){
          localStorage.setItem('accessToken', res.response.accessToken)
          navigate('/contracts')
        } else {
          setMessage(backError)
        }
      } catch (error) {
        const errorMsg = error.toString().split(':')[1]?.trim()

        if (errorMsg === "Code expired"){
          setCodeError(expired)
        } else if (errorMsg === "Wrong code"){
          setCodeError(wrongCode)
        } else if (errorMsg === "Too many attempts") {
          setCodeError(tooManyAttempts)
          return
        } else if (errorMsg === "Too many requests") {
          setCodeError(tooManyRequests)
          return
        } else {
          setMessage(backError)
        }
      }
      clearMessages()
    }

    checkCode()
  })


  const getNewCode = async () => {
    setCodeError('')

    try {
      const res = await api.post({
        url: '/auth/clients/reset_confirm_code',
        data: { id },
        headers: { Authorization: `Bearer ${token}` }
      })

      if (res.success){
        setMessage(checkMail)
      } else {
        setMessage(backError)
      }
    } catch (error) {
      const errorMsg = error.toString().split(':')[1]?.trim()

      if (errorMsg.startsWith('Wait')){
        setMessage(waitError)
      } else if (errorMsg === "Too many requests") {
        setCodeError(tooManyRequests)
        return
      } else {
        setMessage(backError)
      }
    }
    clearMessages()
  }


  const getNewMail = async () => {
    setCodeError('')

    try {
      const res = await api.post({
        url: '/auth/clients/resend_confirm_mail',
        data: { id },
        headers: { Authorization: `Bearer ${token}` }
      })

      if (res.success){
        setMessage(checkMail)
      } else {
        setMessage(backError)
      }
    } catch (error) {
      const errorMsg = error.toString().split(':')[1]?.trim()

      if (errorMsg.startsWith('Wait')){
        setMessage(waitError)
      } else if (errorMsg === "Too many requests") {
        setCodeError(tooManyRequests)
        return
      } else {
        setMessage(backError)
      }
    }
    clearMessages()
  }


  const clearMessages = () => {
    setTimeout( () => {
      setCodeError('')
      setMessage('')
    }, 5000)
  }


  return accessToken && new Date(parsedToken.exp) > Date.now()
  ? <Navigate to='/contracts' replace />
  : !id || secondsLeft < 1
  ? <Navigate to="/sign-up" replace />
  : (
    <div className={style.auth}>
      <Header darkBg styles={{ position: 'absolute', top: 0 }} />
      <h2 className={style.title}>{t('main:auth.signUp.title')}</h2>

      {message &&
        <ResponseMessage
          type={message === checkMail ? 'success' : ''}
          label={message}
        />
      }

      <form onSubmit={onSubmit}>
        
        <p className={style.text}>
          {t('main:auth.confirm.code')}
          {timeLeft && ` ${t('main:auth.confirm.validity', {timeLeft})}`}
        </p>

        <Code
          id='code'
          label={t('main:auth.confirm.codeLabel')}
          length={codeLength}
          value={code}
          register={register('code', {
            required: true,
            minLength: codeLength
          })}
          darkBg
        />

        {codeError &&
          <div className={style['input-error']}>
            <PrefixedText
              text={<>
                {codeError}
                {[expired, tooManyAttempts].includes(codeError) && <>
                  <br/><Link
                    className={style.link}
                    label={t('main:auth.confirm.getNewCode')}
                    to={'#'}
                    onClick={getNewCode}
                    darkBg
                  />
                </>}
              </>}
              prefix={<ExclamationTriangle />}
            />
          </div>
        }

        <Button
          type='primary'
          label={t('main:auth.signUp.register')}
          onClick={handleSubmit}
          disabled={!isValid || [tooManyAttempts, tooManyRequests].includes(codeError)} 
        />

        <Link
          className={style.link}
          label={t('main:auth.confirm.getNewMail')}
          to={'#'}
          onClick={getNewMail}
          darkBg
        />

      </form>
    </div>
  )
}

export default SignUpConfirm
