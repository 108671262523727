import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import Home from 'components/assets/icons/Home'
import MagicStick from 'components/assets/icons/MagicStick'
import CloudBolt from 'components/assets/icons/CloudBolt'
import ChatLine2 from 'components/assets/icons/ChatLine2'
// import Help from 'assets/Help'
// import Info from 'assets/Info'
// import Document from 'assets/Document'
// import Widget from 'assets/Widget'
import SignOutIcon from 'components/assets/icons/SignOutIcon'
import Link from 'components/common/Link/Link'
import style from './Menu.module.scss'

const Menu = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()


  const signOut = () => {
    localStorage.removeItem('reportToken')
    navigate('sign-in')
  }

  const handleNavMenuClick = path => {
    navigate(path)
  }

  const path = '/reporting'

  const navItems = useMemo(
    () => [
      {
        title: t('reporting:menu.titles.data'),
        items: [
          {
            icon: <Home />,
            title: t('reporting:menu.overview'),
            path: '/overview'
          },
          {
            icon: <MagicStick />,
            title: t('reporting:menu.production'),
            path: '/production'
          },
          {
            icon: <CloudBolt />,
            title: t('reporting:menu.claims'),
            path: '/claims'
          }
        ]
      },
      {
        title: t('reporting:menu.titles.help'),
        items: [
          {
            icon: <ChatLine2 />,
            title: t('reporting:menu.contact'),
            path: '/contact'
          },
          // {
          //   icon: <Help />,
          //   title: t('reporting:menu.support'),
          //   path: '/support'
          // },
          // {
          //   icon: <Info />,
          //   title: t('reporting:menu.information'),
          //   path: '/information'
          // }
        ]
      },
      // {
      //   title: t('reporting:menu.titles.more'),
      //   items: [
      //       {
      //           icon: <Document />,
      //           title: t('reporting:menu.ressources'),
      //           path: '/ressources'
      //         },
      //         {
      //           icon: <Widget />,
      //           title: t('reporting:menu.products'),
      //           path: '/products'
      //         }
      //   ]
      // }
    ],
    [t],
  )

  return (
    <nav className={style.menu}>
      <ul>
        {navItems.map((section, index) => (
          <li key={`menu-${index}`} className={style.section}>
            <legend>{section.title}</legend>
            <ul>
              {section.items.map((item, itemId) => (
                <li key={`item-${index}-${itemId}`} className={style.item}>
                  <Link
                    className={classNames(style.link, pathname.startsWith(path+item.path) && style.active)}
                    label={<>
                      {item.icon}
                      {item.title}
                    </>}
                    onClick={() => handleNavMenuClick(path+item.path)}
                  />
                </li>
              ))}
            </ul>
          </li>
        ))}

        <li className={classNames(style.item, style.logout)}>
          <Link
            className={style.link}
            label={<>
              <SignOutIcon />
              {t('common:menu.logout')}
            </>}
            onClick={signOut}
          />
        </li>
      </ul>
    </nav>
  )
}

export default Menu
