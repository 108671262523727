// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressBar_progress__GzypO {
  display: flex;
  width: 100%;
  height: 8px;
  margin-bottom: 60px;
}

.ProgressBar_step__iWZBT {
  height: 100%;
  background: #fff;
}
.ProgressBar_step__iWZBT:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.ProgressBar_step__iWZBT:last-child, .ProgressBar_step__iWZBT.ProgressBar_current__q30vV {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ProgressBar_step__iWZBT.ProgressBar_done__S46Zv {
  background: #8686ff;
}

.ProgressBar_dashed__cheoa {
  gap: 12px;
  margin-bottom: 32px;
}
.ProgressBar_dashed__cheoa .ProgressBar_step__iWZBT {
  border-radius: 8px;
  background: #e0e0e3;
}
.ProgressBar_dashed__cheoa .ProgressBar_step__iWZBT.ProgressBar_done__S46Zv {
  background: #1414ff;
}`, "",{"version":3,"sources":["webpack://./src/components/common/ProgressBar/ProgressBar.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,WAAA;EACA,WAAA;EACA,mBAAA;AAFF;;AAKA;EACE,YAAA;EACA,gBAAA;AAFF;AAIE;EACE,2BAAA;EACA,8BAAA;AAFJ;AAKE;EAEE,4BAAA;EACA,+BAAA;AAJJ;AAOE;EACE,mBCxBO;ADmBX;;AASA;EACE,SAAA;EACA,mBAAA;AANF;AAQE;EACE,kBAAA;EACA,mBCtBO;ADgBX;AAQI;EACE,mBCnCK;AD6BX","sourcesContent":["@use 'styles/sizes.scss';\n@use 'styles/colors.scss';\n\n.progress {\n  display: flex;\n  width: 100%;\n  height: 8px;\n  margin-bottom: 60px;\n}\n\n.step {\n  height: 100%;\n  background: #fff;\n\n  &:first-child {\n    border-top-left-radius: 8px;\n    border-bottom-left-radius: 8px;\n  }\n\n  &:last-child,\n  &.current {\n    border-top-right-radius: 8px;\n    border-bottom-right-radius: 8px;\n  }\n\n  &.done {\n    background: colors.$primary3;\n  }\n}\n\n.dashed {\n  gap: 12px;\n  margin-bottom: 32px;\n\n  .step {\n    border-radius: 8px;\n    background: colors.$neutral4;\n\n    &.done {\n      background: colors.$primary5;\n    }\n  }\n}\n","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress": `ProgressBar_progress__GzypO`,
	"step": `ProgressBar_step__iWZBT`,
	"current": `ProgressBar_current__q30vV`,
	"done": `ProgressBar_done__S46Zv`,
	"dashed": `ProgressBar_dashed__cheoa`
};
export default ___CSS_LOADER_EXPORT___;
