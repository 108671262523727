// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Select_input-wrapper__n8R0V {
  break-inside: avoid-column;
}
.Select_input-wrapper__n8R0V .Select_select__KxRfo {
  position: relative;
}

@media screen and (max-width: 600px) {
  .Select_select__KxRfo {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/Select/Select.module.scss"],"names":[],"mappings":"AAGA;EACE,0BAAA;AAFF;AAIE;EACE,kBAAA;AAFJ;;AAMA;EACE;IACE,WAAA;EAHF;AACF","sourcesContent":["@use 'styles/colors';\n@use 'styles/sizes';\n\n.input-wrapper {\n  break-inside: avoid-column;\n\n  .select {\n    position: relative;\n  }\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .select {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-wrapper": `Select_input-wrapper__n8R0V`,
	"select": `Select_select__KxRfo`
};
export default ___CSS_LOADER_EXPORT___;
