// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Collapsible_collapsible__VJjB3 {
  padding: 16px;
  border-radius: 12px;
  background: #f5f5ff;
  color: #00007a;
}

.Collapsible_top__I6y9W {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Collapsible_title__bU6xM {
  display: flex;
  align-items: center;
  padding-right: 36px;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #1414ff;
  width: calc(100% - 90px);
}

.Collapsible_arrow__2\\+Ys3 {
  position: absolute;
  right: 0;
  height: 20px;
}
.Collapsible_arrow__2\\+Ys3.Collapsible_up__u7-Fm {
  transform: rotate(180deg);
}

.Collapsible_content__hEXSZ {
  display: none;
}
.Collapsible_content__hEXSZ.Collapsible_opened__8yS4L {
  display: block;
  margin-top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Collapsible/Collapsible.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,mBAAA;EACA,mBCNS;EDOT,cAAA;AAFF;;AAKA;EACE,kBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cCpBS;EDqBT,wBAAA;AAFF;;AAKA;EACE,kBAAA;EACA,QAAA;EACA,YAAA;AAFF;AAIE;EACE,yBAAA;AAFJ;;AAMA;EACE,aAAA;AAHF;AAKE;EACE,cAAA;EACA,gBAAA;AAHJ","sourcesContent":["@use 'styles/colors.scss';\n@use 'styles/sizes.scss';\n\n.collapsible {\n  padding: 16px;\n  border-radius: 12px;\n  background: colors.$primary1;\n  color: colors.$primary8;\n}\n\n.top {\n  position: relative;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n\n.title {\n  display: flex;\n  align-items: center;\n  padding-right: 36px;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 28px;\n  color: colors.$primary5;\n  width: calc(100% - 90px);\n}\n\n.arrow {\n  position: absolute;\n  right: 0;\n  height: 20px;\n\n  &.up {\n    transform: rotate(180deg);\n  }\n}\n\n.content {\n  display: none;\n\n  &.opened {\n    display: block;\n    margin-top: 12px;\n  }\n}\n","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapsible": `Collapsible_collapsible__VJjB3`,
	"top": `Collapsible_top__I6y9W`,
	"title": `Collapsible_title__bU6xM`,
	"arrow": `Collapsible_arrow__2+Ys3`,
	"up": `Collapsible_up__u7-Fm`,
	"content": `Collapsible_content__hEXSZ`,
	"opened": `Collapsible_opened__8yS4L`
};
export default ___CSS_LOADER_EXPORT___;
