import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useNavigate, Navigate, useSearchParams } from 'react-router-dom'
import classNames from 'classnames'
import IconWrapper from 'components/common/IconWrapper/IconWrapper'
import MainLogo from 'components/assets/icons/MainLogo'
import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'
import Input from 'components/common/Input/Input'
import InputError from 'components/common/InputError/InputError'
import EyeOpen from 'components/assets/icons/EyeOpen'
import EyeClosed from 'components/assets/icons/EyeClosed'
import Button from 'components/common/Button/Button'
import { passwordValidation, confirmPasswordValidation } from 'constants/inputValidation'
import api from 'helpers/api'
import style from '../Auth.module.scss'
import commonStyle from '../../../Wrapper/wrapper.module.scss'

const PasswordReset = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [errorMessage, setErrorMessage] = useState(null)
  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const [isConfirmShown, setIsConfirmShown] = useState(false)
  const token = searchParams.get('token')

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    getValues
  } = useForm({
    criteriaMode: 'all',
    mode: 'onTouched',
    delayError: 0,
    values: {
      password: '',
      passwordConfirmation: '',
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    setErrorMessage(false)
    const res = await api.put({
      url: '/auth/reporting/reset_password',
      data,
      headers: { Authorization: `Bearer ${token}` },
    })

    if (res?.success) {
      navigate('/reporting/signin')
    } else {
      setErrorMessage(t('main:errorMessages.general'))
    }
  })

  return !token
  ? <Navigate to="/reporting/signin" replace />
  : (
    <section className={style.content}>
      <IconWrapper icon={MainLogo} className={style.logo} styles={{ fill: '#000093' }} text='Logo' />
      <h1 className={style.title}>{t('reporting:passwordReset.title')}</h1>

      {errorMessage &&
        <ResponseMessage
          className={style.message}
          label={errorMessage}
        />
      }

      <form onSubmit={onSubmit}>
      <Input
          register={register('password', passwordValidation(t))}
          label={t('reporting:passwordReset.new')}
          type={isPasswordShown ? 'text' : 'password'}
          className={commonStyle.input}
          error={errors.password}
          suffix={
            isPasswordShown ? (
              <EyeClosed className={classNames(style.eyeClosed, 'clickable')} />
            ) : (
              <EyeOpen className={classNames(style.eyeOpen, 'clickable')} />
            )
          }
          onSuffixClick={() => setIsPasswordShown(!isPasswordShown)}
        />
        {errors.password &&
          <div className={style.error}>
            <p>{t('main:auth.password.required')}</p>
            {Object.values(errors.password.types).map( (error, id) => (
              <span key={'err'+id}>
                {error}
              </span>
            ))}
          </div>
        }

        <Input
          register={register('passwordConfirmation', confirmPasswordValidation(t, getValues('password')))}
          label={t('reporting:passwordReset.confirm')}
          type={isConfirmShown ? 'text' : 'password'}
          className={commonStyle.input}
          error={errors.passwordConfirmation}
          suffix={
            isConfirmShown ? (
              <EyeClosed className={classNames(style.eyeClosed, 'clickable')} />
            ) : (
              <EyeOpen className={classNames(style.eyeOpen, 'clickable')} />
            )
          }
          onSuffixClick={() => setIsConfirmShown(!isConfirmShown)}
        />
        {errors.passwordConfirmation && <InputError error={errors.confirm} />}

        <Button
          className={style.button}
          disabled={!isValid}
          label={t('reporting:passwordReset.button')}
          onClick={handleSubmit}
        />
      </form>
    </section>
  )
};

export default PasswordReset
