import { Outlet, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import style from './Subscription.module.scss'
import ProgressBar from 'components/common/ProgressBar/ProgressBar'
import Back from 'components/common/Back/Back'
import Header from 'components/Header/Header'
import ContentWrapper from 'components/common/ContentWrapper/ContentWrapper'
import Collapsible from 'components/common/Collapsible/Collapsible'
import { LEGAL_NOTICES } from 'constants/contract'
import { useEffect, useState } from 'react'
import { isMobile } from 'helpers/styles'

const Subscription = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const [areNoticesOpened, setAreNoticesOpened] = useState(false)
  const [isContractCreated, setIsContractCreated] = useState(false)

  const navSteps = ['', 'main', 'contacts', 'address', 'expenses', 'recap', 'credit-card', 'signature', 'success']

  const step = pathname.includes('main')
    ? 1
    : pathname.includes('contacts')
    ? 2
    : pathname.includes('address')
    ? 3
    : pathname.includes('expenses')
    ? 4
    : pathname.includes('recap')
    ? 5
    : pathname.includes('credit-card')
    ? 6
    : pathname.includes('signature')
    ? 7
    : pathname.includes('success')
    ? 8
    : 0

  useEffect(() => {
    if (pathname.includes('credit-card')) setIsContractCreated(false)
  }, [pathname])

  const toggleNotices = () => {
    setAreNoticesOpened(!areNoticesOpened)
    isMobile() && window.scrollTo(0, 0)
  }

  const renderContent = () => {
    return <div dangerouslySetInnerHTML={{ __html: LEGAL_NOTICES }} />;
  };

  const LegalNotices = () => {

    const shouldComponentInvisible = !pathname.includes('signature');

    if (!shouldComponentInvisible) {
      return null;
    }
    
    return (
      <div className={classNames(style.notices, areNoticesOpened && style['notices-opened'])}>
        <Collapsible
          className={style.collapsible}
          content={renderContent()}
          isArrowReversed
          isOpenedProp={areNoticesOpened}
          onToggle={toggleNotices}
          title={<span className={style['notices-title']}>{t('common:seeLegalNotices')}</span>}
        />
      </div>
    )
  }

  const isSuccessStep = pathname.includes('success')

  const previousStepName = navSteps[step - 1]
  const mainPath = pathname.split('/').slice(0, 3)
  const fromLink = step === 1 ? mainPath.join('/') : mainPath.concat(previousStepName).join('/')

  return (
    <div className={classNames(style.subscription, isSuccessStep && style['justify-center'])}>
      <Header className={style.header} lightBg />
      {!isSuccessStep && step !== 0 && !isContractCreated && <Back className={style.back} from={fromLink} />}
      {!isSuccessStep && <ProgressBar className={style.progress} stepNumber={8} step={step} />}
      <ContentWrapper className={pathname.includes('signature') && 'signature-wrapper'}>
        <Outlet />
      </ContentWrapper>
      <LegalNotices />
    </div>
  )
}

export default Subscription
