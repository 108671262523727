// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Textarea_textarea__injar {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Textarea_textarea__injar textarea {
  width: 100%;
  height: 100%;
  padding: 6px 12px;
  border: 1px solid #00007a;
  border-radius: 8px;
  resize: none;
  outline: none;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  color: #00007a;
}
.Textarea_textarea__injar textarea::placeholder {
  color: #d8d8ff;
}
.Textarea_textarea__injar.Textarea_error__cdAXG textarea {
  border: 1px solid #bd454a;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Textarea/Textarea.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AADF;AAGE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cCXO;ADUX;AAGI;EACE,cCpBK;ADmBX;AAMI;EACE,yBAAA;AAJN","sourcesContent":["@use 'styles/colors';\n\n.textarea {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n\n  textarea {\n    width: 100%;\n    height: 100%;\n    padding: 6px 12px;\n    border: 1px solid colors.$primary8;\n    border-radius: 8px;\n    resize: none;\n    outline: none;\n    font-weight: 300;\n    font-size: 16px;\n    line-height: 28px;\n    color: colors.$primary8;\n\n    &::placeholder {\n      color: colors.$primary2;\n    }\n  }\n\n  &.error {\n    textarea {\n      border: 1px solid colors.$danger5;\n    }\n  }\n}\n","$primary1: #f5f5ff;\n$primary2: #d8d8ff;\n$primary3: #8686ff;\n$primary4: #4343ff;\n$primary5: #1414ff;\n$primary6: #0000c9;\n$primary7: #000093;\n$primary8: #00007a;\n$primary9: #000054;\n$primary10: #00003a;\n$primary11: #000078;\n\n$neutral1: #fcfcfd;\n$neutral2: #f9f9fa;\n$neutral4: #e0e0e3;\n$neutral8: #838390;\n\n$success1: #f4fced;\n$success5: #58b441;\n$success6: #126f0e;\n\n$attention1: #fef9f0;\n$attention3: #fbd298;\n$attention5: #e78d3d;\n$attention7: #b94402;\n\n$danger1: #fdf1ed;\n$danger2: #f9d9cf;\n$danger3: #f4ada1;\n$danger5: #bd454a;\n$danger6: #911727;\n$danger7: #7c1028;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": `Textarea_textarea__injar`,
	"error": `Textarea_error__cdAXG`
};
export default ___CSS_LOADER_EXPORT___;
