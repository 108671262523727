import { useTranslation } from 'react-i18next'

import style from './Unsuccess.module.scss'

const UnsuccessPage = () => {
  const { t } = useTranslation()

  return (
    <div className={style.success}>
      <p className={style.subtitle}>{t('subscription:unsuccess.subtitle')}</p>
    </div>
  )
}

export default UnsuccessPage
