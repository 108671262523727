import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import Button from 'components/common/Button/Button'
import style from './Expenses.module.scss'
import Title from 'components/common/PageTitle/PageTitle'
import RangeInput from 'components/common/RangeInput/RangeInput'
import { useState,useEffect } from 'react'

import api from 'helpers/api'


const Expenses = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const navigate = useNavigate()
  const [monthlyRate, setMonthlyRate] = useState(400)
  const [refundLimit, setRefundLimit] = useState()

  useEffect(() => {
    const fetchApiRefundLimit = async () => {
      try {

        const res = await api.get({
          url: `/contract-type/${state.contract.contractType._id}/price-type/${state.contract.priceType}/rateType/${state.contract.rateType}/refund-limit`,
          headers: { Authorization: `Bearer ${state.token}` },
        })
        setRefundLimit(res)
        setMonthlyRate(res.defaultPrice)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchApiRefundLimit();
  }, [setRefundLimit, state]);

  const handleSubmit = async (e) => {
    e.preventDefault()

    const res = await api.get({
      url: `/contract-type/${state.contract.contractType._id}/price-type/${state.contract.priceType}/formula?value=${monthlyRate}`,
      headers: { Authorization: `Bearer ${state.token}` },
    })

    if (res) {
      navigate('../recap', {
        state: {
          accessToken: state.accessToken,
          client: state.client,
          contract: {...state.contract,rateType: res._id},
          step: 5,
          token: state.token,
          idCampaign: state.idCampaign,
          monthlyRate,
        },
      })
    }
  }

  if (!state) return null

  const minsum = refundLimit && refundLimit.refundLimitMin
  const maxsum = refundLimit && refundLimit.refundLimitMax

  return (
    <>
      <Title text={<>{t('subscription:expenses.title')} 💰</>} />
      <p className={style.subtitle}>{t('subscription:expenses.subtitle')}</p>
      <form className={style.form}>
        <div className={style.inputs}>
          <div className={style['half-width-inputs']}>
            <RangeInput
              min={minsum}
              max={maxsum}
              minLabel={`${minsum}€`}
              maxLabel={`${maxsum}€ ${t('common:misc.and')} +`}
              value={monthlyRate}
              onChange={e => {
                setMonthlyRate(e.target.value)
              }}
            />
          </div>
          <div className={style.container}>
            <p className={style.pricetag}>
              {t('subscription:expenses.approx')} <span className={style.numbers}>{monthlyRate }€</span>/
              {t('common:period.month')}
            </p>
          </div>
        </div>
        <Button className={style.button} label={t('common:next')} onClick={handleSubmit} type="primary" />
      </form>
    </>
  )
}

export default Expenses
