// Modules
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

// Components
import Collapsible from 'components/common/Collapsible/Collapsible'
import Button from 'components/common/Button/Button'
import Link from 'components/common/Link/Link'
import LogoShort from 'components/assets/icons/LogoShort'
import CreditCard from 'components/assets/icons/CreditCard'
import CashOut from 'components/assets/icons/CashOut'
import Title from 'components/common/PageTitle/PageTitle'
import { next } from 'components/Freemium/Nav/Nav'
import { Capitalize } from 'functions'
import Checkbox from 'components/common/Checkbox/Checkbox'

// Styles
import style from './Summary.module.scss'
import mainStyle from '../FreemiumComponents.module.scss'

const Summary = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state, pathname } = useLocation()

  const [isIbanPayment, setIbanPayment] = useState(false)
  const [areTermsAccepted, setAreTermsAccepted] = useState(false)
  const [areDocsAccepted, setAreDocsAccepted] = useState(false)


  const onSubmit = () => {
    next({
      pathname, navigate, state,
      data: {
        isIbanPayment,
        transactionHorodate: new Date(),
        contract: { paymentPlan: 'month' },
        listingData: { areTermsAccepted, areDocsAccepted }
      },
      skip: !state.client.paidOption && 1
    })
  }


  if (!state) return null

  const { ipid, ni } = state.contract.contractType[0].legalDocuments

  const DocsLabel = () => (
    <p className={style['checkbox-label']}>
      {`${t('freemium:signature.checkboxText')} `}
      <Link
        className={style.link}
        label={t('freemium:signature.insuranceInformation').toLowerCase()}
        target="_blank"
        to={ni}
      />
      {` ${t('freemium:signature.and')} `}
      <Link
        className={style.link}
        label={t('freemium:signature.productInformation').toLowerCase()}
        target="_blank"
        to={ipid}
      />
    </p>
  )

  const { contractType, price } = state.contract
  const mium = contractType.slice(-1)[0]

  const getName = (contract) => contract.subscriptionPortalDescriptions[0].titleGuarantees?.replace(/((garantie|offre) | - .+)/ig,"")

  const TermsLabel = () => (
    <p className={style['checkbox-label']}>
      {`${t('freemium:summary.iAgree')} ${contractType.length === 1 ? t('freemium:summary.oneContract') : t('freemium:summary.twoContracts')} `}
      <Link
        className={style.link}
        label={getName(contractType[0])}
        target="_blank"
        to={mium.legalDocuments.ni}
      />

      {contractType.length === 2 && <>
        {` ${t('freemium:signature.and')} `}
        <Link
          className={style.link}
          label={getName(contractType[1])}
          target="_blank"
          to={mium.legalDocuments.ni}
        />
      </>}
    </p>
  )


  const {
    firstname,
    lastname,
    birthdate,
    address,
    zipcode,
    city,
    additionalAddress,
    phone: { number },
    email,
    partnerOption,
    firstnamePartner,
    lastnamePartner,
    birthdatePartner,
    paidOption
  } = state.client

  const {titlePromotion} = contractType[0].subscriptionPortalDescriptions[0]
  const texts = contractType.map( contract => contract.subscriptionPortalDescriptions[0] )

  // console.log(texts)
  // return null


  return (
    <>
      <Title className={mainStyle['page-title']} text={<>{t('freemium:summary.title')} 👌</>} />

      <div className={style.card}>

        <div className={style['card-top']}>
          <div className={style['price-month']}>
            <span>{titlePromotion}</span>
          </div>

          {paidOption && <span className={style['price-year']}>
            {t('freemium:summary.then', { price })}
          </span>}
        </div>


        {texts.map( (text) =>{
          const free = !text.descriptionActivationGuarantees?.includes('offert')
          
          return (<div className={style.offer} key={`offer-${text._id}`}>
            <h4 className={classNames(style['summary-title'], style.address)}>
              <span>
                {free
                  ? "🎁"
                  : <LogoShort className={style.logo} />
                }
                &nbsp;{text.titleGuarantees}
              </span>
            </h4>
            <Collapsible
              className={style.collapsible}
              title={<span className={style['list-title']}>{text.subTitleGuarantees}</span>}
              price={free
                ? "Offert"
                : t('freemium:summary.price', { price })
              }
              content={
                <ul className={style.list}>
                  {text.coveredEvents.map((item, index) => (
                    <li key={`covered-events-${index}-${text._id}`}>
                      {item.name}
                    </li>
                  ))}
                </ul>
              }
            />
          </div>)
        })}
      </div>


      <div className={style.card}>
        <div className={style['card-content']}>
          <div className={style.summary}>

            <div className={style['summary-row']}>
              <h4 className={classNames(style['summary-title'], style.address)}>
                👤 {t('freemium:summary.yourInfos')}
                <Link className={style['address-link']} label="Modifier" state={state} to="../profil" />
              </h4>
              <p className={style['summary-description']}>
                {`${Capitalize(firstname)} ${Capitalize(lastname)}, ${t('common:form.born')} ${birthdate}`}
              </p>
            </div>

            <div className={style['summary-row']}>
              <h4 className={classNames(style['summary-title'], style.address)}>
                🏠 {t('freemium:summary.yourAddress')}
                <Link className={style['address-link']} label="Modifier" state={state} to="../address" />
              </h4>
              <p className={style['summary-description']}>
                {`${address}, ${zipcode} ${city}`}
                {additionalAddress && `, ${additionalAddress}`}
              </p>
            </div>

            <div className={style['summary-row']}>
              <h4 className={classNames(style['summary-title'], style.address)}>
                💌 {t('freemium:summary.yourContacts')}
                <Link className={style['address-link']} label="Modifier" state={state} to="../contacts" />
              </h4>
              <p className={style['summary-description']}>
                {`${email}, +${number}`}
              </p>
            </div>

            {partnerOption && <div className={style['summary-row']}>
              <h4 className={classNames(style['summary-title'], style.address)}>
                💕 {t('freemium:summary.yourPartner')}
                <Link className={style['address-link']} label="Modifier" state={state} to="../partner" />
              </h4>
              <p className={style['summary-description']}>
                {`${Capitalize(firstnamePartner)} ${Capitalize(lastnamePartner)}, ${t('common:form.born')} ${birthdatePartner}`}
              </p>
            </div>}

          </div>
        </div>
      </div>


      {paidOption && <div className={style.payment}>
        <h3 className={style['payment-title']}>{t('freemium:summary.paymentMethod.title')}</h3>
        <div className={style['payment-methods']}>
          <div
            className={classNames(style['payment-method'], !isIbanPayment && style.selected)}
            onClick={() => setIbanPayment(false)}
          >
            <CreditCard styles={{ fill: !isIbanPayment ? '#fff' : '#8686FF' }} />
            <p>{t('freemium:summary.paymentMethod.creditCard')}</p>
          </div>

          <div
            className={classNames(style['payment-method'], isIbanPayment && style.selected)}
            onClick={() => setIbanPayment(true)}
          >
            <CashOut styles={{ fill: isIbanPayment ? '#fff' : '#8686FF' }} />
            <p>{t('freemium:summary.paymentMethod.iban')}</p>
          </div>
        </div>
      </div>}


      <Checkbox
        id="agree"
        className={style.checkbox}
        label={<DocsLabel />}
        onChange={() => setAreDocsAccepted(!areDocsAccepted)}
      />

      <Checkbox
        id="terms"
        className={style.checkbox}
        label={<TermsLabel />}
        onChange={() => setAreTermsAccepted(!areTermsAccepted)}
      />


      <Button
        className={mainStyle.button}
        label={t('common:join')}
        disabled={!areTermsAccepted || !areDocsAccepted}
        onClick={onSubmit}
        type="primary"
      />
    </>
  )
}

export default Summary
