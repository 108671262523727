import { useTranslation } from 'react-i18next'
import Button from 'components/common/Button/Button'
import Filter from 'components/assets/icons/Filter'
import PrefixedText from 'components/common/PrefixedText/PrefixedText'
import style from './Filters.module.scss'

const Filters = () => {
  const { t } = useTranslation()

  const GetCSV = () => {
  }

  return (
    <div className={style.container}>
      <Button
        className={style.button}
        label={
          <PrefixedText
            prefix={<Filter />}
            text={t('reporting:filters.add')}
          />
        }
        onClick={GetCSV()}
      />
      <div className={style.separation}></div>
      <span className={style.none}>{t('reporting:filters.none')}</span>
    </div>
  )
}

export default Filters
