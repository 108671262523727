// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Signature_signature-iframe__zowy9 {
  width: 100%;
  height: 650px;
}
.Signature_signature-iframe__zowy9.Signature_inactive__LcBWK {
  display: none;
}
.Signature_signature-iframe__zowy9 iframe {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 600px) {
  #Signature_signature-iframe__zowy9 {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Freemium/Signature/Signature.module.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,aAAA;AAFF;AAIE;EACE,aAAA;AAFJ;AAKE;EACE,WAAA;EACA,YAAA;AAHJ;;AAOA;EACE;IACE,WAAA;EAJF;AACF","sourcesContent":["@use 'styles/sizes.scss';\n@use 'styles/colors.scss';\n\n.signature-iframe {\n  width: 100%;\n  height: 650px;\n\n  &.inactive {\n    display: none;\n  }\n\n  iframe {\n    width: 100%;\n    height: 100%;\n  }\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  #signature-iframe {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signature-iframe": `Signature_signature-iframe__zowy9`,
	"inactive": `Signature_inactive__LcBWK`
};
export default ___CSS_LOADER_EXPORT___;
