// Modules
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import api from 'helpers/api'
import { useTranslation } from 'react-i18next'

// Components
import Collapsible from 'components/common/Collapsible/Collapsible'
import Button from 'components/common/Button/Button'
import Link from 'components/common/Link/Link'
import LogoShort from 'components/assets/icons/LogoShort'
import Title from 'components/common/PageTitle/PageTitle'
import { pages } from 'components/Freemium/Nav/Nav'

// Styles
import classNames from 'classnames'
import style from './FreemiumMain.module.scss'
import mainStyle from '../FreemiumComponents.module.scss'

const FreemiumMain = () => {
  const { state, pathname } = useLocation()
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [contract, setContract] = useState()
  const token = state?.token || searchParams.get('token')
  const idCampaign = state?.idCampaign || searchParams.get('idCampaign')


  // Get datas
  const { data, isPending } = useQuery({
    queryKey: ["query-contract", params.id],
    queryFn: async () => {
      try{
        const contractType = await api.get({ url: `/owen-products/contract_type/${params.id}/client` })
        return {
          contractType: [contractType],
          partner: searchParams.get('partner'),
          priceType: searchParams.get('priceType'),
          rateType: searchParams.get('rateType'),
          contractPay: searchParams.get('contractPay')
        }
      } catch (error) {
        throw new Error('Failed to fetch contract')
      }
    }
  })

  useEffect(() => {
    if (!isPending){
      if (data){
        setContract(data)
      } else {
        navigate('/')
      }
    }
  }, [data, isPending, navigate])


  // Next page
  const onSubmit = () => {
    navigate(pages[1], {
      state: {
        client: state?.client,
        contract: state?.contract || contract,
        token,
        idCampaign
      },
    })
  }

  // Await contract
  if (!contract) return null
  const priceType = contract.contractType[0].priceType.find(elem => elem._id === contract.priceType)
  const rateType = priceType ? priceType.rateType.find(elem => elem._id === contract.rateType) : null
  const { subTitlePromotion, titlePromotion, name, coveredEvents, indemnities } = contract.contractType[0].subscriptionPortalDescriptions[0]
  const replaceDataIndemnities = (itemName) => {
    return itemName
      .replace(/{{package}}/g, rateType.dataInsurance.package ?? '')
      .replace(/{{refund_limit}}/g, rateType.refundLimit ?? '');
  }

  return (
    <>
      <Title className={mainStyle['page-title']} text={<>{t('freemium:start.title')} 🎉</>} />

      <div className={style.cards}>

        <div className={style.price}>
          <span className={style['price-description']}>
            {subTitlePromotion}
          </span>

          <h3 className={classNames(mainStyle.shining_title, mainStyle.max)}>
            {titlePromotion}
          </h3>
        </div>


        <div className={style.product}>
          <h4 className={style['product-title']}>
            <LogoShort className={style.logo} /> {t('common:yourProtection')}
          </h4>

          <p className={style['product-description']}>
            {name}
          </p>
        </div>


        <Collapsible
          className={style['collapsible-card']}
          title={<span className={style['list-title']}>⚠️ {t('freemium:start.eventsCovered')}</span>}
          isOpenedProp={true}
          content={
            <ul className={style.list}>
              {coveredEvents.map((item, index) => (
                <li className={style.item} key={`covered-event-${index}`}>
                  {item.name}
                </li>
              ))}
            </ul>
          }
        />


        <Collapsible
          className={style['collapsible-card']}
          title={<span className={style['list-title']}>💸 {t('freemium:start.indemnity')}</span>}
          isOpenedProp={true}
          content={
            <ul className={style.list}>
              {indemnities.map((item, index) => (
                <li className={style.item} key={`indemnity-${index}`}>
                  {replaceDataIndemnities(item.name)}
                </li>
              ))}
            </ul>
          }
        />
      </div>


      <Button
        className={classNames(mainStyle.button, style.button)}
        label={t('freemium:start.button')}
        onClick={onSubmit}
        type="primary"
      />


      <p className={style.link}>
        {`${t('common:consultThe')} `}
        <Link
          label={t('contract:document.ipid').toLowerCase()}
          target="_blank"
          to={contract.contractType[0].legalDocuments.ni}
        />
        {` ${t('common:misc.andThe')} `}
        <Link
          label={t('contract:document.niShort').toLowerCase()}
          target="_blank"
          to={contract.contractType[0].legalDocuments.ipid}
        />
        {` ${t('common:misc.onThe')} ${t('common:insuranceProduct')}`}
      </p>
    </>
  )
}

export default FreemiumMain