// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.ContractDocuments_list__jskNk {
  margin-top: 20px;
}

.ContractDocuments_list-item__VzRrc:not(:last-child) {
  margin-bottom: 12px;
}

.ContractDocuments_download__Fxe07 {
  margin-left: 12px;
}

.ContractDocuments_bold__Kvf8L {
  font-weight: 500;
}

.ContractDocuments_custom-claim-button__BLno9 {
  padding: 10px 20px; /* Ajuster en fonction de la taille du texte */
  font-size: inherit; /* Pour qu'il suive la taille du texte */
  width: auto; /* S'assure que le bouton prend la taille du texte */
  white-space: nowrap; /* Empêche le texte de se couper en plusieurs lignes */
  background-color: #E68D3E;
}

.ContractDocuments_custom-claim-button__BLno9:hover {
  background-color: #E68D3E; /* Si vous voulez que le bouton change au hover */
}

.ContractDocuments_custom-document-refused__GE8j7 {
  background-color: #FDF1ED;
  border: 1px solid #FBD298;
  color: #7B1028;
}

.ContractDocuments_info-message__3sZEd + .ContractDocuments_info-message__3sZEd {
  margin-top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/Contracts/ContractDocuments/ContractDocuments.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,gBAAA;AAEF;;AAEE;EACE,mBAAA;AACJ;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAEA;EACE,kBAAA,EAAA,8CAAA;EACA,kBAAA,EAAA,wCAAA;EACA,WAAA,EAAA,oDAAA;EACA,mBAAA,EAAA,sDAAA;EACA,yBAAA;AACF;;AAEA;EACE,yBAAA,EAAA,iDAAA;AACF;;AAEA;EACE,yBAAA;EACA,yBAAA;EACA,cAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".list {\n  margin-top: 20px;\n}\n\n.list-item {\n  &:not(:last-child) {\n    margin-bottom: 12px;\n  }\n}\n\n.download {\n  margin-left: 12px;\n}\n\n.bold {\n  font-weight: 500;\n}\n.custom-claim-button {\n  padding: 10px 20px; /* Ajuster en fonction de la taille du texte */\n  font-size: inherit; /* Pour qu'il suive la taille du texte */\n  width: auto; /* S'assure que le bouton prend la taille du texte */\n  white-space: nowrap; /* Empêche le texte de se couper en plusieurs lignes */\n  background-color: #E68D3E;\n}\n\n.custom-claim-button:hover {\n  background-color: #E68D3E; /* Si vous voulez que le bouton change au hover */\n}\n\n.custom-document-refused {\n  background-color: #FDF1ED;\n  border: 1px solid #FBD298;\n  color: #7B1028;\n}\n\n.info-message + .info-message{\n  margin-top: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `ContractDocuments_list__jskNk`,
	"list-item": `ContractDocuments_list-item__VzRrc`,
	"download": `ContractDocuments_download__Fxe07`,
	"bold": `ContractDocuments_bold__Kvf8L`,
	"custom-claim-button": `ContractDocuments_custom-claim-button__BLno9`,
	"custom-document-refused": `ContractDocuments_custom-document-refused__GE8j7`,
	"info-message": `ContractDocuments_info-message__3sZEd`
};
export default ___CSS_LOADER_EXPORT___;
