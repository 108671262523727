// Modules
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { parsePhoneNumber, getCountryCallingCode } from 'libphonenumber-js'

// Components
import PhoneInput from 'components/common/PhoneInput/PhoneInput'
import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import InputError from 'components/common/InputError/InputError'
import Title from 'components/common/PageTitle/PageTitle'
import { formParams, getDefaultValue } from 'constants/inputValidation'
import { next } from 'components/Freemium/Nav/Nav'
import mainStyle from '../FreemiumComponents.module.scss'

const Contacts = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state, pathname } = useLocation()


  // Initialize form
  const required = t('common:form.incorrect')
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
  } = useForm({
    ...formParams,
    values: {
      email: getDefaultValue('email', state),
      phone: getDefaultValue('phone', state),
    },
  })


  // Submit form => Navigate to the next page
  const onSubmit = handleSubmit(async data => {
    next({
      pathname, navigate, state,
      data:{ client: data },
      skip: !state.client.partnerOption && 1
    })
  })


  // Handle phone number changes
  const handleChangeNumber = (value, data, onChange, onBlur) => {
    onChange({
      number: value,
      countryCode: data.countryCode,
    })
    onBlur(value)
  }


  return (
    <>
      <Title className={mainStyle['page-title']} text={<>{t('freemium:contacts.title')} 💌</>} />

      <form className={mainStyle.form} onSubmit={onSubmit}>

        <Input
          error={errors.email}
          register={register('email', {
            required,
            validate: value => /\S+@\S+\.\S+/.test(value) || t('common:form.email.error')
          })}
          type={'email'}
          label={t('common:form.email.label')+'*'}
          placeholder={`${t('common:your')} ${t('common:form.email.placeholder')}`}
          autocomplete="email"
        />
        <InputError error={errors.email} />


        <Controller
          control={control}
          name="phone"
          rules={{
            required,
            validate: ({ number, countryCode }) => {
              try {
                const countryCallingCode = getCountryCallingCode(countryCode.toUpperCase())
                const codePays = new RegExp(`^${countryCallingCode}`)
                number = number.replace(codePays, '');

                const phoneNumber = parsePhoneNumber(number, countryCode.toUpperCase())

                const country = phoneNumber.country === countryCode.toUpperCase()
                const possible = phoneNumber.isPossible()
                const valid = phoneNumber.isValid()

                return (country && possible && valid) || t('common:form.phone.error')
              } catch {
                return required
              }
            },
          }}
          render={({ field: { onBlur, onChange } }) => (
            <PhoneInput
              localization={'fr'}
              country={'fr'}
              onBlur={onBlur}
              error={errors.phone}
              label={t('common:form.phone.label')+'*'}
              onChange={(value, data) => {
                handleChangeNumber(value, data, onChange, onBlur)
              }}
              onlyCountries={['fr']}
              placeholder={`${t('common:your')} ${t('common:form.phone.placeholder')}`}
              value={`${state?.client?.phone?.number || ''}`}
            />
          )}
        />
        <InputError error={errors.phone} />


        <p className={mainStyle.required}>{t('common:form.mandatoryFields')}</p>


        <Button
          className={mainStyle.button}
          label={t('common:next')}
          onClick={handleSubmit}
          type="primary"
          disabled={!isValid}
        />

      </form>
    </>
  )
}

export default Contacts
