export const Capitalize = (text) => {
  return text && typeof text === 'string'
    ? text.toLowerCase().charAt(0).toUpperCase() + text.toLowerCase().slice(1)
    : text
}

export const dateToAge = (date) => {
  const birthday = new Date(date.replace(/(\d+)\/(\d+)\/(\d+)/,'$3-$2-$1'))
  var ageDifMs = Date.now() - birthday.getTime()
  var ageDate = new Date(ageDifMs)
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export const formatNb = (data) => {
  if (!data && data !== 0) return
  const number = data.toString().match(/^\-?[\d\.]+/)[0]
  const value = data.length !== number.length
    ? data.toString().slice(number.length)
    : ''
  return new Intl.NumberFormat('fr-FR').format(Math.round(number*10)/10)+value
}