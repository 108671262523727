import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocizeBackend from 'i18next-locize-backend';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import 'dayjs/locale/fr';

import config from 'config';

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [LocizeBackend, HttpApi],
      backendOptions: [
        config.locize,
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    ns: ['common', 'main', 'claim', 'contract', 'subscription','freemium'],
    defaultNS: 'common',
    fallbackNS: 'common',
    supportedLngs: ['fr', 'it', 'en', 'es', 'de', 'nl'],
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
  });

export default i18n;
