import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Header from 'components/Header/Header'
import Menu from '../components/Menu/Menu'
import Submenu from 'components/reporting/components/Submenu/Submenu'
import { parseToken } from 'helpers/auth'
import style from './wrapper.module.scss'

const Wrapper = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [contract, setContract] = useState(null)
  const reportToken = localStorage.getItem('reportToken')
  const {name} = reportToken ? parseToken(reportToken) : {}
  const dashboardPage = pathname.match(/(overview|production|claims)/)
  const isAuthRoute = pathname.match(/^\/reporting\/(?:sign(?:in|up)|password)/)
  const notFound = pathname.match(/404/)

  useEffect(() => {
    if (notFound) return
    if (isAuthRoute && reportToken){
      navigate('/reporting/overview')
    } else if (!isAuthRoute && !reportToken){
      navigate('/reporting/signin')
    }
  }, [isAuthRoute, notFound, reportToken, navigate])

  return notFound
  ? <Outlet context={[]} />
  : reportToken
    ? <>
      <Header className={style.header} />
      <Menu />
      <main className={style.content}>
        <h1>
          {
          pathname.includes('overview') ? t('reporting:overview.welcome', { name })
          : pathname.includes('production') ? t('reporting:menu.production')
          : pathname.includes('claims') ? t('reporting:menu.claims')
          : ''
          }
        </h1>
        {dashboardPage && <Submenu setContract={setContract} />}
        <Outlet context={[contract]} />
      </main>
    </>
    : <main className={style.auth}>
      {<aside className={style.cover}></aside>}
      <Outlet context={[]} />
    </main>
}

export default Wrapper