// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContractCancel_form__CDF70 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.ContractCancel_textarea__e\\+J0T {
  height: 132px;
}

.ContractCancel_datepicker__6Ff5U {
  align-self: flex-start;
  margin-bottom: 20px;
}

.ContractCancel_button__BrCrG {
  width: 200px;
  margin-top: 40px;
}

@media screen and (max-width: 600px) {
  .ContractCancel_textarea__e\\+J0T {
    height: 188px;
  }
  .ContractCancel_datepicker__6Ff5U {
    width: 100%;
  }
  .ContractCancel_button__BrCrG {
    margin-top: 32px;
  }
  .ContractCancel_info-message__yG0KW button {
    height: 35px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Contracts/ContractCancel/ContractCancel.module.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,aAAA;AAFF;;AAKA;EACE,sBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,YAAA;EACA,gBAAA;AAFF;;AAKA;EACE;IACE,aAAA;EAFF;EAKA;IACE,WAAA;EAHF;EAMA;IACE,gBAAA;EAJF;EAQE;IACE,YAAA;EANJ;AACF","sourcesContent":["@use 'styles/colors.scss';\n@use 'styles/sizes.scss';\n\n.form {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 40px;\n}\n\n.textarea {\n  height: 132px;\n}\n\n.datepicker {\n  align-self: flex-start;\n  margin-bottom: 20px;\n}\n\n.button {\n  width: 200px;\n  margin-top: 40px;\n}\n\n@media screen and (max-width: sizes.$breakpoint-sm) {\n  .textarea {\n    height: 188px;\n  }\n\n  .datepicker {\n    width: 100%;\n  }\n\n  .button {\n    margin-top: 32px;\n  }\n\n  .info-message {\n    button {\n      height: 35px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `ContractCancel_form__CDF70`,
	"textarea": `ContractCancel_textarea__e+J0T`,
	"datepicker": `ContractCancel_datepicker__6Ff5U`,
	"button": `ContractCancel_button__BrCrG`,
	"info-message": `ContractCancel_info-message__yG0KW`
};
export default ___CSS_LOADER_EXPORT___;
