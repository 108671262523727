import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from 'components/common/PageTitle/PageTitle'
import PageSubtitle from 'components/common/PageSubtitle/PageSubtitle'
import style from './ContractList.module.scss'
import InfoMessage from 'components/common/InfoMessage/InfoMessage'
import ShieldLogo from 'components/assets/icons/ShieldLogo'
import DocumentText from 'components/assets/icons/DocumentText'
import Arrow from 'components/assets/icons/Arrow'
import Card from 'components/common/Card/Card'
import api from 'helpers/api'
import Button from 'components/common/Button/Button'
import { sortContractsByStatus } from 'helpers/contract'
import { useNavigate } from 'react-router-dom'
import ExclamationTriangle from 'components/assets/icons/ExclamationTriangle'

const ContractList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [contracts, setContracts] = useState([])
  const [contractTypeName, setContractTypeName] = useState('')
  const [daysRemaining, setDaysRemaining] = useState(null)
  const [globalStatus, setGlobalStatus] = useState(true)
  const [globalAdhesionId, setGlobalAdhesionId] = useState('')
  const refused = contracts.reduce( (acc, cur) => {
    const refusedList = cur.legalDocumentClientsInfo.filter( doc => doc.status === "refusé" )
    return [...acc, ...refusedList]
  }, [] )
  const shorterRefused = refused.reduce( (acc, cur) =>
    (!acc || acc > cur.daysRemaining)
    ? cur.daysRemaining
    : acc,
  undefined)

  useEffect(() => {
    const getContracts = async () => {
      try {
        const res = await api.get({ url: '/adhesions/client' })
        setContracts(sortContractsByStatus(res.adhesions))
        setContractTypeName(res.contractTypeName)
        setDaysRemaining(res.daysRemaining)
        setGlobalStatus(res.globalStatus)
        setGlobalAdhesionId(res.adhesionId)
      } catch (error) {
        console.log('Fetch contracts error')
      }
    }
    getContracts()
  }, [])


  return (
    <>
      <PageTitle text={t('contract:list.title')} />
      <PageSubtitle text={t('contract:list.subtitle')} />
      <InfoMessage
        className={style['info-message']}
        label={t('claim:infoMessage')}
        prefix={<ShieldLogo />}
        suffix={
          <Button
            className={style['claim-button']}
            label={t('claim:ctaInfinitive')}
            onClick={() => navigate('/claims/new')}
            type="primary"
          />
        }
      />
      <br />

      {refused.length !== 0 && (
        <InfoMessage
          type={'error'}
          label={refused.length === 1
            ? `L’un de vos justificatifs est invalide ! Vous avez ${shorterRefused} jours pour le modifier.`
            : `Plusieurs de vos justificatifs sont invalides ! Vous avez ${shorterRefused} jours pour les modifier.`
          }
          className={style['custom-info-message']}
          prefix={<ExclamationTriangle />}
          suffix={
            <Button
              className={`${style['claim-button']} ${style['custom-claim-button']}`}
              label={"Télécharger mes documents"}
              onClick={() => navigate(`/contracts/${globalAdhesionId}/documents`)}
              type="primary"
            />
          }
        />
      )}

      {!globalStatus && daysRemaining > 0 && (
        <InfoMessage
          type={'warning'}
          className={style['custom-info-message']}
          label={`Il vous reste ${daysRemaining} jours pour télécharger vos pièces justificatives pour le contrat ${contractTypeName} !`}
          prefix={<ExclamationTriangle styles={{fill: "#FFB02F"}} />}
          suffix={
            <Button
              className={`${style['claim-button']} ${style['custom-claim-button']}`}
              label={"Télécharger mes documents"}
              onClick={() => navigate(`/contracts/${globalAdhesionId}/documents`)}
              type="primary"
            />
          }
        />
      )}

      <div className={style.items}>
        {!!contracts?.length &&
          contracts.map((item, index) => {
            // Déterminer si la condition est remplie pour choisir la deuxième solution
            //const isProblematic = globalStatus === false && daysRemaining > 0
            const isProblematic = item.legalDocumentClientsInfo.find( doc => doc.status !== "accepté" )
            return (
              // Utiliser la deuxième solution si la condition est remplie
              <Card
                className={style.item}
                warning={isProblematic}
                inactive={item.cancelation}
                content={item.rateType.dashboardPackageDescription || item.contractType.dashboardDescription}
                key={`contract-item-${index}`}
                onClick={() => navigate(item._id)}
                title={
                  item.rateType?.dashboardPackageName
                    ? `${item.rateType.dashboardPackageName} - ${item.idTransaction}`
                    : `${item.contractType.dashboardName} - ${item.idTransaction}`
                }
                titleIcon={<DocumentText styles={isProblematic && {fill: '#B94402'}} />}
                cornerContent={<Arrow />}
              />
            )
          })
        }
      </div>
    </>
  )
}

export default ContractList
