import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Button from 'components/common/Button/Button'
import Input from 'components/common/Input/Input'
import Link from 'components/common/Link/Link'
import Eye from 'components/assets/icons/Eye'
import ResponseMessage from 'components/common/ResponseMessage/ResponseMessage'
import { emailValidation, passwordValidation } from 'constants/inputValidation'
import api from 'helpers/api'
import Header from 'components/Header/Header'
import { parseToken } from 'helpers/auth'
import style from 'styles/auth.module.scss'
import Loader from 'components/assets/icons/Loader'

const SignUp = () => {
  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const token = searchParams.get('token')
  const data = token ? parseToken(token) : null
  const accessToken = localStorage.getItem('accessToken')
  const parsedToken = parseToken(accessToken)

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    criteriaMode: 'all',
    delayError: 0,
    defaultValues: {
      email: data?.email,
      password: ''
    },
    mode: 'all',
  })

  const togglePasswordVisibility = () => {
    setIsPasswordShown(!isPasswordShown)
  }

  const onSubmit = handleSubmit(async data => {
    setLoading(true)

    const register = async () => {
      try {
        const res = await api.post({ url: '/auth/clients/signup', data })

        if (res.success && res.response.confirmToken) {
          navigate('/sign-up-confirm?token='+res.response.confirmToken)
        } else {
          setErrorMessage(t('main:errorMessages.general'))
        }
      } catch (error) {
        const errorMsg = error.toString().split(':')[1]?.trim()
        const errorsList = {
          "Email is already registered": t('main:auth.signUp.knownEmail'),
          "Client does not exist": t('main:auth.signUp.unknownEmail'),
          "Password should contain at least 9 characters, including 1 digit and 1 capital letter": t('main:auth.signUp.wrongPassword')
        }
  
        setErrorMessage(errorsList[errorMsg] ?? t('main:errorMessages.general'))

        if (errorsList[errorMsg] === t('main:auth.signUp.knownEmail')){
          setTimeout( () => {
            navigate('/sign-in')
          }, 5000)
        }
      }
      setLoading(false)
    }

    setTimeout( () => register(), 500)
  })


  return accessToken && new Date(parsedToken.exp) > Date.now()
  ? <Navigate to='/contracts' replace />
  : (
    <div className={style.auth}>
      <Header darkBg styles={{ position: 'absolute', top: 0 }} />
      <h2 className={style.title}>{t('main:auth.signUp.title')}</h2>

      {errorMessage &&
        <ResponseMessage
          className={style['response-message']}
          label={errorMessage}
        />
      }

      <form onSubmit={onSubmit}>

        <Input
          type="email"
          label="Email"
          placeholder={`${t('common:your')} ${t('common:form.email.placeholder')}`}
          register={register('email', emailValidation(t))}
          darkBg
        />
        {errors.email &&
          <div className={style['input-error']}>
            {errors.email.message}
          </div>
        }


        <Input
          type={isPasswordShown ? 'text' : 'password'}
          label={t('main:auth.password.password')}
          placeholder={t('main:auth.password.placeholder')}
          register={register('password', passwordValidation(t))}
          suffix={<Eye className="clickable" />}
          onSuffixClick={togglePasswordVisibility}
          className={style.margin}
          darkBg
        />
        {errors.password &&
          <div className={style['input-error']}>
            <p>{t('main:auth.password.required')}</p>
            {Object.values(errors.password.types).map( (error, id) => {
              return <div key={'err'+id}>{error}</div>
            })}
          </div>
        }

        <Button
          type={loading ? 'loading' : 'primary'}
          label={loading ? <Loader/> : t('main:auth.signUp.register')}
          onClick={handleSubmit}
          disabled={!isValid || loading} 
        />

      </form>

      <Link
        className={style.link}
        label={t('main:auth.signUp.alreadyHaveAccount')}
        to={'/sign-in'}
        darkBg
      />
    </div>
  )
}

export default SignUp
