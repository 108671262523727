import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Envelope from 'components/assets/icons/Envelope'
import Phone from 'components/assets/icons/Phone'
import Link from 'components/common/Link/Link'
import Button from 'components/common/Button/Button'
import Checkbox from 'components/common/Checkbox/Checkbox'
import Title from 'components/common/PageTitle/PageTitle'
import Spinner from 'components/common/Spinner/Spinner'

import { Yousign } from 'helpers/yousign'
import dayjs from 'dayjs'
import classNames from 'classnames'
import style from './Signature.module.scss'
import api from 'helpers/api'

const Signature = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()

  const [signatureLink, setSignatureLink] = useState()
  const [isSigned, setIsSigned] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [otpType, setOtpType] = useState(false)
  const [areTermsAccepted, setAreTermsAccepted] = useState(false)
  const [isSpinnerLoading, setIsSpinnerLoading] = useState(false)

  const onSignatureSuccess = useCallback(
    async data => {
      try {
        /*const res = await api.put({
          url: `/adhesions/${state.contract._id}/signature/subscription`,
          data: {
            adhesionId: state.contract._id,
            signature: 1,
            signatureHorodate: dayjs(data.timeStamp).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          },
          headers: { Authorization: `Bearer ${state.token}` },
        })

        if (res?.response) {
          navigate('../success', {
            state: {
              accessToken: state.accessToken,
              client: state.client,
              contract: state.contract,
              step: 8,
              token: state.token,
            },
          })
        }*/
        const res = await api.put({
          url: `/adhesions/${state.saveID}/signature/subscription/client`,
          data: {
            adhesionId: state.saveID,
            signature: 1,
            signatureHorodate: dayjs(data.timeStamp).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          },
          headers: { Authorization: `Bearer ${state.token}` },
        })      
        if (res?.response) {
          /*navigate('../success', {
            state: {
              accessToken: state.accessToken,
              client: state.client,
              contract: state.contract,
              step: 7,
              token: state.token,
            },
        })*/
      }
      } catch (error) {
        console.log('Update adhesion status error')
      }
    },
    [state],
  )

  useEffect(() => {
    if (!state?.client || !state?.contract || !state?.step) navigate('/sign-in')

    areTermsAccepted && otpType ? setIsValid(true) : setIsValid(false)

    if (signatureLink) {
      const yousign = new Yousign({
        signatureLink: signatureLink,
        iframeContainerId: 'signature-iframe',
        isSandbox: true, //To set if you use the sandbox environment
      })

      !isSigned &&
        yousign.onSuccess(() => {
          setIsSigned(true)
          onSignatureSuccess({ timeStamp: new Date() })
        })

      yousign.onError(() => {
        console.log('Error on signing the document')
      })
    }
  }, [isSigned, navigate, onSignatureSuccess, signatureLink, state, areTermsAccepted, otpType])

  const getSignatureLink = async () => {
    try {
      setIsSpinnerLoading(true);
      const res = await api.post({
        url: '/subscriptions/sign',
        data: { adhesionId: state.saveID, email: state.client.email, otpType: otpType },
        headers: { Authorization: `Bearer ${state.token}` },
      })
      if (res?.response?.signatureLink) setSignatureLink(res.response.signatureLink)
    } catch (error) {
      console.log('Get signature link error')
    } finally {
      setIsSpinnerLoading(false);
    }
  }

  if (!state) return null

  const { ipid, ni } = state.contract.contractType.legalDocuments
  const otpSmsType = 'otp_sms'
  const otpMailType = 'otp_email'

  const TermsLabel = () => (
    <p className={style['checkbox-label']}>
      {`${t('subscription:signature.checkboxText')} `}
      <Link
        className={style.link}
        label={t('subscription:signature.insuranceInformation').toLowerCase()}
        target="_blank"
        to={ni}
      />
      {` ${t('subscription:signature.and')} `}
      <Link
        className={style.link}
        label={t('subscription:signature.productInformation').toLowerCase()}
        target="_blank"
        to={ipid}
      />
    </p>
  )

  return (
    <>
      {isSpinnerLoading && <Spinner />}
      {!signatureLink && (
        <div className={style.container}>
          <Title className={style.title} text={t('subscription:signature.title')} />
          <div className={style.form}>
            <div className={style.inputs}>
              <div className={style.check}>
                <div className={style['check-methods']}>
                  <div
                    className={classNames(style['check-method'], otpType === otpSmsType && style.selected)}
                    onClick={() => setOtpType(otpSmsType)}
                  >
                    <Phone
                      styles={{
                        fill: otpType === otpSmsType ? '#fff' : '#8686FF',
                      }}
                    />
                    <p>{t('subscription:signature.bySms')}</p>
                  </div>
                  <div
                    className={classNames(style['check-method'], otpType === otpMailType && style.selected)}
                    onClick={() => setOtpType(otpMailType)}
                  >
                    <Envelope
                      styles={{
                        fill: otpType === otpMailType ? '#fff' : '#8686FF',
                      }}
                    />
                    <p>{t('subscription:signature.byMail')}</p>
                  </div>
                </div>
              </div>
              <Checkbox
                className={style.checkbox}
                id="terms"
                label={<TermsLabel />}
                onChange={() => setAreTermsAccepted(!areTermsAccepted)}
              />
            </div>
            <Button
              className={style.button}
              disabled={!isValid}
              label={t('common:next')}
              onClick={() => getSignatureLink()}
              type="primary"
            />
          </div>
        </div>
      )}
      <div
        className={classNames(style['signature-iframe'], !signatureLink && style.inactive)}
        id="signature-iframe"
      ></div>
    </>
  )
}

export default Signature
